
export type NavTabItem = {
    pageId: number
    pageTitle: string
    link: string | undefined
  }

  export type TabItem = {
    tabId: number
    tabTitle: 'Weekly' | 'Season'
    action: 'link' | 'change'
    link?: string | undefined
  }

  export const PAGE_NAV_NAMES = {
    home: 'home',
    fantasy: 'fantasy',
    fantasyGroupChallenge: 'fantasyGroupChallenge'
  }


  export const LEADERBOARD_NAMES = {
    pllNation: 'PLL Nation'
  }

  export const LEADERBOARD_TAB_NAMES = {
    weekly: 'Weekly',
    season: 'Season'
  }

export const leaderboardTabs: TabItem[] = [
    {
      tabId: 0,
      tabTitle: 'Weekly',
      action: 'change'
    },
    {
      tabId: 1,
      tabTitle: 'Season',
      action: 'change'
    },
  ]