import {
  AllEvents,
  CompiledPicksType,
  CondensedAIPlayer,
  CurrentGame,
  FantasyPlayer,
  FantasyPositionTypes,
  FantasyUser,
  FantasyWeek,
  PlayerBonusFieldType,
  PlayerFieldType,
} from '../../Api/Fantasy/fantasy.types'
import { FANTASY_SALARY_CAP } from '../../Configs/Fantasy/fantasy.config'
import { fantasyPositionFilterOrder } from '../../Constants/fantasy'
import { PLL_CONFERENCES } from '../../Constants/season'
import { currentTime } from '../DateTime/dateTimeHelpers'
import { Decimal1DigitNum } from '../formatting'
import { SEASON_SEGMENT_NAMES } from '../Season/seasonHelpers'

export const defaultCompiledPicks: CompiledPicksType = {
  All: [],
  A: [],
  M: [],
  D: [],
  SSDM: [],
  LSM: [],
  F: [],
  G: [],
  FO: [],
}

export function Percent(value: number): string {
  let pctStr = `${(value * 100).toFixed()}`
  let cleaned = parseFloat(pctStr)
  return `${cleaned}%`
}

export function PercentFromStr(value: string): string {
  let num = parseFloat(value)
  let pctStr = `${(num * 100).toFixed()}`
  return `${pctStr}%`
}

export function Decimal1Digit(value: number): string {
  return `${Math.round(value * 10) / 10}`
}

export function ConvertStatValue<T>(
  value: T,
  valueName: string
): T | string {
  if (valueName.toLowerCase().endsWith('pct')) {
    if (typeof value === 'number') {
      return Percent(value)
    }
    if (typeof value === 'string') {
      return PercentFromStr(value)
    }

    if (value === null || value === undefined) return '-'
  } else if (
    valueName.toLowerCase().endsWith('aa') ||
    valueName.endsWith('PG')
  ) {
    if (typeof value === 'number') {
      return Decimal1Digit(value)
    }
    if (value === null || value === undefined) return '-'
  } else if (valueName === 'foRecord') {
    if (value === null || value === undefined) return '-'
    return value
  } else {
    if (value === null || value === undefined) return '0'
  }
  return value
}

export const getPositionLong = (pos: string) => {
  switch (pos) {
    case 'A':
      return 'Attack'
    case 'M':
      return 'Midfield'
    case 'D':
      return 'Defense'
    case 'G':
      return 'Goalie'
    case 'FO':
      return 'Faceoff'
    case 'F':
      return 'Field'
    default:
      return 'Player'
  }
}

export const getPositionShort = (pos: string) => {
  switch (pos) {
    case 'attack':
      return 'A'
    case 'midfield':
      return 'M'
    case 'defense':
      return 'D'
    case 'goalie':
      return 'G'
    case 'faceoff':
      return 'FO'
    case 'field':
      return 'F'
    default:
      return 'Player'
  }
}

export const getCapLeft = (
  maxCap: number,
  compiledPicks: CompiledPicksType
) => {
  if (!compiledPicks) return maxCap

  let sum = maxCap

  let joinedArrays = getPicksArray(compiledPicks)

  sum = joinedArrays.reduce(
    (acc, pick) => acc - pick?.fantasyInfo?.salary,
    200
  )

  return sum
}

export const getTotalCap = (
  players: CondensedAIPlayer[] | FantasyPlayer[]
) => {
  const sum = players.reduce(
    (acc, p) => acc + p?.fantasyInfo?.salary,
    0
  )

  return sum
}

export const getTotalProj = (
  players: CondensedAIPlayer[] | FantasyPlayer[]
) => {
  const sum = players.reduce(
    (acc, p) => acc + (p?.fantasyInfo?.projectedPoints || 0),
    0
  )

  return Decimal1DigitNum(sum)
}

export const teamHasPlayerWithGameStarted = (
  picks: CompiledPicksType,
  week: number
) => {
  let liveOrPlayedCount = 0
  Object.keys(picks).map((pos) => {
    picks[pos].map((pl) => {
      if (!hasGameStarted(pl, week)) {
      } else {
        liveOrPlayedCount++
      }
    })
  })

  return liveOrPlayedCount > 0 ? true : false
}

export const hasChampSeriesGameStarted = (player: FantasyPlayer) => {
  let now = Math.round(Date.now() / 1000)
  if (player?.currentGame?.startTime < now) {
    //Has Started
    return true
  }

  return false
}

export const hasGameStarted = (
  player: FantasyPlayer,
  week?: number
) => {
  let now = Math.round(Date.now() / 1000)
  if (week && week === 0) {
    //Champ Series
    if (!player?.currentGame || player?.currentGame.week > week)
      return false
    const playedGames = player.allEvents.find(
      (ev) =>
        ev.week === week.toString() &&
        (ev.startTime < now || ev.eventStatus > 0)
    )
    return playedGames ? true : false
  }
  if (player?.currentGame?.startTime < now) {
    //Has Started
    return true
  }

  return false
}

export const checkGamesStarted = (
  activeWeek: number,
  fantasyWeeks?: FantasyWeek[]
) => {
  let currentWeek = fantasyWeeks?.find(
    (week) => week.week === activeWeek
  )
  return currentWeek ? currentTime > currentWeek?.openTime : false
}

export const checkSalaryCap = (
  player: FantasyPlayer,
  compiledPicks: CompiledPicksType,
  maxCap: number = FANTASY_SALARY_CAP
) => {
  const salary =
    getCapLeft(maxCap, compiledPicks) - player.fantasyInfo.salary
  return salary >= 0 ? true : false
}

export const checkPlayerCount = (picks: CompiledPicksType) => {
  let picksArray = getPicksArray(picks)

  return picksArray.length
}

export const getPickedPlayers = (user: FantasyUser | null) => {
  let compiled: CompiledPicksType = {
    All: [],
    A: [],
    M: [],
    D: [],
    SSDM: [],
    LSM: [],
    F: [],
    G: [],
    FO: [],
  }
  if (!user) return compiled
  let picks = user.weekPick?.players ?? []
  if (picks.length < 1) {
    return compiled
  }
  picks.map((pick) => {
    let alreadyAdded: boolean
    if (!pick?.fantasyInfo?.position) return

    if (
      pick.fantasyInfo.position === 'SSDM' ||
      pick.fantasyInfo.position === 'LSM'
    ) {
      alreadyAdded = checkPlayerAlreadyAdded(pick, 'D', compiled)
    } else {
      alreadyAdded = checkPlayerAlreadyAdded(
        pick,
        pick.fantasyInfo.position,
        compiled
      )
    }
    if (alreadyAdded) return

    if (
      pick.fantasyInfo.position === 'SSDM' ||
      pick.fantasyInfo.position === 'LSM'
    ) {
      compiled['D'].push(pick)
    } else {
      compiled[pick.fantasyInfo.position].push(pick)
    }
  })
  return compiled
}

export const checkPlayerAlreadyAdded = (
  pick: FantasyPlayer,
  position: string,
  pickList: CompiledPicksType
) => {
  let posList = pickList[position]
  let foundPlayer = posList.find(
    (pl) =>
      pl.fantasyInfo?.fantasyPlayerInfoId ===
      pick.fantasyInfo?.fantasyPlayerInfoId
  )
  return foundPlayer ? true : false
}

export const playerCardHeaderStats = [
  {
    title: 'Games',
    firstValue: 'stats',
    secondValue: 'gamesPlayed',
  },
  {
    title: 'SZN Points',
    firstValue: 'fantasyInfo',
    secondValue: 'seasonTotalPts',
  },
  {
    title: 'Avg Points',
    firstValue: 'fantasyInfo',
    secondValue: 'avgPPG',
  },
]

export const PlayerFields: PlayerFieldType[] = [
  {
    title: '1G',
    field: 'onePointGoals',
    points: 10,
  },
  {
    title: '2G',
    field: 'twoPointGoals',
    points: 20,
  },
  {
    title: 'A',
    field: 'assists',
    points: 10,
  },
  {
    title: 'TO',
    field: 'turnovers',
    points: -3,
  },
  {
    title: 'CT',
    field: 'causedTurnovers',
    points: 10,
  },
  {
    title: 'GB',
    field: 'groundBalls',
    points: 1,
  },
  {
    title: 'FOW',
    field: 'faceoffsWon',
    points: 0.8,
  },
  {
    title: 'FOL',
    field: 'faceoffsLost',
    points: -0.5,
  },
  {
    title: 'Sv',
    field: 'saves',
    points: 3,
  },
  {
    title: 'GA',
    field: 'goalsAgainst',
    points: -1,
  },
  {
    title: '2ptGA',
    field: 'twoPtGaa',
    points: -2,
  },
]

export const playerBonusFields: PlayerBonusFieldType[] = [
  {
    title: '1G',
    field: 'onePointGoals',
    bonus: 5,
    bonusMetric: 3,
  },
  {
    title: 'A',
    field: 'assists',
    bonus: 5,
    bonusMetric: 3,
  },
  {
    title: 'Sv',
    field: 'saves',
    bonus: 5,
    bonusMetric: 15,
  },
  {
    title: 'CT',
    field: 'causedTurnovers',
    bonus: 5,
    bonusMetric: 3,
  },
]

export const getResultStatus = (
  playerTeam: number,
  oppTeam: number
) => {
  if (playerTeam > oppTeam) {
    return 'W'
  }

  return 'L'
}

export const getScoresArray = (
  player: FantasyPlayer,
  game: AllEvents
) => {
  if (player?.currentTeam?.officialId === game.homeTeam.officialId) {
    return [game.homeScore, game.visitorScore]
  }
  return [game.visitorScore, game.homeScore]
}

export const getPlayerOpponent = (
  player: FantasyPlayer,
  game?: AllEvents | CurrentGame
) => {
  //return opposite of player's team
  if (game?.seasonSegment === SEASON_SEGMENT_NAMES.allstar) {
    const playerConference = player.currentTeam?.conference
    if (!playerConference) return 'TBD'
    //Return the opposite conference
    return playerConference === PLL_CONFERENCES.EASTERN
      ? 'West'
      : 'East'
  }

  const playerTeam = player.currentTeam?.locationCode
  const homeCode = game?.homeTeam?.locationCode
  const awayCode = game?.awayTeam?.locationCode

  if (!playerTeam || !homeCode || !awayCode) return 'TBD'

  return homeCode === playerTeam ? awayCode : homeCode
}

export const getGameFP = (eventId: string, player: FantasyPlayer) => {
  if (!player?.fantasyInfo?.seasonWeeklyPts) return 0
  const gamePtsObj = player.fantasyInfo.seasonWeeklyPts.find(
    (wk) => wk.eventId === eventId
  )
  return gamePtsObj?.totalPts || 0
}

/**
 * Adding bonus points if player has met the bonus metric
 */
export const calculateFantasyBonusForStat = (
  field: string,
  value: number
) => {
  let bonus = playerBonusFields.find((f) => f.field === field)
  if (!bonus) return 0
  if (value >= bonus.bonusMetric) return bonus.bonus
  return 0
}

/**
 * Sorting the filter items in the order of what is expected
 */
export const sortPositionFilters = (
  items: FantasyPositionTypes[]
): FantasyPositionTypes[] => {
  return items.sort((a, b) => {
    // Get the index of each item's value in fantasyPositionFilterOrder
    const indexA = fantasyPositionFilterOrder.indexOf(a.value)
    const indexB = fantasyPositionFilterOrder.indexOf(b.value)
    if (indexA > indexB) {
      return 1
    }

    if (indexA < indexB) {
      return -1
    }
    return 0
  })
}

export const filterFantasyPlayers = (
  pos: string,
  allPlayers: FantasyPlayer[]
) => {
  let players: FantasyPlayer[] = []
  if (allPlayers.length < 1) return players

  if (pos === 'All') {
    players = allPlayers
  } else {
    players = allPlayers.filter(
      (plr) => mergePosition(plr.fantasyInfo.position) === pos
    )
  }

  return players
}

export const mergePosition = (pos: string) => {
  if (pos === 'SSDM' || pos === 'LSM') {
    return 'D'
  }
  return pos
}

export const getFilteredPlayers = (allPlayers: FantasyPlayer[]) => {
  let players: CompiledPicksType = {
    All: filterFantasyPlayers('All', allPlayers),
    A: filterFantasyPlayers('A', allPlayers),
    M: filterFantasyPlayers('M', allPlayers),
    SSDM: filterFantasyPlayers('SSDM', allPlayers),
    LSM: filterFantasyPlayers('LSM', allPlayers),
    D: filterFantasyPlayers('D', allPlayers),
    G: filterFantasyPlayers('G', allPlayers),
    FO: filterFantasyPlayers('FO', allPlayers),
    F: filterFantasyPlayers('F', allPlayers),
  }

  return players
}

export const reduceTotalNeededPlayers = (
  posList: {
    [key: string]: number
  } | null
) => {
  if (posList === null) return 0
  const counts = Object.values(posList).reduce(
    (total, current) => (total ?? 0) + (current ?? 0),
    0
  )
  return counts
}

export const checkCompiled = (
  compiled: CompiledPicksType,
  player?: FantasyPlayer
) => {
  if (!player) return false
  let position: string

  if (
    player.fantasyInfo.position === 'LSM' ||
    player.fantasyInfo.position === 'SSDM'
  ) {
    position = 'D'
  } else {
    position = player.fantasyInfo.position
  }

  return compiled[position]?.find(
    (pick) =>
      pick.fantasyInfo?.fantasyPlayerInfoId ===
      player.fantasyInfo?.fantasyPlayerInfoId
  )
    ? true
    : false
}

export const determineActionButton = (
  player: FantasyPlayer,
  page: string,
  compiled: CompiledPicksType,
  week: number
) => {
  if (page === 'pTab') return 'arrow'

  const gameLive = hasGameStarted(player, week)

  if (gameLive) {
    return page === 'pCard' ? '' : 'arrow'
  }

  if (page === 'team') {
    return 'swap'
  }

  if (page === 'swap' || page === 'pCard') {
    let isInCompiled = checkCompiled(compiled, player)

    if (player.currentTeam?.officialId === 'ZPP') {
      return ''
    }

    if (isInCompiled) {
      return 'drop'
    }

    return 'add'
  }

  return ''
}

export const getPicksArray = (picks: CompiledPicksType) => {
  return Object.values(picks).flat()
}

export const convertPositionForFantasy = (
  player: CondensedAIPlayer | FantasyPlayer
) => {
  if (!player?.fantasyInfo?.position) return ''
  return mergePosition(player.fantasyInfo.position)
}

export const getLeaderName = (leader: FantasyUser) => {
  if (leader?.teamName) return leader.teamName
  if (leader?.name) return `SuperFan#${leader.name.slice(0, 4)}`
  return `SuperFan#${leader.firebaseId.slice(3, 6)}`
}
