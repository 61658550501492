import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { SuspensePage } from '../../Pages'

const FantasyChallengersProvider = lazy(() =>
  import('../../Context').then((module) => ({ default: module.FantasyChallengersProvider }))
)

export const FantasyChallengersContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
    <FantasyChallengersProvider>
      <Outlet />
    </FantasyChallengersProvider>
    </Suspense>
  )
}