import {
  MAIN_PLL_URL,
  PLL_IMAGE_URL,
  SEASON,
  Team_LOGO_URL,
} from '../../Configs/config'

export type TeamFrontData = {
  officialId: string
  fullName: string
  logo1: string
  logo2: string
  rectLogo: string
  location: string // Boston
  locationCode: string // BOS
  primaryColor: string
  secondaryColor: string
  sortOrder: number
  seed?: number
}

export type FavTeamCodeType =
  | 'ARC'
  | 'ATL'
  | 'CAN'
  | 'CHA'
  | 'CHR'
  | 'RED'
  | 'OUT'
  | 'WAT'
  | 'WHP'

export type FavTeamType =
  | 'Archers'
  | 'Atlas'
  | 'Cannons'
  | 'Chaos'
  | 'Chrome'
  | 'Redwoods'
  | 'Outlaws'
  | 'Waterdogs'
  | 'Whipsnakes'

export const getTeamColor = (teamId: string) => {
  const team = teamsDataArray.find((team) => team.officialId === teamId)
  if (!team) return
  return team.primaryColor
}

export function getFullTeamName(teamId: string): string | undefined {
  const team = teamsDataArray.find((td) => td.officialId === teamId)
  return team?.fullName
}

export function getTeamCode(teamFullName: string): string | undefined {
  const team = teamsDataArray.find(
    (td) => td.fullName.toLowerCase() === teamFullName.toLowerCase()
  )
  return team?.officialId
}

export function getTeamSeed(teamId: string): number | undefined {
  const team = teamsDataArray.find((td) => td.officialId === teamId)
  if (!team) return
  return team.seed
}

export const getTeamIc = (teamName: string | undefined) => {
  if (!teamName) {
    return getGeneralTeamIc()
  }
  const lowerCase = teamName.toLowerCase()
  switch (lowerCase) {
    case 'baptiste':
    case 'farrell':
      return `${Team_LOGO_URL}/2022/Logo/team-${lowerCase}.png`
    case 'adversaries':
    case 'defenders':
      return `${Team_LOGO_URL}/2021/07/${lowerCase}.jpg`
    case 'veterans':
    case 'rising stars':
      return `${Team_LOGO_URL}/2023/Logo/2023_ASG_${lowerCase}.webp`
    default:
      return SEASON < 2024 || lowerCase === 'chrome'
        ? `https://${MAIN_PLL_URL}/${lowerCase}_primary_fullcolor`
        : `${Team_LOGO_URL}/2024/Logo/2024_${lowerCase}_primary_color.png`
  }
}

export const getGeneralTeamIc = () => {
  return `${PLL_IMAGE_URL}/2021/05/PLL_Icon_Bl.png`
}

export const teamsDataArray: TeamFrontData[] = [
  {
    officialId: 'ARC',
    fullName: 'Archers',
    location: 'Utah',
    locationCode: 'UTA',
    rectLogo: '',
    logo1: `${Team_LOGO_URL}/2024/Logo/2024_archers_primary_color.png`,
    logo2: `${Team_LOGO_URL}/2024/Logo/2024_archers_primary_gray.png`,
    sortOrder: 1,
    primaryColor: '#FF5216',
    secondaryColor: '#002244',
  },
  {
    officialId: 'ATL',
    fullName: 'Atlas',
    location: 'New York',
    locationCode: 'NYA',
    rectLogo: '',
    logo1: `${Team_LOGO_URL}/2024/Logo/2024_atlas_primary_color.png`,
    logo2: `${Team_LOGO_URL}/2024/Logo/2024_atlas_primary_gray.png`,
    sortOrder: 2,
    primaryColor: '#00B1FF',
    secondaryColor: '#011E41',
  },
  {
    officialId: 'CAN',
    fullName: 'Cannons',
    location: 'Boston',
    locationCode: 'BOS',
    rectLogo: '',
    logo1: `${Team_LOGO_URL}/2024/Logo/2024_cannons_primary_color.png`,
    logo2: `${Team_LOGO_URL}/2024/Logo/2024_cannons_primary_gray.png`,
    sortOrder: 3,
    primaryColor: '#B0B6BB',
    secondaryColor: '#C52032',
  },
  {
    officialId: 'CHA',
    fullName: 'Chaos',
    location: 'Carolina',
    locationCode: 'CAR',
    rectLogo: '',
    logo1: `${Team_LOGO_URL}/2024/Logo/2024_chaos_primary_color.png`,
    logo2: `${Team_LOGO_URL}/2024/Logo/2024_chaos_primary_gray.png`,
    sortOrder: 4,
    primaryColor: '#FF0E11',
    secondaryColor: '#000',
  },
  {
    officialId: 'OUT',
    location: 'Denver',
    locationCode: 'DEN',
    rectLogo: '',
    fullName: 'Outlaws',
    logo1: `${Team_LOGO_URL}/2024/Logo/2024_outlaws_primary_color.png`,
    logo2: `${Team_LOGO_URL}/2024/Logo/2024_outlaws_primary_gray.png`,
    sortOrder: 5,
    primaryColor: '#FF5216',
    secondaryColor: '#2E2E4D',
  },
  {
    officialId: 'RED',
    fullName: 'Redwoods',
    location: 'California',
    locationCode: 'CAL',
    rectLogo: '',
    logo1: `${Team_LOGO_URL}/2024/Logo/2024_redwoods_primary_color.png`,
    logo2: `${Team_LOGO_URL}/2024/Logo/2024_redwoods_primary_gray.png`,
    sortOrder: 6,
    primaryColor: '#004B37',
    secondaryColor: '#FFD200',
  },
  {
    officialId: 'WAT',
    fullName: 'Waterdogs',
    location: 'Philadelphia',
    locationCode: 'PHI',
    rectLogo: '',
    logo1: `${Team_LOGO_URL}/2024/Logo/2024_waterdogs_primary_color.png`,
    logo2: `${Team_LOGO_URL}/2024/Logo/2024_waterdogs_primary_gray.png`,
    sortOrder: 7,
    primaryColor: '#6C20C4',
    secondaryColor: '#99A4AD',
  },
  {
    officialId: 'WHP',
    fullName: 'Whipsnakes',
    location: 'Maryland',
    locationCode: 'MDW',
    rectLogo: '',
    logo1: `${Team_LOGO_URL}/2024/Logo/2024_whipsnakes_primary_color.png`,
    logo2: `${Team_LOGO_URL}/2024/Logo/2024_whipsnakes_primary_gray.png`,
    sortOrder: 8,
    primaryColor: '#FF0000',
    secondaryColor: '#FFCE00',
  },
]

export const getTeamColorByFullName = (teamName: string) => {
  const team = teamsDataArray.find(
    (team) => team.fullName.toLowerCase() === teamName.toLowerCase()
  )
  if (!team) return ''
  return team.primaryColor
}

export function getTeamBasicData(teamId: string): TeamFrontData | undefined {
  let tId = teamId.toLowerCase().trim()
  const team = teamsDataArray.find((td) => td.officialId.toLowerCase() === tId)
  return team
}