import { styled } from '@mui/system'
import { colors, fontSizes } from '../../Components/consts'

export const CashTagPopupInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& .textField': {
    height: 40,
    background: colors.white,
    borderRadius: '5px',
    width: '95%',
    margin: theme.spacing(0, 1),
  },
  '& .submitContainer': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .subTextRow': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      textAlign: 'center',
      fontSize: fontSizes.small,
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizes.xSmall,
      },
    },
    '& a': {
      color: colors.limeGreen,
      fontSize: fontSizes.small,
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizes.xSmall,
      },
    },
  },
  '& .stackedTextCon': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      textAlign: 'center',
      fontSize: fontSizes.xSmall,
      marginBottom: 2,
    },
    '& a': {
      color: colors.limeGreen,
      fontSize: fontSizes.xSmall,
    },
  },
  '& .submitBtn': {
    width: 200,
    height: 44,
    borderRadius: 25,
    border: 'none',
    background: colors.limeGreen,
    color: colors.black,
    fontWeight: 'bold',
    margin: theme.spacing(1, 0.5),
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizes.xSmall,
      width: 180,
      height: 38,
    },
  },
  '& .disabled': {
    width: 200,
    height: 44,
    borderRadius: 25,
    border: `2px solid ${colors.lightGrey}`,
    background: 'none',
    color: colors.lightGrey,
    fontWeight: 'bold',
    margin: theme.spacing(1, 0.5),
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizes.xSmall,
      width: 180,
      height: 38,
    },
  },
  '& .error': {
    color: colors.red,
    display: 'inline-block',
    fontSize: '12px',
    lineHeight: '15px',
    margin: '2px',
  },
}))
