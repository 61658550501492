import { PLL_IMAGES } from "../../Configs/config"
import { DYNAMIC_LINK_CONFIG } from "../../Configs/app.config"
export type DynamicLinkParams = {
    link: string
    shouldOpenAppStore?: boolean
    shouldOpenGooglePlay?: boolean
    socImg?: string
    socTitle?: string
  }

export const createDynamicShortLink = async (linkParams: DynamicLinkParams) => {
    const body = {
      dynamicLinkInfo: {
        domainUriPrefix: DYNAMIC_LINK_CONFIG.baseUrl,
        link: linkParams.link,
        iosInfo: {
          iosBundleId: DYNAMIC_LINK_CONFIG.ibi,
          iosAppStoreId: DYNAMIC_LINK_CONFIG.isi,
          iosFallbackLink: linkParams?.shouldOpenAppStore === false ? linkParams.link : null,
          iosIpadFallbackLink: linkParams?.shouldOpenAppStore === false  ? linkParams.link : null
        },
        androidInfo: {
          androidPackageName: DYNAMIC_LINK_CONFIG.apn,
          androidFallbackLink: linkParams?.shouldOpenGooglePlay === false ? linkParams.link : null
        },
        socialMetaTagInfo: {
          socialTitle: linkParams?.socTitle ? linkParams.socTitle : DYNAMIC_LINK_CONFIG.shareTitle,
          socialImageLink: linkParams?.socImg ? linkParams.socImg : PLL_IMAGES.pllAppFeaturedImage
        }
      }
    }
    const opts: RequestInit = {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(body),
      }
      const response = await fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, opts)
      if (response.status === 200) {
        let r = await response.json()
        return r.shortLink
      }
      return null
  }