import React, { useContext, useState } from 'react'
import {
  BottomAppCallout,
  MobileBox,
  PageContainer,
} from '../../../Components/Layout'
import { Desc } from '../../../Components/Typography/Text.styles'
import { colors } from '../../../Components/consts'
import { FantasyContext } from '../../../Context'
import { GroupListCon } from './styles'
import { AuthContext } from '../../../Api/firebase'
import { GenLoginForm } from '../../../Components/Auth/GenLoginForm'
import { NavComponent } from '../../../Components/Navbar/SiteNavbar/NavComponent'
import { Helmet } from 'react-helmet-async'
import { PageNav } from '../../../Components/Navbar/PageNav/PageNav'
import { GetCurrentPage, fantasyPages } from '../../../Configs'
import { PAGE_NAV_NAMES } from '../../../Configs/Tabs/tabs.config'
import { fantasyDeepLink } from '../../../Constants/fantasy'
import { CreateGroup } from '../../../Components'

const FantasyGroupsEnterCompetition: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  let { isLoggedIn } = useContext(AuthContext)!
  let { fantasyUser, loading } = useContext(FantasyContext)!
  const [showAppCallout, setShowAppCallout] = useState<boolean>(false)

  const handleToggleAppCallout = (show: boolean) => {
    setShowAppCallout(show)
  }

  return (
    <>
      <Helmet>
        <title>{`Enter Fantasy Group Compeition | PLL Weekly Fantasy`}</title>
        <meta
          name="description"
          content={`Premier Lacrosse League's Weekly Fantasy. Join or create Weekly Fantasy Groups`}
        />
        <meta
          name="keywords"
          content={`PLL Fantasy, Contest, pro lacrosse fantasy`}
        />
        <meta
          property="og:title"
          content={`Enter Fantasy Group Compeition | PLL Weekly Fantasy`}
        />
      </Helmet>
      <NavComponent showFixedAd={false}>
        <PageContainer>
          <MobileBox
            vertMargins={0}
            sideMargins={0}
            color="dark"
            padding={0}
            fullHeight
          >
            <PageNav
              navList={fantasyPages}
              selectedTab={GetCurrentPage()}
              navName={PAGE_NAV_NAMES.fantasy}
            />
            <GroupListCon>
              <>
                {loading && (
                  <Desc justify="center" color={colors.white}>
                    Loading...
                  </Desc>
                )}
                <>
                  {fantasyUser && !loading && (
                    <CreateGroup
                      handleShowGetAppCallout={handleToggleAppCallout}
                    />
                  )}
                  {!isLoggedIn && !loading && (
                    <GenLoginForm
                      title="Log In To Create Group"
                      desc="Login to create and enter your group. Owners can share their group code to invite others to join."
                      theme="dark"
                    />
                  )}
                </>
              </>
            </GroupListCon>
          </MobileBox>
        </PageContainer>
        {showAppCallout && (
          <BottomAppCallout
            calloutId="fantasy_groups_callout"
            appLink={fantasyDeepLink}
            location="Fantasy_Groups_Page"
            text="Manage your fantasy team in the PLL App!"
            showOnAndroid={false}
          />
        )}
      </NavComponent>
    </>
  )
}

export default FantasyGroupsEnterCompetition
