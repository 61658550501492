export const initializeSentry = async () => {
  if (process.env.NODE_ENV === 'production') {
    try {
      const sentry = await import('@sentry/browser')
      const client = new sentry.BrowserClient({
        dsn: 'https://af050c7dfaef417c81853ae54c8c6176@o414291.ingest.sentry.io/5303504',
        transport: sentry.makeFetchTransport,
        stackParser: sentry.defaultStackParser,
        integrations: [
          new sentry.BrowserTracing(),
          new sentry.Breadcrumbs(),
          new sentry.GlobalHandlers(),
          new sentry.LinkedErrors(),
          new sentry.Dedupe(),
        ],
        tracesSampleRate: 0.1,
        environment: 'production',
      }) //Error tracking
      sentry.getCurrentHub().bindClient(client)
    } catch (err) {
      console.error('Failed to initialize Sentry:', err)
      throw err
    }
  }
}
