import React, { useContext } from 'react'
import { styled } from '@mui/system'
import { AuthContext } from '../../Api/firebase'
import { Box } from '../Layout'

export const LogoutBtn = styled('button')(({ theme }) => ({
  background: '#fff',
    border: 'solid 1px #efefef',
    borderRadius: 5,
}))

export const UserIndicator: React.FC = () => {
  const { isAnonymous, firstName, isLoggedIn, login, logout} = useContext(AuthContext)!
  return (
    <Box>
      Signed in as {' '}
      <b>
        {isAnonymous && 'anonymous'}
        {!isLoggedIn && 'not logged in'}
        {isLoggedIn && firstName}
      </b>{' '}
      {isLoggedIn && !isAnonymous && (
        <LogoutBtn onClick={logout}>logout</LogoutBtn>
      )}
      {(!isLoggedIn || isAnonymous) && (
        <button onClick={() => login()}>login</button>
      )}
    </Box>
  )
}
