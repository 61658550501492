import React, { createContext, useState, useEffect } from 'react'
import { checkStorageOptions, retrieveFromCache, storeInCache } from '../../Utils'
import { StorageOptionsStatus } from '../../Constants'
import { deleteFromCache } from '../../Utils/WindowHelpers/storageHelpers'

type StorageContextType = {
  storageStatuses: StorageOptionsStatus
  isStorageReady: boolean
  store: (key: string, item: any) => Promise<void>
  getStoredData: (key: string, timeLimit?: number) => Promise<{
    data: any
    refetch: boolean
  }>
  deleteStoredData: (key: string) => Promise<void>
}

export const StorageContext = createContext<StorageContextType | null>(null)

export const StorageProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [storageStatuses, setStorageStatuses] = useState<StorageOptionsStatus>({
    indexedDB: false,
    local: false,
    session: false,
    cookies: false,
  })
  const [isStorageReady, setIsStorageReady] = useState<boolean>(false)

  useEffect(() => {
    const storageOptions = checkStorageOptions()
    setStorageStatuses(storageOptions)
    setIsStorageReady(true)
  }, [])

  const store = async (key: string, item: any): Promise<void> => {
    await storeInCache(key, item, storageStatuses)
  }

  const getStoredData = async (key: string, timeLimit?: number): Promise<{
    data: any
    refetch: boolean
  }> => {
    const storedData = await retrieveFromCache(key, storageStatuses)
    if (storedData?.lastUpdated) {
      if (timeLimit) {
        const lastUpdated = new Date(storedData.lastUpdated)
        const shouldRefetch = lastUpdated.getTime() < Date.now() - timeLimit
        return {
          data: storedData,
          refetch: shouldRefetch,
        }
      }
      return {
        data: storedData,
        refetch: false,
      }
    }
    return {
      data: null,
      refetch: true,
    }
  }

  const deleteStoredData = async (key: string): Promise<void> => {
    await deleteFromCache(key, storageStatuses)
  }

  return (
    <StorageContext.Provider
      value={{
        storageStatuses,
        isStorageReady,
        store,
        getStoredData,
        deleteStoredData
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}
