import { styled } from '@mui/system'
import { colors } from '../../consts'

export const EnterGroupsModalCon = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(8),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    overflow: 'auto',
    '& .eligibleGroupText': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '90%',
      marginBottom: theme.spacing(1),
    },
  }))

  export const SelectedGroupCon = styled('div')<{ isSelected: boolean }>(
    ({ theme, isSelected }) => ({
      cursor: 'pointer',
      backgroundColor: isSelected ? colors.yellow : colors.gray.gray60,
      borderRadius: 100,
      [theme.breakpoints.down('md')]: {
        height: 30,
        width: 30,
      },
      height: 50,
      width: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.black,
      fontWeight: '600',
    })
  )

  export const GroupModalItemCon = styled('div')(({ theme }) => ({
    backgroundColor: colors.gray.gray90,
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 6,
    padding: theme.spacing(2, 1),
    marginBottom: theme.spacing(1),
    '& .itemLeft': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    '& .imgCon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(1, 2),
      borderRadius: '50%',
      padding: theme.spacing(1),
      background: colors.black,
      width: 100,
      height: 100,
      [theme.breakpoints.down('sm')]: {
        width: 50,
        height: 50,
      },
      '& img': {
        width: 65,
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
          width: 35,
          borderRadius: 2,
        },
      },
    },
    '& .dottedCircle': {
      cursor: 'pointer',
      border: `1px dashed ${colors.offWhite}`,
      borderRadius: 100,
      [theme.breakpoints.down('md')]: {
        height: 30,
        width: 30,
      },
      height: 50,
      width: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
      color: colors.white,
      fontWeight: '600',
    },
  }))
  