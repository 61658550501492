import { months, days, shortDays, PLL_LINKS } from '../Configs/config'

/**
 *
 * Converting UTC timestamp to shortened date from timestamp ie June 7 with day of week
 */
export const getShortDate = (
  timeStamp: any,
  includeDay: boolean = true
) => {
  const s = new Date(timeStamp * 1000)
  const dayOfWeek = includeDay ? `${getShortDayString(s)}, ` : ''
  const dateString = `${dayOfWeek}${
    months[s.getMonth()]
  } ${s.getDate()}`
  return dateString
}

/**
 *
 * Converting UTC timestamp and returning just the event's day of the month ie 7
 */
export const getDayOfMonth = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  return s.getDate()
}

/**
 *
 * Returning long date from timestamp ie Thursday, May 1 7pm ET
 */
export const timeStampToDate = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  const est = s.toLocaleTimeString('en-US', {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: 'numeric',
  })
  const tm = est.slice(0, -3)

  // remove extra :00 if exists
  const st = tm.includes(':00') ? tm.split(':00')[0] : tm

  //check if am or pm
  const ampm = est.includes('PM') ? 'pm' : 'am'
  const dateAndTime = {
    date: `${days[s.getDay()]}, ${months[s.getMonth()]} ${s.getDate()}`,
    time: `${st}${ampm} ET`,
  }
  return dateAndTime
}

/**
 *
 * Returning game time in eastern time
 */
export const getGameTime = (timeStamp: any) => {
  if(!timeStamp) return ''
  const s = new Date(timeStamp * 1000)
  const dateStr = s.toLocaleTimeString('en-US')

  // get just the time
  const tm = dateStr.slice(0, -3)

  // remove extra :00 if exists
  const st = tm.includes(':00') ? tm.split(':00')[0] : tm

  // 3am is the time set for a game that doesn't have a time, return TBD
  if (st.includes(':23')) return 'TBA'

  //check if am or pm
  const ampm = dateStr.includes('PM') ? 'pm' : 'am'

  return `${st}${ampm}`
}

/**
 *
 * Returning game time in user's local time
 */
export const getGameTimeLocal = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  const dateStr = s.toLocaleTimeString('en-US')

  // get just the time
  const tm = dateStr.slice(0, -3)

  // remove extra :00 if exists
  const st = tm.includes(':00') ? tm.split(':00')[0] : tm

  // :23 is the time set for a game that doesn't have a time, return TBD
  if (st.includes(':23')) return 'TBA'

  //check if am or pm
  const ampm = dateStr.includes('PM') ? 'pm' : 'am'

  return `${st}${ampm}`
}

export const getYearMonthDay = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  return [s.getFullYear(), s.getMonth() + 1, s.getDate()]
}

/**
 *
 * Converts a day number to a string.
 */
export const getShortDayString = (s: Date) => {
  return shortDays[s.getDay()]
}

export const abbreviateNumber = (value: number) => {
  let newValue = `${value}`
  if (value >= 1000) {
    let suffixes = ['', 'k', 'm']
    let suffixNum = Math.floor(('' + value).length / 3)
    let shortValue
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(3)
      )
      let dotLessShortValue = (shortValue + '').replace(
        /[^a-zA-Z 0-9]+/g,
        ''
      )
      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (!shortValue) return
    if (shortValue % 1 !== 0)
      shortValue = shortValue.toFixed(2).toString()
    newValue = shortValue.toString() + suffixes[suffixNum]
  }
  return newValue
}

export const addCommas = (number: number | null): string => {
  if (!number) return ''
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const calcPercent = (value: number, total: number): number => {
  return +((value / total) * 100).toFixed(0)
}

export const calcPercent1Dec = (value: number, total: number) => {
  let pctStr = ((value / total) * 100).toFixed(1)
  let cleaned = parseFloat(pctStr)
  return cleaned
}

/**
 * Convert value with decimal to representation with one digit precision
 */
export function Decimal1DigitNum(value: number): number {
  return Math.round(value * 10) / 10
}

type ShopParams = {
  medium: string
  slug?: string
  campaign: string
}

export const createShopUTM = ({
  medium,
  campaign,
  slug,
}: ShopParams) => {
  return `${PLL_LINKS.shop}/${
    slug ? slug : ''
  }?utm_source=Web_Main&utm_medium=${medium}&utm_campaign=${campaign}`
}

export const stringToNumber = (input: any): number | null => {
  if (typeof input === 'number') return input //No need to convert
  const result = parseFloat(input)
  return isNaN(result) ? null : result
}

export const formatUsername = (
  first?: string | null,
  last?: string | null
) => {
  let firstName = first ? first : ''
  let lastName = last ? last : ''

  return `${firstName} ${lastName}`
}

export function transformString(inputString: string) {
  let result = ''
  if (inputString.includes('-')) {
    result = inputString.replace(/-/g, ' ')
  } else {
    result = inputString.replace(/([A-Z])/g, ' $1')
  }
  return result.trim().replace(/\b\w/g, (c) => c.toUpperCase())
}
/**
 * Takes in division string param, separates numerator and denominator and returns [num, den]
 * Good for styling use cases
 */
export const getSplitQuotient = (value: string | number) => {
  if (!value || typeof value !== 'string' || !value.includes('/'))
    return [value]
  return value.split('/')
}

/**
 * Decodes a string from URI encoding
 */
export const decodeString = (str?: string | null) => {
  if(!str) return ''
  return decodeURIComponent(str)
}


