import React from 'react'
import { MAIN_PLL_URL } from '../../Configs/config'
import { FooterCon } from './styles'
import { Lk } from '../../Routing/utils'
import {
  // BRACKET_URL,
  FANTASY_TEAM_URL,
  PLLN_PROFILE_URL,
} from '../../Routing/urls'

export const Footer: React.FC = () => {
  return (
    <FooterCon>
      <div className="innerFooter">
        <Lk classNameObj="lnk" link={PLLN_PROFILE_URL}>
          PLL Nation
        </Lk>
        <Lk classNameObj="lnk" link={FANTASY_TEAM_URL}>
          Fantasy
        </Lk>
        <Lk classNameObj="lnk" link={'/'}>
          Competitions
        </Lk>
        {/* <Lk classNameObj="lnk" link={BRACKET_URL}>
          Bracket
        </Lk> */}
        <Lk
          classNameObj="lnk"
          link={`https://${MAIN_PLL_URL}/cash-app-challenge-terms-of-service`}
        >
          Terms
        </Lk>
      </div>
    </FooterCon>
  )
}
