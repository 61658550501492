import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { SuspensePage } from '../../Pages'

const FantasyProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.FantasyProvider,
  }))
)

export const FantasyContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
      <FantasyProvider>
        <Outlet />
      </FantasyProvider>
    </Suspense>
  )
}
