import { AddToCal, Bolt, Invite } from './Icons'

export type Color = 'white' | 'yellow' | 'dark' | 'grey'
export type ColorMapType = { [key in Color]: string }

export const colors = {
  pageBackground: '#111111',
  darkBackground: '#262626',
  darkLayout: '#3A3E40',
  white: '#fff',
  offWhite: '#FAFAFA',
  black: '#000',
  desc: '#A6A6A6',
  lightGrey: '#c7c7c7',
  lightestGrey: '#ececec',
  gray: {
    gray20: '#DEDEE3',
    gray40: '#B4B4B9',
    gray60: '#98989D',
    gray70: '#595959',
    gray75: '#3A3E40',
    gray80: '#313131',
    gray90: '#262626',
  },
  tan: '#B9B9B9',
  text: '#242424',
  helperText: 'rgba(0, 0, 0, .60)',
  yellow: '#ffcb06',
  opaqueYellow: 'rgba(255, 203, 6, .10)',
  blue: '#0764a6',
  lightBlue: '#3ba9f6',
  placeholder: '#f6f6f6',
  green: {
    green: '#00CB83',
    darkGreen: '#1D8E00',
    darkerGreen: 'rgba(17, 204, 0, 5%)',
    lightGreen: 'rgb(17, 204, 0)',
    greenOpaque: 'rgba(0, 195, 100, .20)',
  },
  red: '#EF114C',
  selected: '#4873ff',
  gold: '#ecbf28',
  limeGreen: '#28C101',
  redOpaque: 'rgba(255, 18, 18, .20)',
}

export const colorsMap: ColorMapType = {
  white: colors.white,
  yellow: colors.yellow,
  dark: colors.text,
  grey: colors.darkLayout,
}

export const zIndex = {
  loginOverlay: 99999999999,
  videoOverlay: 9999999999,
}

export const panelWidth = {
  desktop: 150,
  tablet: 140,
  mobile: 130,
  smMobile: 110,
}

export const SWIPER_ITEM_SIZE = {
  md: {
    desktop: 145,
    tablet: 130,
    mobile: 120,
    smMobile: 100,
  },
  sm: {
    desktop: 120,
    tablet: 80,
    mobile: 65,
    smMobile: 55,
  },
}

export const SLIDEUP_HEIGHTS: {[key: string]: string} = {
  full: '100%',
  twoThirds: '66%',
  half: '50%',
  third: '33%'
}

export const PLL_SOCIAL_URLS = {
  twitter: 'https://twitter.com/PremierLacrosse',
  instagram: 'https://instagram.com/pll/',
  youtube: 'https://www.youtube.com/pll',
  tiktok: 'https://www.tiktok.com/@pll',
  facebook: 'https://www.facebook.com/PremierLacrosseLeague/',
}

export const fontSizes = {
  xxSmall: 7,
  xSmall: 11, //'0.7rem',
  small: 13, //'0.8rem',
  medium: 17, //'1rem',
  semiLarge: 20, //
  large: 30, //
  larger: 33,
  xlarge: 38, //'1.2rem',
}

export const buttonWidths = {
  small: 75,
  medium: 100,
  large: 200,
}

export const getIcon = (icon: string) => {
  switch (icon) {
    case 'AddToCal':
      return AddToCal
    case 'Invite':
      return Invite
    case 'Bolt':
      return Bolt
    default:
      return AddToCal
  }
}