

export const HOME_URL = '/'
export const PICKEM_URL = '/pickem'
export const BRACKET_URL = '/bracket'
export const PREDICTOR_URL = '/predictor'
export const FANBASE_RACE_URL = '/fanbase-race'
export const LOGIN_URL = '/login'
export const INVITE_URL = '/invite'
export const PLLN_URL = '/pll-nation'
export const PLLN_PROFILE_URL = `${PLLN_URL}/profile`
export const ACHIEVEMENTS_URL = `${PLLN_URL}/achievements`
export const BENEFITS_URL = `${PLLN_URL}/benefits`
export const BADGES_URL = `${PLLN_URL}/badges`
export const CODE_CLAIM_URL = `${PLLN_URL}/redeem`
export const PLLN_EDIT_PROFILE_URL = `${PLLN_PROFILE_URL}/edit`
export const PLAYER_STARS_URL = `/player-stars`
export const PLAYER_STARS_REVEAL_URL = `/player-stars/reveal`
export const CODE_REDEEM_URL = '/redeem'
export const FANTASY_URL = '/fantasy'
export const FANTASY_TEAM_URL = `${FANTASY_URL}/team`
export const FANTASY_GROUPS_URL = `${FANTASY_URL}/groups`
export const FANTASY_CHALLENGERS_URL = `${FANTASY_URL}/challengers`
export const FANTASY_MATCHUP_URL = `${FANTASY_URL}/matchup`
export const FANTASY_ACCEPT_CHALLENGE_URL = `${FANTASY_URL}/accept-challenge`
export const FANTASY_PLAYERS_URL = `${FANTASY_URL}/players`
export const FANTASY_GROUPS_COMPETITION_URL = `${FANTASY_URL}/competition/groups`
export const FANTASY_GROUPS_ENTER_COMPETITION_URL = `${FANTASY_URL}/groups/competition/enter`
export const RELEASE_URL = `release`