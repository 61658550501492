import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AuthContext } from '../../Api/firebase'
import { getStarsArr } from '../../Utils'
import { PlayerStarType } from '../../Api/types/productConfigs.types'
import { GamificationContext } from '../GamificationContext/GamificationContext'

type PlayerStarsContextType = {
  playerStarsArray: PlayerStarType[]
  isLoadingStars: boolean
  updatePlayerStarsArray: (starId: string | null) => void
}

export const PlayerStarsContext =
  createContext<PlayerStarsContextType | null>(null)

export const PlayerStarsProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  let { pllNation } = useContext(AuthContext)!
  let { gamificationConfig } = useContext(GamificationContext)!
  const [playerStarsArray, setPlayerStarsArray] = useState<
    PlayerStarType[]
  >([])
  const [isLoadingStars, setIsLoadingStars] = useState<boolean>(true)

  useEffect(() => {
    if (!gamificationConfig?.playerStarTypes || !pllNation?.playerStars) return

    let sortedStars = getStarsArr(
      gamificationConfig.playerStarTypes,
      pllNation?.playerStars
    )
    setPlayerStarsArray(sortedStars)
    setIsLoadingStars(false)
  }, [gamificationConfig, pllNation])

  const updatePlayerStarsArray = (starId: string | null) => {
    if (!starId) return

    let starsArr = [...playerStarsArray]
    let locked: PlayerStarType[] = []
    let unlocked: PlayerStarType[] = []

    starsArr.map((star) => {
      if (star.playerStarId === starId) {
        star.unlocked = true
        star.claimDate = Math.floor(new Date().getTime() / 1000)
      }
      if (star.unlocked) {
        unlocked.push(star)
      } else {
        locked.push(star)
      }
    })

    setPlayerStarsArray([...unlocked, ...locked])
  }
 
  return (
    <PlayerStarsContext.Provider
      value={{
        playerStarsArray,
        isLoadingStars,
        updatePlayerStarsArray
      }}
    >
      {children}
    </PlayerStarsContext.Provider>
  )
}
