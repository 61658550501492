import React, { useEffect } from 'react'
import { ADSENSE_CLIENT_ID, AD_OPTIONS } from '../../Configs/Ads/ads'
import { AdCon } from './styles'
import { BottomCon } from '../Layout/Overlays/styles'

type AdProps = {
  bannerType: string
  bottomFixed?: boolean
  inContent?: boolean
  zIndex?: number
}

export const AdUnit: React.FC<AdProps> = ({
  bannerType,
  bottomFixed = false,
  inContent = false,
  zIndex = 999
}) => {
  const banner = AD_OPTIONS[bannerType]

  useEffect(() => {
    try {
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (e) {}
  }, [])

  if (bottomFixed) {
    return (
      <>
        {banner && (
          <BottomCon zIndex={zIndex}>
            <AdCon vertMargins={1} sideMargins={0.5}>
              <ins
                className="adsbygoogle"
                data-ad-client={ADSENSE_CLIENT_ID}
                data-ad-slot={banner.slotId}
              ></ins>
            </AdCon>
          </BottomCon>
        )}
      </>
    )
  } else if (inContent === true) {
    return (
      <>
        {banner && (
          <AdCon vertMargins={1} sideMargins={0.5}>
            <ins
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-format="fluid"
              data-ad-layout-key={banner.layoutKey}
              data-ad-client={ADSENSE_CLIENT_ID}
              data-ad-slot={banner.slotId}
            ></ins>
          </AdCon>
        )}
      </>
    )
  } else {
    return (
      <>
        {banner && (
          <AdCon vertMargins={1} sideMargins={0.5}>
            <ins
              className="adsbygoogle"
              data-ad-client={ADSENSE_CLIENT_ID}
              data-ad-slot={banner.slotId}
            ></ins>
          </AdCon>
        )}
      </>
    )
  }
}
