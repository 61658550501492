import { callGraphQL } from '../utils/request'
import type { BannerRes, Banner } from './banners.types'

/**
 * Fetch all active banners
 */
export async function fetchActiveBanners(
  isActive: boolean,
  page?: string
): Promise<Banner[]> {
  try {
    const bannerRes = await callGraphQL<BannerRes>(activeBannersQuery, {
      isActive,
      page,
    })
    if (!bannerRes?.banners) return []
    return bannerRes.banners
  } catch (err) {
    return []
  }
}

const activeBannersQuery = `
query($isActive: Boolean, $page: String){
  banners(isActive:$isActive, page:$page, type:banner){
    id
    sponsor
    topUrl
    topLink
    leftSideUrl
    rightSideUrl
    secondaryMobile
    location
    startTime
    endTime
    tags
  }
}
`
