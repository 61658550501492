import { Pick } from '../Api'
import { SEASON } from '../Configs/config'
import { FANTASY_GENERATE_WEEK_TOKENS } from '../Constants/fantasy'

export const checkLocalCashTag = () => {
  const localStorage = window.localStorage
  if (localStorage) {
    const cashTag = localStorage.getItem('bracketCashTag')
    if (!cashTag) {
      return
    }
    return cashTag
  }
  return
}

export const setCashTagLocally = (cashTag: string) => {
  if (localStorage) {
    const localStorage = window.localStorage
    localStorage.setItem('bracketCashTag', cashTag)
  }
}

export const removeLocalStorageItem = (name: string) => {
  if (localStorage) {
    const localStorage = window.localStorage
    localStorage.removeItem(name)
  }
}

// FANTASY

//FANTASY CHALLENGE LINK
export const checkStoredLocalChallengeLink = () => {
  const localStorage = window.localStorage
  if (localStorage) {
    const link = localStorage.getItem('fantasyChallengeLink')
    if (!link) {
      return
    }
    return link
  }
  return
}

export const setLocalFantasyChallengeLink = (link: string) => {
  const localStorage = window.localStorage
  if (localStorage) {
    localStorage.setItem('fantasyChallengeLink', link)
  }
}

// REFERRALS
export const checkStoredSharedLink = () => {
  const localStorage = window.localStorage
  if (localStorage) {
    const link = localStorage.getItem('bracketShareLink')
    if (!link) {
      return
    }
    return link
  }
  return
}

export const setBracketShareLink = (link: string) => {
  const localStorage = window.localStorage
  if (localStorage) {
    localStorage.setItem('bracketShareLink', link)
  }
}

//BRACKET LOCAL STORAGE
export const checkLocalBracketSelections = () => {
  if (localStorage) {
    const item = localStorage.getItem('localBracketSelections')
    if (item) {
      let sels: Pick[] = JSON.parse(item)
      return sels
    } else {
      //No selections, fetch
      console.log('No selected teams')
      return []
    }
  }
  return
}

export const setLocalBracketSelections = (selections: Pick[]) => {
  if (selections.length > 0) {
    if (localStorage) {
      localStorage.setItem(
        'localBracketSelections',
        JSON.stringify(selections)
      )
    }
  }
  return
}

//LAYOUT Local Storage

export const checkAppCalloutClosedCount = (calloutId: string) => {
  if (localStorage) {
    const item = localStorage.getItem(`${calloutId}_close_count`)
    if (item) {
      let count = JSON.parse(item)
      return parseInt(count)
    } else {
      console.log('No closes yet')
      return 0
    }
  }
  return 0
}
export const setAppCalloutClosedCount = (calloutId: string) => {
  const localStorage = window.localStorage
  if (localStorage) {
    let count = checkAppCalloutClosedCount(calloutId)
    count++
    //Set the new count locally
    localStorage.setItem(`${calloutId}_close_count`, count.toString())
  }
}

//FANTASY AI Generation
export const checkRemainingGenTokensLocal = (week: number) => {
  if (localStorage) {
    const tokenObj = getRemainingGenTokensLocal()
    if (!tokenObj) return FANTASY_GENERATE_WEEK_TOKENS //default for new user
    const weekStr = `week_${week}_${SEASON}`
    if (weekStr in tokenObj && tokenObj[weekStr] >= 0) {
      return tokenObj[weekStr]
    }
  }
  return FANTASY_GENERATE_WEEK_TOKENS
}

export const getRemainingGenTokensLocal = () => {
  if (localStorage) {
    const localObj = localStorage.getItem('_fantasyGenerationTokens')
    if (!localObj) return null
    return JSON.parse(localObj)
  }
  return null
}

export const updateRemainingGenTokensLocal = (
  remainingCount: number,
  week: number
) => {
  if (localStorage) {
    const storedTokenObj = getRemainingGenTokensLocal()
    const weekStr = `week__${week}_${SEASON}`
    if (!storedTokenObj || Object.keys(storedTokenObj).length === 0) {
      //No tokens yet
      const newTokens = {
        [weekStr]: remainingCount,
      }
      localStorage.setItem(
        '_fantasyGenerationTokens',
        JSON.stringify(newTokens)
      )
      return
    } else {
      const obj = { ...storedTokenObj, [weekStr]: remainingCount }
      localStorage.setItem(
        '_fantasyGenerationTokens',
        JSON.stringify(obj)
      )
    }
  }
  return
}

//TOOLTIP LOCAL STORAGE
export const checkLocalAITooltip = () => {
  if (localStorage) {
    const lastClosed = localStorage.getItem('lastClosedAITip')
    if (!lastClosed) return true
    const lastClosedDate = new Date(lastClosed)
    const currentDate = new Date()
    const diff = currentDate.getTime() - lastClosedDate.getTime()
    if (diff > 86400000) return true
  }
  return false
}

export const setLocalAITooltip = () => {
  if (localStorage) {
    localStorage.setItem('lastClosedAITip', new Date().toISOString())
  }
  return
}
