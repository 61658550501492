import { colors } from "../consts"
import { styled } from '@mui/system'
import { LockIcon } from "../LazyComponents/lazyIcons"

const LockedCon = styled(LockIcon)<{iconColor?: string}>(
    ({iconColor}) => ({
    color: iconColor || colors.gray.gray20,
    height: 20,
  }))

type LockedProps = {
    iconColor?: string
}

export const Locked: React.FC<LockedProps> = ({iconColor}) => {
    return (
        <LockedCon iconColor={iconColor}/>
    )
}