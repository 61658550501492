import { FantasyUser } from '../../Api'
import { FantasyPlayer } from '../../Api/Fantasy/fantasy.types'
import { PLL_IMAGES } from '../../Configs/config'

export const updateImagePrefix = (src: string) => {
  const firstFourChars = src.substring(0, 4)
  return firstFourChars === 'http' ? src : `https://${src}`
}

export const generateProfileImg = (
  user: FantasyUser | FantasyPlayer | null
) => {
  if (user === null) return PLL_IMAGES.defaultProfile

  return user?.profileUrl
    ? updateImagePrefix(user.profileUrl)
    : PLL_IMAGES.defaultProfile
}
