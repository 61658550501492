import React, { useEffect, useState } from 'react'
import {
  AppCalloutCon,
  AppLinkButton,
  CloseCon,
  OptionCon,
} from '../styles'
import { PLL_IMAGES } from '../../../../Configs/config'
import { checkAppCalloutClosedCount, checkIfEmbededInPLLApp, logEvent, setAppCalloutClosedCount } from '../../../../Utils'
import { Img } from '../../../Images/Img'
import { ANALYTICS_TAGS } from '../../../../Constants/analytics'

type DrawerParams = {
  calloutId: string
  appLink: string
  location: string
  text?: string
  showOnAndroid?: boolean
  showOnPLLApp?: boolean
  maxCloseCount?: number
}

export const BottomAppCallout: React.FC<
  React.PropsWithChildren<DrawerParams>
> = ({
  calloutId,
  appLink,
  location,
  text,
  showOnAndroid = true,
  showOnPLLApp = false,
  maxCloseCount = 0,
}) => {
  const [open, setOpen] = useState(false)
  const isAndroid = /Android|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )

  const isPLLAppEmbedded = checkIfEmbededInPLLApp()
  const defaultText = 'This page looks better in the app'

  useEffect(() => {
    // 0 means infinite close count
    if(maxCloseCount === 0) return setOpen(true)
    const closeCount = checkAppCalloutClosedCount(calloutId)
    if (closeCount && closeCount >= maxCloseCount) {
      return
    }
    setOpen(true)
  }, [calloutId])

  const toggleDrawer = () => () => {
    maxCloseCount > 0 && setAppCalloutClosedCount(calloutId)
    setOpen(!open)
  }

  const log = () => {
    logEvent(ANALYTICS_TAGS.app_callout_click, {
      location: location,
      link: appLink,
      text: text || defaultText,
    })
  }

  //Don't show on Android devices
  if (isAndroid && !showOnAndroid) {
    return <div />
  }

  //Don't show on embedded in PLL App
  if (isPLLAppEmbedded && !showOnPLLApp) {
    console.log('Embedded in PLL App')
    return <div />
  }

  return (
    <>
      {open && (
        <AppCalloutCon>
          <OptionCon>
            <CloseCon onClick={toggleDrawer()}>
              <p>X</p>
            </CloseCon>
            <Img src={PLL_IMAGES.pllAppLogo} alt="App Logo" />
            <div className="text">{text ? text : defaultText}</div>
            <a href={appLink}>
              <AppLinkButton onClick={() => log()}>Open</AppLinkButton>
            </a>
          </OptionCon>
        </AppCalloutCon>
      )}
    </>
  )
}
