import { useContext } from 'react'
import { RightArrow } from '../../Icons/RightArrow'
import { IneligibleIndicatorCon } from './styles'
import { FantasyContext, FantasyGroupContext } from '../../../Context'
import { Desc } from '../../Typography/Text.styles'
import { colors, fontSizes } from '../../consts'
import { SingleFantasyGroupCompetition } from '../../../Api'
import { useNavigate } from 'react-router-dom'
import { FANTASY_GROUPS_COMPETITION_URL } from '../../../Routing/urls'
import { Error } from '../../Icons'
import { logEvent } from '../../../Utils'
import { ANALYTICS_TAGS } from '../../../Constants/analytics'

type IneligibleProps = {
  competition: SingleFantasyGroupCompetition
}

export const IneligibleGroupIndicator: React.FC<IneligibleProps> = ({
  competition,
}) => {
  const nav = useNavigate()
  let { fantasyConfig } = useContext(FantasyContext)!
  let { singleGroup } = useContext(FantasyGroupContext)!
  let usersToAdd = singleGroup?.totalUsers
    ? `${
        (fantasyConfig?.groupCompetitions?.minimumRequiredMembers ??
          3) - singleGroup.totalUsers
      } more teams`
    : `teams`

  const handleIndicatorClick = () => {
    logEvent(ANALYTICS_TAGS.fantasy_competition_callout_click, {
      competition_id: competition.competitionId,
      competition_name: competition.name,
      location: 'single_group',
      button_text: 'ineligible',
    })
    nav(
      `${FANTASY_GROUPS_COMPETITION_URL}/${competition?.competitionId}`
    )
  }
  return (
    <IneligibleIndicatorCon onClick={handleIndicatorClick}>
      <div className="leftCon">
        <Error />
        <Desc color={colors.gray.gray20} fontSize={fontSizes.small}>
          Add {usersToAdd} to qualify for prizes in the{' '}
          <b>{competition.name}</b>
        </Desc>
      </div>
      <RightArrow />
    </IneligibleIndicatorCon>
  )
}
