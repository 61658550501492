import React, { useContext, useEffect, useState } from 'react'
import { LoginStyle, MobileHelperCon } from './styles'
import { AuthContext } from '../../Api/firebase'
import { isMobile } from '../../Utils'

type LoginParams = {
  title?: string
  desc?: string
  buttonText?: string
  extraFields?: string[]
  imgComponent?: React.ReactNode
  theme: 'light' | 'dark'
}

export const GenLoginForm: React.FC<LoginParams> = ({
  title = 'Log in to PLL',
  desc,
  buttonText = 'Log In',
  extraFields = [],
  imgComponent,
  theme = 'light',
}) => {
  const auth = useContext(AuthContext)!
  let copyLink = window.location.href
  const [copied, setCopied] = useState<string | null>(null)
  const [showHelper, setShowHelper] = useState<boolean>(false)

  useEffect(() => {
    // Set timer to clear copied so user can click multiple times
    if (copied) {
      const timer = setTimeout(() => setCopied(''), 2000)
      return () => clearTimeout(timer)
    }
    return
  }, [copied])

  useEffect(() => {
    let mobile = isMobile()
    if (mobile) {
      const showTimer = setTimeout(() => setShowHelper(true), 4000)
      return () => clearTimeout(showTimer)
    }
    return
  }, [])

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(copyLink || origin)
      setCopied('Copied!')
    } catch (err) {
      console.log('Error copying link', err)
    }
  }

  return (
    <>
      {!auth.isLoading && (
        <LoginStyle uiTheme={theme}>
          {imgComponent && imgComponent}
          <div className="loginTitle">{title}</div>
          {desc && <div className="loginDesc">{desc}</div>}
          <button
            className="loginBtn"
            onClick={() => auth.login(extraFields)}
          >
            {buttonText}
          </button>
          {showHelper && (
            <MobileHelperCon uiTheme={theme}>
              <div className="loginDesc">
                Trouble logging in? Copy and paste in your phone
                browser.
              </div>
              <button className="helpBtn" onClick={handleClick}>
                {!copied ? 'Copy' : 'Copied!'}
              </button>
            </MobileHelperCon>
          )}
        </LoginStyle>
      )}
    </>
  )
}
