import { useContext, useEffect, useState } from 'react'
import { colors, fontSizes, SLIDEUP_HEIGHTS } from '../../consts'
import { SlideUp } from '../../SlideUp/SlideUp'
import { GroupModalItem } from './GroupModalItem'
import { Desc, Head2 } from '../../Typography/Text.styles'
import { InfoIcon } from '../../Icons/InfoIcon'
import { FantasyContext, FantasyGroupContext } from '../../../Context'
import { InfoSlideUp } from './InfoSlideUp'
import { EnterGroupsModalCon } from './styles'
import { FantasyGroup } from '../../../Api'

type GroupsModalProps = {
  compId?: number
}

export const EnterGroupsModal: React.FC<GroupsModalProps> = ({
  compId,
}) => {
  let { fantasyConfig } = useContext(FantasyContext)!
  let { myGroupList, showModal, handleShowModal } = useContext(FantasyGroupContext)!
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false)
  const [eligibleGroups, setEligibleGroups] = useState<FantasyGroup[]>([])

  useEffect(() => {
    let groups = myGroupList.filter(
      (g) =>
        g.totalUsers <
          (fantasyConfig?.groupCompetitions?.maximumMembersAllowed ??
            15) &&
        g.private
    )
    setEligibleGroups(groups)
  }, [myGroupList])

  const handleShowInfoModal = () => {
    setShowInfoModal(!showInfoModal)
  }

  return (
    <SlideUp
      height={SLIDEUP_HEIGHTS.full}
      visible={showModal?.enterGroups?.show}
      showClose
      handleClose={() => handleShowModal('enterGroups')}
    >
      <EnterGroupsModalCon>
        <GroupModalItem />
        <div className="eligibleGroupText">
          <Head2 color={colors.white}>Eligible Groups</Head2>
          <div style={{cursor: 'pointer'}} onClick={handleShowInfoModal}>
            <InfoIcon
              fontSize={fontSizes.semiLarge}
              iconColor={colors.white}
            />
          </div>
        </div>
        {eligibleGroups.map((group) => (
          <GroupModalItem group={group} compId={compId} />
        ))}
        {eligibleGroups.length < 1 && (
          <Desc color={colors.gray.gray40} style={{ width: '90%' }}>
            You have no eligible groups for this competition. Create a
            new group and invite friends to get started.
          </Desc>
        )}
      </EnterGroupsModalCon>
      <InfoSlideUp
        showModal={showInfoModal}
        handleShowModal={handleShowInfoModal}
      />
    </SlideUp>
  )
}
