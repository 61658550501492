import { WriteupCon } from './styles'

type WriteupProps = {
  data: string
}

export const Writeup: React.FC<WriteupProps> = ({ data }) => {
  return (
    <WriteupCon
      dangerouslySetInnerHTML={{
        __html: data || '',
      }}
    />
  )
}
