import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { SuspensePage } from '../../Pages'

const PlayerStarsProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.PlayerStarsProvider,
  }))
)
const GamificationPublicProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.GamificationPublicProvider,
  }))
)

export const PlayerStarsContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
      <PlayerStarsProvider>
        <GamificationPublicProvider>
          <Outlet />
        </GamificationPublicProvider>
      </PlayerStarsProvider>
    </Suspense>
  )
}
