import React from 'react'
import { PLL_IMAGES } from '../../../Configs/config'
import { PrizeCircleCon } from './styles'
import { Img } from '../../Images/Img'

export const PrizeCircleIC: React.FC<
  React.PropsWithChildren
> = () => {
  return (
    <PrizeCircleCon>
      <Img src={PLL_IMAGES.yellow_trophy} alt={'Trophy Icon'} />
    </PrizeCircleCon>
  )
}
