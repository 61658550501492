import type { Event } from '../Api'
import { calcPercent1Dec, getShortDate } from './formatting'
import {
  ALL_STAR_WEEK,
  BROADCAST_IMG,
  PLL_LINKS,
  REVERSE_BROADCAST_IMG,
  SEASON,
} from '../Configs/config'
import { FanResults } from '../Api/types/events.types'

type EventSortKeys = keyof Event

type EventWeekParams = {
  week: string
  events: Event[]
}

/**
 *
 * Return tie delta
 */
export const renderTieDeltaRaw = (
  tiebreaker: number,
  totalShots: number
) => {
  return totalShots - tiebreaker
}

/**
 *
 * Return tie delta with + or minus
 */
export const renderTieDelta = (
  tiebreaker: number | string,
  totalShots: number
) => {
  if (typeof tiebreaker === 'string') {
    tiebreaker = parseInt(tiebreaker)
  }
  const delta = tiebreaker - totalShots
  if (delta > 0) {
    return `(+${delta})`
  } else {
    return `(${delta})`
  }
}

/**
 *
 * Get current Week
 */
export const getCurrentWeek = (events: Event[]) => {
  const weekRes = events.find((ev) => ev.eventStatus < 2)
  if (!weekRes) return null
  return weekRes.week
}

/**
 *
 * Map events into specific weeks
 */
export const getWeekList = (events: Event[]) => {
  let weekList: number[] = []
  events.map((e) => {
    let parsed = parseWeek(e.week)
    let we = weekList.find((w) => w === parsed)
    if (we === undefined) {
      //Week wasn't added yet
      weekList.push(parsed)
    }
    return
  })
  return weekList
}

/**
 *
 * Map events into specific weeks
 */
export const mapEventWeeks = ({ week, events }: EventWeekParams) => {
  return events.filter((event) => event.week === week)
}

/**
 *
 * Map events into specific weeks
 */
export const parseWeek = (wk: string) => {
  if (wk === '0') {
    return 0
  }
  return Number(wk)
}

/**
 *
 * Return date range of all games in a specific weekend
 */
export const getWeekendRange = (events: Event[], week?: string) => {
  if (events.length === 0) return ''
  let wk: string = ''
  if (!week) {
    wk = events[0].week
  } else {
    wk = week
  }
  const eventList = events.filter((ev) => ev.week === wk)
  // Only one event, grab the date
  if (eventList.length === 1)
    return getShortDate(eventList[0].startTime)
  const sorted = eventList.sort((a, b) => a.startTime - b.startTime)
  const [first] = sorted.slice(0)
  const [last] = sorted.slice(-1)
  const firstDay = getShortDate(first.startTime)
  const lastDay = getShortDate(last.startTime)
  return firstDay === lastDay
    ? firstDay
    : `${firstDay}${` - ${lastDay}`}`
}

/**
 *
 * Return number of events in the specific week
 */
export const getWeekEventCount = (week: number, events: Event[]) => {
  const eventList = events.filter((ev) => parseInt(ev.week) === week)
  return eventList.length
}

/**
 *
 * Return text to display in button based on event status
 */
export const getActionText = (status: number) => {
  return status === 0
    ? 'Preview'
    : status === 1
    ? 'Watch Live'
    : 'Game Stats'
}

/**
 *
 * Return all broadcaster images for specific event
 */
export const getBroadcastImg = (broadcasters: string[]) => {
  const broadcastImgs = broadcasters.map((name) => {
    switch (name) {
      case 'ESPN':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ESPN2':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ABC':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ESPNU':
        return `${BROADCAST_IMG}/${name}_Black.png`
      case 'ESPN+':
        return `${BROADCAST_IMG}/${name.replace('+', '%2B')}_Black.png`
      case 'Peacock':
        return `${BROADCAST_IMG}/${name}_Black.png`
      case 'NBC':
        return `${BROADCAST_IMG}/${name}_Black.png`
      default:
        return ''
    }
  })
  return broadcastImgs
}

/**
 *
 * Return all broadcaster images for specific event
 */
export const getReverseBroadcastImg = (broadcasters: string[]) => {
  const broadcastImgs = broadcasters.map((name) =>
    name === ''
      ? ' '
      : name === 'ABC'
      ? `${REVERSE_BROADCAST_IMG}/${name.replace('+', '%2B')}_Main.png`
      : `${REVERSE_BROADCAST_IMG}/${name.replace('+', '%2B')}_White.png`
  )
  return broadcastImgs
}

/**
 *
 * Return link  based on event status
 */
export const getEventActionLink = (event: Event) => {
  switch (event.eventStatus) {
    case 0:
      return `${PLL_LINKS.matchups}/${event.slugname}`
    case 1:
      return event.urlStreaming
    case 2:
      return `${PLL_LINKS.stats}/games/${SEASON}/${event.slugname}`
    case 3:
      return `${PLL_LINKS.stats}/games/${SEASON}/${event.slugname}`
    default:
      return `${PLL_LINKS.matchups}/${event.slugname}`
  }
}

/**
 *
 * Return link  based on event status
 */
export const checkSpecialWeek = (week: number) => {
  const wk = week.toString()
  if (wk === '6' || wk === '9' || wk === '10' || wk === '11') {
    return true
  }
  return false
}

/**
 *
 * sort events by field
 */
export const sortEvents = (
  events: Event[],
  sortBy: EventSortKeys,
  ascDesc: -1 | 1
): Event[] => {
  return events.sort((a, b) => {
    const A = a[sortBy]
    const B = b[sortBy]
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

/**
 *
 * Get % vote of your selected team
 */
export const getTeamVotePercent = (
  pickedTeam: string,
  fanResults?: FanResults[]
) => {
  let initialValue = 0
  if (!fanResults) return '-'
  let totalFanVote = fanResults.reduce(
    (previousValue, currentValue) => previousValue + currentValue.count,
    initialValue
  )
  let yourTeamCount = fanResults.find((v) => v.teamId === pickedTeam)
  return calcPercent1Dec(yourTeamCount?.count || 0, totalFanVote || 1)
}

/**
 *
 * Get all events in single week
 */
export const filterForWeekEvents = (evs: Event[], wk: number) => {
  if (wk === ALL_STAR_WEEK) {
    return evs.filter(
      (ev) =>
        ev.week === wk.toString() &&
        ev?.description &&
        !ev.description.includes('Skills')
    )
  }
  return evs.filter((ev) => ev.week === wk.toString())
}

/**
 *
 * Check if a game of the week has started
 */
export const hasWeekendStarted = (evs: Event[], week: number) => {
  const now = new Date().getTime() / 1000
  const weekEvs = filterForWeekEvents(evs, week)
  const startedGame = weekEvs.find((ev) => ev.startTime < now)
  return startedGame ? true : false
}
