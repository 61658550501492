import { colors, SLIDEUP_HEIGHTS } from '../../consts'
import { SlideUp } from '../../SlideUp/SlideUp'
import { Desc, Head2 } from '../../Typography/Text.styles'
import { InfoSlideUpCon } from '../../../Pages/Fantasy/GroupCompetition/styles'

type InfoProps = {
  showModal: boolean
  handleShowModal: () => void
}

export const InfoSlideUp: React.FC<InfoProps> = ({
  showModal,
  handleShowModal,
}) => {
  return (
    <SlideUp
      height={SLIDEUP_HEIGHTS.third}
      visible={showModal}
      showClose
      handleClose={handleShowModal}
    >
      <InfoSlideUpCon>
        <Head2 className='text' color={colors.white}>Eligibility</Head2>
        <Desc className='text' color={colors.gray.gray40}>Only groups with <b>3 - 15 members</b> are eligible.</Desc>
      </InfoSlideUpCon>
    </SlideUp>
  )
}
