import { styled } from '@mui/system'
import { colors } from '../consts'

export const FooterCon = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  background: colors.black,
  height: 60,
  [theme.breakpoints.down('md')]: {
    height: 30,
  },
  '& .innerFooter': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.between('md', 'lg')]: {
      width: '75%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  '& .lnk': {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
    textDecoration: 'none',
    fontSize: '1rem',
    color: colors.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
    '& a': {
      cursor: 'pointer',
      margin: theme.spacing(0, 1),
      textDecoration: 'none',
      fontSize: '1rem',
      color: colors.white,
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
      },
    },
  },
}
}))
