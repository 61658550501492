import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import { SEASON, playoffEventIds } from '../../Configs/config'
import { AuthContext } from '../../Api/firebase'
import { SeasonContext } from '../SeasonContext'
import {
  submitPick,
  PickMutationParams,
  updateUserProfile,
  fetchUsersBracketPicks,
} from '../../Api'
import {
  checkLocalCashTag,
  setCashTagLocally,
  setLocalBracketSelections,
  checkSinglePickEnabled,
  PickStatus
} from '../../Utils'
import type { Event, Pick, User, BracketPick } from '../../Api'
import {
  TeamFrontData,
  teamsDataArray,
} from '../../Utils/Teams/teamHelpers'
import { CashTagPopup } from '../../Components/Popups/CashTagPopup'

type PicksProviderProps = {
  userId?: string
  children?: React.ReactNode
}
type PicksContextType = {
  user: User | null
  activeWeek: number
  events: Event[]
  teamList: TeamFrontData[]
  selections: Pick[]
  pickStatus: PickStatus
  cashTag: string | null
  isEditing: boolean
  showModal: boolean
  showTutorial: boolean
  showSnackbar: boolean
  showChampion: boolean
  toggleModal: () => void
  toggleTutorial: () => void
  toggleSnackbar: () => void
  handleBracketTeamSelect: (bracketPick: BracketPick) => Promise<void>
  handleCashTagSubmit: (cashTag: string) => Promise<any>
  shouldShowCashAppPopover: (prevChamp: string, champ: string) => void
}

export const PicksContext = createContext<PicksContextType | null>(null)

export const PicksProvider: React.FC<PicksProviderProps> = ({
  children,
}) => {
  const { uid, isLoggedIn, login } = useContext(AuthContext)!
  const { activeWeek, events: seasonEvents } =
    useContext(SeasonContext)!
  const [user, setUser] = useState<User | null>(null)
  const [events, setEvents] = useState<Event[]>([])
  const [teamList, setTeamList] =
    useState<TeamFrontData[]>(teamsDataArray)
  const [selections, setSelections] = useState<Pick[]>([])
  const [cashTag, setCashTag] = useState<string | null>(null)
  const [pickStatus, setPickStatus] = useState<PickStatus>('disabled')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showTutorial, setShowTutorial] = useState<boolean>(false)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [showChampion, setShowChampion] = useState<boolean>(false)
  const [showTagModal, setShowTagModal] = useState<boolean>(false)
  const FIRST_QUARTERFINAL = playoffEventIds.q3 // TODO: Change to start time - ID of first quarterfinal game

  useEffect(() => {
    if (!uid) {
      //Either not logged in or just logged out
      setSelections([])
      setCashTag(null)
      setUser(null)
    }
    checkStoredCashTag()
    fetchData()
  }, [uid])

  useEffect(() => {
    if (seasonEvents.length < 1) return
    setEvents(seasonEvents)
  }, [seasonEvents])

  const fetchData = async () => {
    try {
      if (!uid) {
        console.log('No User')
        return
      }
      return await fetchBracketSelections()
    } catch (err) {
      return err
    }
  }

  const fetchBracketSelections = async () => {
    if (!uid) return

    const bracketSelections = await fetchUsersBracketPicks(uid)
    if (!bracketSelections) return
    if (
      bracketSelections?.picks &&
      bracketSelections.picks.length > 0
    ) {
      setSelections(bracketSelections.picks)
    }
  }

  const checkStoredCashTag = () => {
    let tag = checkLocalCashTag()
    if (tag) {
      setCashTag(tag)
    }
    return
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const toggleTutorial = () => {
    setShowTutorial(!showTutorial)
  }

  const toggleSnackbar = () => {
    setShowSnackbar(true)
    //show for only 3 seconds
    setTimeout(() => {
      setShowSnackbar(false)
    }, 3000)
  }

  const toggleCashTagModal = () => {
    setShowTagModal(!showTagModal)
  }

  const shouldShowCashAppPopover = () => {
    if (cashTag) {
      //Already submitted Cash Tag
      return
    }
    setTimeout(() => {
      toggleCashTagModal()
    }, 2000)
    return
  }

  // BRACKET
  const handleBracketTeamSelect = async (bracketPick: BracketPick) => {
    if (!isLoggedIn) return login() //Log them in first

    // Check if first quarterfinal game is set
    const eventPickEnabled = checkSinglePickEnabled(
      FIRST_QUARTERFINAL,
      events
    )
    //If pick is closed, return
    if (!eventPickEnabled) return
    if (!uid) return

    //Keeping copy of Previous Picks
    let prevSelections = [...selections]

    //Add Pick To State
    addPickToState(bracketPick)

    let pk: PickMutationParams = {
      id: bracketPick.id,
      week: parseInt(bracketPick.week),
      teamId: bracketPick.teamId,
      year: SEASON,
      userId: uid,
    }
    let successPick = await submitPick(pk)
    if (!successPick) {
      alert('Error submitting pick')
      //Reset Pick to previous on error
      setSelections(prevSelections)
      //Reset local pick cache
      setLocalBracketSelections(prevSelections)
      return
    } else {
      if (bracketPick.slugname.includes('champion')) {
        shouldShowCashAppPopover()
      }
    }
  }

  const addPickToState = (pk: BracketPick) => {
    const pick: Pick = {
      id: pk.id,
      week: parseInt(pk.week),
      pickedTeam: pk.teamId,
    }
    let userSelections = [...selections]
    const prevSelection = userSelections.find(
      (sel) => sel.id === pick.id
    )
    if (selections.length < 1 || !prevSelection) {
      userSelections.push(pick)
      setIsEditing(true)
    } else {
      const idx = userSelections.findIndex((sel) => sel.id === pick.id)
      const prevPick = userSelections[idx].pickedTeam
      userSelections[idx].pickedTeam = pick.pickedTeam
      let index = -1
      if (
        pick.id === playoffEventIds.q1 ||
        pick.id === playoffEventIds.q2
      ) {
        index = userSelections.findIndex(
          (sel) =>
            sel.id === playoffEventIds.s1 && sel.pickedTeam === prevPick
        )
      }
      if (pick.id === playoffEventIds.q3) {
        index = userSelections.findIndex(
          (sel) =>
            sel.id === playoffEventIds.s2 && sel.pickedTeam === prevPick
        )
      }
      if (
        pick.id === playoffEventIds.s1 ||
        pick.id === playoffEventIds.s2
      ) {
        index = userSelections.findIndex(
          (sel) =>
            sel.id === playoffEventIds.c && sel.pickedTeam === prevPick
        )
      }
      if (index > -1 && prevPick !== pick.pickedTeam) {
        //Champ game ID
        const finalsIndex = userSelections.findIndex(
          (sel) =>
            sel.id === playoffEventIds.c &&
            sel.pickedTeam === userSelections[index].pickedTeam
        )
        if (finalsIndex > -1) {
          if (userSelections[finalsIndex]) {
            removePick(userSelections[finalsIndex])
          }
          userSelections.splice(finalsIndex, 1)
        }
        if (userSelections[index]) {
          removePick(userSelections[index])
        }
        userSelections.splice(index, 1)
      }
      if (prevPick !== pick.pickedTeam) {
        setIsEditing(true)
      }
    }
    setSelections(userSelections)

    //Update local picks cache
    setLocalBracketSelections(userSelections)
  }

  const removePick = async (pickToRemove: Pick) => {
    // Check if first quarterfinal game is set
    const eventPickClosed = checkSinglePickEnabled(
      FIRST_QUARTERFINAL,
      events
    )
    if (eventPickClosed) return
    if (!uid) return

    let pk: PickMutationParams = {
      id: pickToRemove.id,
      week: pickToRemove.week || 0,
      teamId: null,
      year: SEASON,
      userId: uid,
    }
    let successPick = await submitPick(pk)
    if (!successPick) {
      alert('Error removing pick')
      //Reset Pick to previous on error
      return
    }
    return
  }

  const handleCashTagSubmit = async (cashTag: string) => {
    if (!isLoggedIn || !uid) return login()
    if (cashTag.includes('$')) {
      //remove $ sign
      cashTag = cashTag.replace('$', '')
    }
    const data = {
      userId: uid,
      cashTag: cashTag,
    }
    let user = await updateUserProfile(data)
    if (user) {
      setCashTagLocally(cashTag)
      setCashTag(cashTag)
    }
    return user
  }

  return (
    <PicksContext.Provider
      value={{
        user,
        activeWeek,
        events,
        teamList,
        selections,
        pickStatus,
        cashTag,
        isEditing,
        showModal,
        showTutorial,
        showSnackbar,
        showChampion,
        toggleModal,
        toggleTutorial,
        toggleSnackbar,
        handleBracketTeamSelect,
        handleCashTagSubmit,
        shouldShowCashAppPopover,
      }}
    >
      {children}
      {showTagModal && (
        <CashTagPopup toggleCashTagModal={toggleCashTagModal} />
      )}
    </PicksContext.Provider>
  )
}
