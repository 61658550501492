import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import {
  CompeteSummary,
  Competition,
  fetchUserRecords,
} from '../../Api'
import { GamificationContext } from '../GamificationContext'
import { SEASON } from '../../Configs/config'
import { AuthContext } from '../../Api/firebase'
import { SeasonContext } from '../SeasonContext'

type CompetitionContextType = {
  selectedContest: Competition | null
  competeSummary: CompeteSummary | null
  retrievedSummaries: CompeteSummary[]
  handleCompetitionChange: (comp: Competition) => void
  updateSummaryByAnswer: (compType: string) => void
}

export const CompetitionContext =
  createContext<CompetitionContextType | null>(null)

export const CompetitionProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  let { uid } = useContext(AuthContext)!
  let { activeWeek } = useContext(SeasonContext)!
  let { gamificationConfig, pllnWeek } = useContext(
    GamificationContext
  )!
  const [selectedContest, setSelectedContest] =
    useState<Competition | null>(null)
  const [competeSummary, setCompeteSummary] =
    useState<CompeteSummary | null>(null)
  const [retrievedSummaries, setRetrievedSummaries] = useState<
    CompeteSummary[]
  >([])

  useEffect(() => {
    getInitialCompetition()
  }, [gamificationConfig?.competitions])

  useEffect(() => {
    if (!uid) return
    if (!selectedContest) return getInitialCompetition()
    updateCompetitionByWeek(selectedContest)
  }, [uid, activeWeek])

  const handleCompetitionChange = (comp: Competition) => {
    setSelectedContest(comp)
    updateMainSummary(comp)
  }

  const updateCompetitionByWeek = (comp: Competition) => {
    updateMainSummary(comp)
  }

  const updateMainSummary = async (comp: Competition) => {
    let summaries = await getSummaries()
    if (summaries.length < 1) return
    let foundSummary = summaries.find((sum) => sum.type === comp?.type)
    setCompeteSummary(foundSummary || null)
  }

  const getSummaries = async () => {
    if (
      !gamificationConfig?.competitions ||
      gamificationConfig.competitions.length < 1
    )
      return []
    let summaryList = [...retrievedSummaries]
    const contestTypes = gamificationConfig.competitions.map(
      (comp) => comp.type
    )
    if (summaryList.length < 1) {
      let newSummaries = await retrieveCompeteSummaries(contestTypes)
      summaryList = newSummaries
    }
    return summaryList
  }

  const retrieveCompeteSummaries = async (contestTypes: string[]) => {
    if (!uid) {
      return []
    }
    let records = await fetchUserRecords(
      uid,
      SEASON,
      activeWeek,
      pllnWeek,
      contestTypes.join(',')
    )
    return records
  }

  const getInitialCompetition = () => {
    if (
      gamificationConfig?.competitions &&
      gamificationConfig.competitions.length > 0
    ) {
      const urlParams = new URLSearchParams(window.location.search)
      const competitionParam = urlParams.get('competition')
      if (competitionParam) {
        const comp = gamificationConfig.competitions.find(
          (c) => c.type === competitionParam
        )
        if (comp) {
          return handleCompetitionChange(comp)
        }
      }
      return handleCompetitionChange(gamificationConfig.competitions[0])
    }
    return
  }

  /**
   * update summary when user successfully answers question by replacing
   * the refetched type
   */
  const updateSummaryByAnswer = async () => {
    if (!uid || !selectedContest) return
    let copySums = [...retrievedSummaries]
    let filteredSums = copySums.filter(
      (sum) => sum.type !== selectedContest.type
    )
    let record = await fetchUserRecords(
      uid,
      SEASON,
      activeWeek,
      pllnWeek,
      selectedContest.type
    )

    if (record) {
      filteredSums.push(record[0])
      setRetrievedSummaries(filteredSums)
      //Assume it's current active competition
      setCompeteSummary(record[0])
    }
  }

  return (
    <CompetitionContext.Provider
      value={{
        selectedContest,
        competeSummary,
        retrievedSummaries,
        handleCompetitionChange,
        updateSummaryByAnswer,
      }}
    >
      {children}
    </CompetitionContext.Provider>
  )
}
