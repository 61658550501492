import styled from '@emotion/styled'
import { colors, fontSizes } from '../consts'

export const WriteupCon = styled('div')(({ theme }) => ({
  '& p': {
    color: `${colors.lightestGrey} !important`,
    '& span': {
      color: `${colors.lightestGrey} !important`,
      fontSize: fontSizes.medium,
    },
  },
}))
