import React, { createContext, useState, useEffect } from 'react'
import { useQuery } from '../../Routing'

type NavbarContextType = {
  shouldShowNav: boolean
}

export const NavbarContext = createContext<NavbarContextType | null>(null)

export const NavbarProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  let query = useQuery()
  const navQuery = query.get('showNavBar')
  let [shouldShowNav, setShouldShowNav] = useState<boolean>(true)

  useEffect(() => {
    const showNavQuery = navQuery === 'false' ? false : true
    setShouldShowNav(showNavQuery)
  }, [])

  return (
    <NavbarContext.Provider
      value={{
       shouldShowNav
      }}
    >
      {children}
    </NavbarContext.Provider>
  )
}
