import { styled } from '@mui/system'
import { colors } from '../consts'

export type SlideUpProps = {
  height: string
  visible: boolean
  width: string
  noBackground?: boolean
}

export const SlideUpContainer = styled('div')<SlideUpProps>(
  ({ height, visible, noBackground, width, theme }) => ({
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: width ?? '100%',
    borderRadius: '20px 20px 0 0',
    bottom: visible ? 0 : '-100%',
    height: height,
    background: noBackground ? 'none' : colors.gray.gray80,
    zIndex: 999,
    transition: `bottom .4s ease-in`,
    [theme.breakpoints.down('md')]: {
      // width always set to 100% on mobile
      width: '100%',
    },
    '& .closeX': {
      position: 'absolute',
      top: 30,
      right: 30,
      color: colors.white,
      background: colors.text,
      borderRadius: '50%',
      border: `solid 1px ${colors.white}`,
      padding: '6px 10px',
      fontSize: 18,
      fontWeight: 800,
      cursor: 'pointer',
      zIndex: 99,
      [theme.breakpoints.between('md', 'lg')]: {
        right: 200,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
        right: 10,
        padding: '5px 8px',
      },
    },
  })
)
