import React from 'react'
import { colors } from '../../consts'
import { Lk } from '../../../Routing/utils'
import { FANTASY_GROUPS_URL } from '../../../Routing/urls'
import { Desc } from '../../Typography/Text.styles'
import { GoToGroupCon } from './styles'

type GroupListHeaderParams = {
  groupId: number
}

export const GoToGroup: React.FC<
  React.PropsWithChildren<GroupListHeaderParams>
> = ({ groupId }) => {
  return (
    <GoToGroupCon>
      <Desc color={colors.white} className='title'>
        Start Inviting Friends
      </Desc>
      <Lk
        classNameObj="goToLink"
        link={`${FANTASY_GROUPS_URL}/${groupId}`}
      >
        Invite Friends
      </Lk>
    </GoToGroupCon>
  )
}
