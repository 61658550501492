import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { SuspensePage } from '../../Pages'

const SeasonProvider = lazy(() =>
  import('../../Context').then((module) => ({ default: module.SeasonProvider }))
)

export const LeagueContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
    <SeasonProvider>
      <Outlet />
    </SeasonProvider>
    </Suspense>
  )
}