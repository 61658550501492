import { PlayerStarType } from '../../Api/types/productConfigs.types'
import { UserPlayerStars } from '../../Api/types/users.types'
import { PLL_IMAGE_URL } from '../../Configs/config'

// Button states for button text
export const buttonStates = {
  loading: 'Loading...',
  locked: 'Locked',
  reveal: 'Reveal',
  unlock: 'Unlock',
  revealing: 'Revealing',
  unlocking: 'Unlocking',
  view: 'View Your Stars',
}

export const getRevealAnimation = (starId: string) => {
  return `${PLL_IMAGE_URL}/2023/09/${starId}-noloop.gif`
}

export const findPlayerInUnlocked = (
  playerStarId: string,
  userStars: UserPlayerStars[]
) => {
  return userStars.find((us) => us.playerStarId === playerStarId)
}

export const getStarsArr = (
  starsTypes: PlayerStarType[] | null,
  userStars: UserPlayerStars[],
  unlockedOnly?: boolean
) => {
  let unlockedStars: PlayerStarType[] = []
  let lockedStars: PlayerStarType[] = []
  if (!starsTypes) return []

  if (userStars.length === 0) return starsTypes //No stars, return all locked

  starsTypes.map((star) => {
    const unlockedPlayer = findPlayerInUnlocked(
      star.playerStarId,
      userStars
    )
    if (unlockedPlayer) {
      star.unlocked = true
      star.claimDate = unlockedPlayer?.claimDate
      unlockedStars.push(star)
      return
    }
    //If user doesn't have star, add to locked
    lockedStars.push(star)
  })
  if(unlockedOnly) return unlockedStars

  return [...unlockedStars, ...lockedStars]
}
