import { styled } from '@mui/system'

type SpacerProps = {
  h: number
  hideSpacer?: boolean
}

type DividerProps = {
  h: number
  background?: string
}

export const Spacer = styled('div')<SpacerProps>(
  ({ hideSpacer, h }) => ({
    display: hideSpacer ? 'none' : 'block',
    height: h,
  })
)

export const Divider = styled('div')<DividerProps>(
  ({ h, background, theme }) => ({
    margin: theme.spacing(3, 1),
    height: h || 3,
    width: '80%',
    background: background || 'none',
  })
)