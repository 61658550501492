import { callGraphQL } from '../utils/request'
import {
  getWeeklyPicks,
  getBracketChallengePicks,
} from './picks.queries'
import { insertWeeklyPicks, insertPick } from './picks.mutations'
import type {
  WeeklyPick,
  PicksFetch,
  WeeklyPickMutResult,
  Pick,
  PickMutationResult,
  PickMutationParams,
  UserBracketResult,
  BracketChallenge,
} from '../index'
import { SEASON } from '../../Configs/config'

/**
 * Fetch weekly picks for specific week
 */
export async function fetchWeeklyPicks(
  firebaseId: string,
  week: number
): Promise<WeeklyPick> {
  try {
    const pickRes = await callGraphQL<PicksFetch>(getWeeklyPicks, {
      firebaseId,
      year: SEASON,
      week,
    })
    if (!pickRes?.weeklyPick) throw new Error('Failed to fetch picks.')
    return pickRes.weeklyPick
  } catch (e) {
    throw new Error('Failed to fetch picks.')
  }
}

/**
 * Fetch weekly picks for specific week
 */
export async function fetchUsersBracketPicks(
  firebaseId: string
): Promise<BracketChallenge> {
  try {
    const pickRes = await callGraphQL<UserBracketResult>(
      getBracketChallengePicks,
      {
        userId: firebaseId,
        year: SEASON,
      }
    )
    if (!pickRes?.bracketChallengePicks)
      throw new Error('Failed to fetch picks.')
    return pickRes.bracketChallengePicks
  } catch (e) {
    throw new Error('Failed to fetch picks.')
  }
}

// POST //
/**
 * Create/Update weekly picks for a specific week
 */
export async function sendWeeklyPicks(
  weeklyPickData: WeeklyPick
): Promise<WeeklyPick> {
  try {
    const picksRes = await callGraphQL<WeeklyPickMutResult>(
      insertWeeklyPicks,
      weeklyPickData
    )
    if (!picksRes?.weeklyPicksInsert)
      throw new Error('Failed to add weekly Picks.')
    return picksRes.weeklyPicksInsert
  } catch (err) {
    throw new Error('Failed to add weekly Picks.')
  }
}

/**
 * Create/Update weekly picks for a specific week
 */
export async function submitPick(
  pickData: PickMutationParams
): Promise<Pick> {
  try {
    const picksRes = await callGraphQL<PickMutationResult>(
      insertPick,
      pickData,
      1 //Gamification is true
    )
    if (!picksRes?.pickInsert) throw new Error('Failed to add Pick.')
    return picksRes.pickInsert
  } catch (err) {
    console.log(err)
    throw new Error('Failed to add Pick.')
  }
}
