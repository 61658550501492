import { callGraphQL } from '../utils/request'
import {
  questionsWithUserQuery,
  activeQuestionsQuery,
  submitAnswerMutation,
} from './question.queries'
import type {
  Question,
  AllQuestionsResponse,
  SubmitAnswerResponse,
  SubmitAnswer,
} from './question.types'

/**
 * Fetch all active questions, choose between questions with user or not
 */
export async function fetchActiveQuestions(
  params: { [key: string]: string | number | boolean } = {}
): Promise<Question[]> {
  let query = params?.userId
    ? questionsWithUserQuery
    : activeQuestionsQuery
  try {
    const res = await callGraphQL<AllQuestionsResponse>(query, {
      isActive: true,
      timing: 'pre-game',
      ...params,
    })
    if (!res?.questions) return []
    return res.questions
  } catch (error) {
    return []
  }
}

/**
 * Fetch group by type
 */
export async function fetchQuestionsGroupByType(
  questionType: string,
  userId?: string | null,
  week?: number,
  year?: number
): Promise<Question[]> {
  let query = userId ? questionsWithUserQuery : activeQuestionsQuery
  try {
    const res = await callGraphQL<AllQuestionsResponse>(query, {
      type: questionType,
      userId,
      week,
      year,
    })
    if (!res?.questions) return []
    return res.questions
  } catch (error) {
    return []
  }
}

/**
 * Fetch single event question
 */
export async function fetchGameQuestions(
  eventId: string
): Promise<Question[]> {
  try {
    const res = await callGraphQL<AllQuestionsResponse>(
      questionsWithUserQuery,
      {
        eventId,
      }
    )
    if (!res?.questions) return []
    return res.questions
  } catch (error) {
    return []
  }
}

/**
 * Fetch single question
 */
export async function fetchSingleQuestion(
  questionId: string,
  userId: string
): Promise<Question[]> {
  let query = userId ? questionsWithUserQuery : activeQuestionsQuery
  try {
    const res = await callGraphQL<AllQuestionsResponse>(query, {
      questionId,
      userId,
    })
    if (res?.questions && res.questions.length === 1) {
      return res.questions
    }
    return []
  } catch (error) {
    return []
  }
}

/**
 * Submit user answer
 */
export async function submitAnswer(
  userId: string,
  questionId: number,
  answerCode: string
): Promise<SubmitAnswer | null> {
  try {
    const res = await callGraphQL<SubmitAnswerResponse>(
      submitAnswerMutation,
      {
        userId: userId,
        questionId: questionId,
        answerCode: [answerCode],
      },
      1 //Gamification is true
    )
    if (!res?.submitAnswer) return null
    return res.submitAnswer
  } catch (err: unknown) {
    console.error(err)
    return null
  }
}
