import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/system'

type LKParams = {
  link?: string
  onClick?: (params: any) => void
  classNameObj?: React.HTMLAttributes<HTMLDivElement> | string
}

const DefaultLinkStyle = styled('div')(({ theme }) => ({
  width: '100%',
  '& .default': {
    cursor: 'pointer',
    '& a': {
      textDecoration: 'none',
    },
  },
}))

export const Lk: React.FC<React.PropsWithChildren<LKParams>> = ({
  link,
  classNameObj,
  onClick,
  children,
}) => {
  const navigate = useNavigate()

  return (
    <DefaultLinkStyle>
      <div
        className={`${classNameObj || 'default'}`}
        onClick={() => onClick}
      >
        {link && link.includes('http') && <a href={link}>{children}</a>}
        {link && !link.includes('http') && (
          <div onClick={() => navigate(link)}>{children}</div>
        )}
        {!link && <div>{children}</div>}
      </div>
    </DefaultLinkStyle>
  )
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const extractPageID = (url: string): string | null => {
  const urlObj = new URL(url)
  return urlObj.searchParams.get('pageID')
}

export const extractLink = (url: string): string | null => {
  const urlObj = new URL(url)
  return urlObj.searchParams.get('link')
}
