import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { SuspensePage } from '../../Pages'

const CodeClaimProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.CodeClaimProvider,
  }))
)

export const CodeClaimContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
      <CodeClaimProvider>
        <Outlet />
      </CodeClaimProvider>
    </Suspense>
  )
}
