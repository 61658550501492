import React, { useEffect, useContext } from 'react'
import { AuthContext } from '../Api/firebase'
import { colors, fontSizes } from '../Components/consts'
import { styled } from '@mui/system'
import { PageContainer, Box } from '../Components/Layout/'
import { PLL_IMAGES } from '../Configs/config'
import { Spinner } from '../Components/Icons'
import { useQuery } from '../Routing'
import { NavComponent } from '../Components/Navbar/SiteNavbar/NavComponent'
import { Img } from '../Components/Images/Img'

const LoginStyleCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  '& h3': {
    color: '#242424',
    fontSize: fontSizes.semiLarge,
    fontWeight: 'bold',
  },
  '& p': {
    color: '#242424',
    fontSize: '1.1rem',
    fontWeight: 500,
    margin: theme.spacing(1.5, 'auto'),
  },
  '& img': {
    width: '150px',
    display: 'block',
    margin: theme.spacing(2, 'auto'),
  },
  '& .loading': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .logoutDiv': {
    textAlign: 'right',
    margin: theme.spacing(1, 2),
    '& .logout': {
      cursor: 'pointer',
      width: '100px',
      borderColor: '#c7c7c7',
      border: 'none',
      borderRadius: '25px',
      fontWeight: 700,
      color: '#000',
      padding: theme.spacing(1, 0),
    },
  },
  '& .login': {
    cursor: 'pointer',
    width: '230px',
    background: '#2196f3',
    border: 'none',
    borderRadius: '25px',
    fonSize: '1.5rem',
    fontWeight: 700,
    color: '#fff',
    padding: theme.spacing(1.5, 0),
    margin: theme.spacing(2, 'auto'),
    textDecoration: 'none',
  },
}))

export const Login: React.FC = () => {
  const { uid, isLoggedIn, isAnonymous, isLoading, login, logout } =
    useContext(AuthContext)!
  useEffect(() => {
    // eslint-disable-next-line
  }, [uid, isLoggedIn, isAnonymous, isLoading])

  let query = useQuery()
  const navQuery = query.get('showNavBar')
  const shouldShowNav = navQuery === 'false' ? false : true

  return (
    <>
      <NavComponent showPLLNav={shouldShowNav}>
        <PageContainer>
          <Box>
            <LoginStyleCon>
              {isLoggedIn && !isAnonymous && (
                <>
                  <div className="logoutDiv">
                    <button className="logout" onClick={logout}>
                      logout
                    </button>
                  </div>
                  <Img src={PLL_IMAGES.pllShield} alt="Pll logo" />
                  <p>You're logged in!</p>
                  <a className="login" href="/">
                    Go Home
                  </a>
                </>
              )}
              {(!isLoggedIn || isAnonymous) && (
                <>
                  <Img src={PLL_IMAGES.pllShield} alt="Pll logo" />
                  <h3>Join The PLL</h3>
                  <p>
                    Keep up to date with your favorite team and players
                  </p>
                  <p>Gain access to exclusives</p>
                  <p>Personalize your experience</p>
                  {isLoading && (
                    <div className="loading">
                      <Spinner width={30} color={colors.yellow} />
                    </div>
                  )}
                  {!isLoading && (
                    <button className="login" onClick={() => login()}>
                      login
                    </button>
                  )}
                </>
              )}
            </LoginStyleCon>
          </Box>
        </PageContainer>
      </NavComponent>
    </>
  )
}

export default Login
