export const getWeeklyPicks = `
query($firebaseId: String!, $year: Int!, $week: Int!) {
	weeklyPick(firebaseId: $firebaseId, year: $year, week: $week) {
    week
    score
    pllRank
    tiebreaker
    tiebreakerDiff
    picks {
     	id
      pickedTeam
      correct
    }
    weekEvents{
      id
      fanResults {
        teamId
        count
      }
    }
  }
}
`

export const getBracketChallengePicks = `
query($userId: String, $year: Int!){
  bracketChallengePicks(firebaseId:$userId, year: $year) {
    picks{
      id
      pickedTeam
      correct
    }
  }
}
`