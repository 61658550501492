export const DOMAIN_URI_PREFIX =
  process.env.REACT_APP_DOMAIN_URI_PREFIX || 'https://pllmain.page.link'
export const IOS_BUNDLE_ID =
  process.env.REACT_APP_IOS_BUNDLE_ID || 'com.pll.PLL'

export const DYNAMIC_LINK_CONFIG = {
    baseUrl: DOMAIN_URI_PREFIX,
    ibi: IOS_BUNDLE_ID,
    isi:
      process.env.NODE_ENV === 'development'
        ? '1593807119'
        : '1618489206',
    apn:
      process.env.NODE_ENV === 'development'
        ? 'com.pll.pllandroiddev'
        : 'com.pll.pllandroid',
    mainPageId: 'BracketChallengeViewController',
    shareTitle: 'Download the PLL App'
  }