import React from 'react'
import { PrimaryBtn, SecondaryBtn, OutlinedBtn } from './styles'
import { logEvent } from '../../Utils'

type ActionButtonProps = {
  btnStyle: 'primary' | 'secondary' | 'outlined'
  handleClick?: (...args: any[]) => any | Promise<any>
  disabled?: boolean
  analytics?: { event: string; params?: unknown }
  uiTheme?: 'light' | 'dark' | 'gray'
}

export const Btn: React.FC<React.PropsWithChildren<ActionButtonProps>> = ({
  btnStyle,
  handleClick,
  analytics,
  disabled,
  uiTheme = 'dark',
  children,
}) => {

  const handleButtonClick = () => {
    handleClick && handleClick()
    analytics && logEvent(analytics.event, analytics.params)
  }
  return (
    <>
      {btnStyle === 'primary' && (
        <PrimaryBtn onClick={handleButtonClick} disabled={disabled} buttonType={btnStyle} uiTheme={uiTheme}>
          {children}
        </PrimaryBtn>
      )}
      {btnStyle === 'secondary' && (
        <SecondaryBtn onClick={handleButtonClick} disabled={disabled} buttonType={btnStyle} uiTheme={uiTheme}>
          {children}
        </SecondaryBtn>
      )}
      {btnStyle === 'outlined' && (
        <OutlinedBtn onClick={handleButtonClick} disabled={disabled} buttonType={btnStyle} uiTheme={uiTheme}>
          {children}
        </OutlinedBtn>
      )}
    </>
  )
}
