import { FantasyPrize } from '../../../Api/Fantasy/fantasy.types'
import { PLL_IMAGES } from '../../../Configs/config'
import { colors } from '../../consts'
import { Img } from '../../Images/Img'
import { Desc, Head2 } from '../../Typography/Text.styles'
import { Writeup } from '../../Writeup/WriteUp'
import { PrizeItemCon } from './styles'

type PrizeProps = {
  prize: FantasyPrize | null
  rules?: any
}

export const PrizeItem: React.FC<PrizeProps> = ({
  prize,
  rules,
}) => {
  const { image, imgUrl, desc, title, description } = prize || {}
  const img = image ?? imgUrl ?? PLL_IMAGES.pllShield
  const prizeDesc = description ?? desc

  const itemizeDesc = () => {
    if (!prizeDesc) return
    return prizeDesc.replace(/- \s*/g, `\n- `)
  }

  return (
    <PrizeItemCon>
      {prize && (
        <>
          <Head2 color={colors.white}>Prizes</Head2>
          {img && (
            <div className="imgCon">
              <Img
                src={img}
                alt={title ?? 'Prize logo'}
              />
            </div>
          )}
          <h4 className="prizeTitle">{title}</h4>
          {prizeDesc && (
            <Desc
              color={colors.gray.gray40}
              style={{ whiteSpace: 'pre-wrap' }}
              className="desc"
            >
              {itemizeDesc()}
            </Desc>
          )}
        </>
      )}
      {rules && (
        <>
          <Head2 color={colors.white}>Rules</Head2>
          <Writeup data={rules} />
        </>
      )}
    </PrizeItemCon>
  )
}
