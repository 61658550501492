import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import { FantasyContext } from './FantasyContext'
import {
  CompiledPicksType,
  FantasyUser,
} from '../../Api/Fantasy/fantasy.types'
import { SEASON } from '../../Configs/config'
import { fetchFantasyChallenger } from '../../Api/Fantasy/fantasy.api'
import { DYNAMIC_LINK_CONFIG } from '../../Configs'
import {
  checkStoredLocalChallengeLink,
  createFantasyChallengeDynamicLink,
  defaultCompiledPicks,
  getPickedPlayers,
  setLocalFantasyChallengeLink,
  logEvent,
} from '../../Utils'
import { GeneralSnackbar } from '../../Components/Snackbar/GeneralSnackbar'
import { SeasonContext } from '../SeasonContext'
import { useMatchupURLParams } from '../utils'

type FantasyChallengersContextType = {
  challengers: FantasyUser[]
  matchupUser: FantasyUser | null
  challengeLink: string | null | undefined
  challengerLoading: boolean
  matchupCompiled: CompiledPicksType | null
  handleChallengerClick: (challenger: FantasyUser) => void
}

export type MatchupUsers = {
  user: FantasyUser | null
  challenger: FantasyUser | null
}

export const FantasyChallengersContext =
  createContext<FantasyChallengersContextType | null>(null)

export const FantasyChallengersProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const challengerParam = useMatchupURLParams().challengerId || null
  let { fantasyUser, currentWeek, showSnackbar, snackbarData } =
    useContext(FantasyContext)!
  let { seasonSelect } = useContext(SeasonContext)!
  const [challengers, setChallengers] = useState<FantasyUser[]>([])
  let [matchupUser, setMatchupUser] = useState<FantasyUser | null>(null)
  const [challengeLink, setChallengeLink] = useState<string | null>()
  const [challengerLoading, setChallengerLoading] =
    useState<boolean>(false)
  const [matchupCompiled, setMatchupCompiled] =
    useState<CompiledPicksType | null>(null)

  useEffect(() => {
    if (
      fantasyUser?.challengers &&
      fantasyUser.challengers.length > 0
    ) {
      setChallengers(fantasyUser.challengers)
    }
    if (fantasyUser) {
      generateChallengeLink(fantasyUser)
    }
  }, [fantasyUser])

  useEffect(() => {
    if (!challengerParam) return

    getMatchupUser(challengerParam)
  }, [challengerParam])

  const getMatchupUser = async (challenger: string) => {
    setChallengerLoading(true)
    if (!challengerParam) {
      setChallengerLoading(false)
      return Promise.resolve()
    }

    let usr = await fetchFantasyChallenger(
      challenger,
      currentWeek,
      SEASON,
      seasonSelect.seasonType
    )
    if (!usr?.weekPick?.players || usr.weekPick.players.length < 1) {
      const matchupPs = {...defaultCompiledPicks}
      setMatchupUser(usr)
      setMatchupCompiled(matchupPs)
      setChallengerLoading(false)
      return
    }

    setupChallengerPicks(usr)
    setMatchupUser(usr)
    setChallengerLoading(false)
    return
  }

  const generateChallengeLink = async (fantUser: FantasyUser) => {
    if (fantUser?.challengeCode) {
      //Code exists, just append to base url
      let fullLink = fantUser?.challengeCode.includes('http')
        ? fantUser?.challengeCode
        : `${DYNAMIC_LINK_CONFIG.baseUrl}/${fantUser?.challengeCode}`
      setChallengeLink(fullLink)
      return Promise.resolve()
    }
    let localLink = checkStoredLocalChallengeLink()
    if (!localLink) {
      let generated = await createFantasyChallengeDynamicLink(fantUser)
      generated && setLocalFantasyChallengeLink(generated)
      setChallengeLink(generated)
      return
    }
    return Promise.resolve()
  }

  const setupChallengerPicks = (challenger: FantasyUser) => {
    if (
      !challenger?.weekPick?.players ||
      challenger.weekPick.players.length < 1
    ) {
      setMatchupCompiled({...defaultCompiledPicks})
      return
    }

    let picks: CompiledPicksType = getPickedPlayers(challenger)
    setMatchupCompiled(picks)
    return picks
  }

  const handleChallengerClick = (challenger: FantasyUser) => {
    logEvent('fantasy_challenger_row_click', {
      week: currentWeek,
      challenger_id: challenger.firebaseId,
      challenger_team_name: challenger.teamName,
    })
  }

  return (
    <FantasyChallengersContext.Provider
      value={{
        challengers,
        matchupUser,
        handleChallengerClick,
        challengeLink,
        challengerLoading,
        matchupCompiled,
      }}
    >
      {children}
      {showSnackbar && snackbarData && (
        <GeneralSnackbar
          text={snackbarData.text}
          color={snackbarData.color}
          show={showSnackbar}
        />
      )}
    </FantasyChallengersContext.Provider>
  )
}
