import * as React from 'react'
import { IconProps } from './types'

export const Spinner: React.FC<IconProps & { color?: string }> = ({
  width,
  height,
  color,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 200 200"
  >
    <path
      className="symbolStrokeColor"
      strokeWidth="20"
      strokeLinecap="round"
      fill="none"
      d="m189,100c0,-49 -40,-89 -89,-89c-49,0 -89,40 -89,89"
      stroke={color}
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 100 100"
        to="360 100 100"
        dur="1s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)

export const AddToCal: React.FC<IconProps> = ({
  width,
  height,
  className,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 615 615"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g>
      <g>
        <path
          fill={fill}
          d="M499.641,320.573c-12.207-3.251-25.021-5.011-38.25-5.011c-1.602,0-3.189,0.071-4.781,0.119
			c-78.843,2.506-142.118,66.556-143.375,145.709c-0.015,0.799-0.062,1.587-0.062,2.391c0,15.85,2.515,31.102,7.119,45.422
			C339.474,568.835,395.381,612,461.391,612c81.859,0,148.219-66.359,148.219-148.219
			C609.609,395.151,562.954,337.441,499.641,320.573z M461.391,561.797c-54.133,0-98.016-43.883-98.016-98.016
			s43.883-98.016,98.016-98.016s98.016,43.883,98.016,98.016S515.523,561.797,461.391,561.797z"
        />
        <polygon
          points="475.734,396.844 442.266,396.844 442.266,449.438 389.672,449.438 389.672,482.906 442.266,482.906 
			442.266,535.5 475.734,535.5 475.734,482.906 528.328,482.906 528.328,449.438 475.734,449.438 		"
        />
        <path
          d="M126.703,112.359c9.228,0,16.734-7.507,16.734-16.734V54.984v-38.25C143.438,7.507,135.931,0,126.703,0h-14.344
			c-9.228,0-16.734,7.507-16.734,16.734v38.25v40.641c0,9.228,7.506,16.734,16.734,16.734H126.703z"
        />
        <path
          d="M389.672,112.359c9.228,0,16.734-7.507,16.734-16.734V54.984v-38.25C406.406,7.507,398.899,0,389.672,0h-14.344
			c-9.228,0-16.734,7.507-16.734,16.734v38.25v40.641c0,9.228,7.507,16.734,16.734,16.734H389.672z"
        />
        <path
          d="M274.922,494.859c-2.333-11.6-3.572-23.586-3.572-35.859c0-4.021,0.177-7.999,0.435-11.953H74.109
			c-15.845,0-28.688-12.843-28.688-28.688v-229.5h411.188v88.707c3.165-0.163,6.354-0.253,9.562-0.253
			c11.437,0,22.61,1.109,33.469,3.141V93.234c0-21.124-17.126-38.25-38.25-38.25h-31.078v40.641c0,22.41-18.23,40.641-40.641,40.641
			h-14.344c-22.41,0-40.641-18.231-40.641-40.641V54.984H167.344v40.641c0,22.41-18.231,40.641-40.641,40.641h-14.344
			c-22.41,0-40.641-18.231-40.641-40.641V54.984H40.641c-21.124,0-38.25,17.126-38.25,38.25v363.375
			c0,21.124,17.126,38.25,38.25,38.25H274.922z"
        />
        <circle cx="137.165" cy="260.578" r="37.954" />
        <circle cx="251.016" cy="260.578" r="37.954" />
        <circle cx="364.867" cy="260.578" r="37.954" />
        <circle cx="251.016" cy="375.328" r="37.953" />
        <circle cx="137.165" cy="375.328" r="37.953" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)

export const Invite: React.FC<IconProps> = ({
  width,
  height,
  className,
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 487.958 487.958"
    viewBox="0 0 487.958 487.958"
    className={className}
  >
    <path d="M483.058 215.613l-215.5-177.6c-4-3.3-9.6-4-14.3-1.8-4.7 2.2-7.7 7-7.7 12.2v93.6c-104.6 3.8-176.5 40.7-213.9 109.8-32.2 59.6-31.9 130.2-31.6 176.9v10.8c0 6.1 4.1 11.5 10.1 13.1 1.1.3 2.3.4 3.4.4 4.8 0 9.3-2.5 11.7-6.8 73-128.7 133.1-134.9 220.2-135.2v93.3c0 5.2 3 10 7.8 12.2s10.3 1.5 14.4-1.8l215.4-178.2c3.1-2.6 4.9-6.4 4.9-10.4s-1.8-7.9-4.9-10.5zm-210.5 160v-78.1c0-3.6-1.4-7-4-9.5-2.5-2.5-6-4-9.5-4-54.4 0-96.1 1.5-136.6 20.4-35 16.3-65.3 44-95.2 87.5 1.2-39.7 6.4-87.1 28.1-127.2 34.4-63.6 101-95.1 203.7-96 7.4-.1 13.4-6.1 13.4-13.5v-78.2l180.7 149.1-180.6 149.5z" />
  </svg>
)

export const Handshake: React.FC<IconProps> = ({
  width,
  height,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M11.329 19.6c-.185.252-.47.385-.759.385-.194 0-.389-.06-.558-.183-.419-.309-.509-.896-.202-1.315l1.077-1.456c.308-.417.896-.508 1.315-.199.421.306.511.895.201 1.313l-1.074 1.455zm-.825-2.839c.308-.418.217-1.007-.201-1.316-.421-.308-1.008-.216-1.317.203l-1.073 1.449c-.309.419-.217 1.009.202 1.317.417.307 1.007.218 1.315-.202l1.074-1.451zm-1.9-1.388c.309-.417.217-1.007-.203-1.315-.418-.307-1.007-.216-1.314.202l-1.083 1.461c-.308.419-.209.995.209 1.304.421.308 1 .229 1.308-.19l1.083-1.462zm-1.898-1.386c.308-.419.219-1.007-.203-1.315-.419-.309-1.007-.218-1.315.201l-1.075 1.451c-.308.418-.217 1.008.202 1.315.419.307 1.008.218 1.315-.202l1.076-1.45zm17.294-8.438s-1.555.301-2.667.479c-2.146.344-4.144-.416-6.361-1.562-.445-.229-.957-.466-1.458-.466-.461 0-.913.209-1.292.639-1.366 1.547-2.16 2.915-3.785 3.864-.801.468.14 1.934 1.86 1.331.878-.308 1.736-.895 2.706-1.677.762-.615 1.22-.524 1.879.135 1.238 1.238 5.404 5.351 5.404 5.351 1.317-.812 2.422-1.312 3.713-1.792v-6.302zm-10.524 12.662c-.158.459-.618 1.001-.953 1.455.297.235.608.334.882.334.717 0 1.188-.671.542-1.318l-.471-.471zm6.506-3.463c-1.07-1.055-4.732-4.667-5.803-5.713-.165-.161-.421-.18-.608-.044-.639.464-2.082 1.485-2.944 1.788-1.685.59-3.115-.222-3.422-1.359-.192-.712.093-1.411.727-1.781 1.008-.589 1.657-1.375 2.456-2.363-.695-.539-1.35-.732-1.991-.732-1.706 0-3.317 1.366-5.336 1.231-1.373-.09-3.061-.403-3.061-.403v6.333c1.476.321 2.455.464 3.92 1.199l.462-.624c.364-.496.949-.792 1.564-.792.87 0 1.622.578 1.861 1.388.951 0 1.667.602 1.898 1.387.826-.031 1.641.519 1.897 1.385 1.171 0 2.017.92 1.981 2.007l1.168 1.168c.367.368.963.367 1.331 0 .368-.368.368-.964 0-1.332l-1.686-1.687c-.22-.22.113-.553.333-.333l2.032 2.033c.368.368.963.368 1.331 0s.368-.963 0-1.331l-2.501-2.502c-.221-.218.113-.553.333-.333l2.7 2.701c.368.368.963.368 1.331 0 .358-.356.361-.922.027-1.291z" />
  </svg>
)

export const Bolt: React.FC<IconProps> = ({
  width,
  height,
  fill,
  className,
}) => (
<svg width={width} height={height} viewBox="0 0 8 10" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M7.5835 3.375C7.50585 3.2207 7.35054 3.125 7.18198 3.125H4.216L5.1789 0.642578C5.23514 0.498535 5.21796 0.334375 5.13333 0.205469C5.04871 0.0765625 4.89215 0 4.75768 0H1.42427C1.19643 0 1.004 0.173945 0.973884 0.407031L0.367809 5.09453C0.350046 5.22852 0.389704 5.36328 0.476107 5.46484C0.562511 5.56641 0.687324 5.625 0.818198 5.625H3.58909L2.79779 9.41602C2.75222 9.63516 2.86349 9.85664 3.06295 9.94512C3.12128 9.98828 3.18189 10 3.2425 10C3.38869 10 3.53076 9.92676 3.61769 9.79609L7.55718 3.85859C7.65169 3.71484 7.66305 3.5293 7.5835 3.375Z" fill={fill} />
</svg>
)

