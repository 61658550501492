import { useNavigate } from 'react-router-dom'
import { SingleFantasyGroupCompetition } from '../../../Api'
import {
  currentTime,
  getTimeUntilString,
} from '../../../Utils/DateTime/dateTimeHelpers'
import { colors } from '../../consts'
import { RightArrow } from '../../Icons/RightArrow'
import { Desc, Head3 } from '../../Typography/Text.styles'
import { GroupListCompetitionCalloutCon } from './styles'
import { FANTASY_GROUPS_COMPETITION_URL } from '../../../Routing/urls'
import { useContext } from 'react'
import { FantasyGroupContext } from '../../../Context'

type CompCalloutProps = {
  competition?: SingleFantasyGroupCompetition
}

export const GroupsListCompetitionCallout: React.FC<
  CompCalloutProps
> = ({ competition }) => {
  let nav = useNavigate()
  let {handleShowModal} = useContext(FantasyGroupContext)!
  let competitionIsPast = competition
    ? competition.entryCloseTime < currentTime
    : true
  if (!competition) return <></>

  const goToCompetition = () => {
    nav(
      `${FANTASY_GROUPS_COMPETITION_URL}/${competition.competitionId}`
    )
  }

  return (
    <GroupListCompetitionCalloutCon>
      <div className="ribbon" />
      <div className="top" onClick={goToCompetition}>
        <div className="topLeft">
          <Head3 color={colors.white}>{competition.name}</Head3>
          {!competitionIsPast && (
            <div className="closesCon">
              Locks {getTimeUntilString(competition.entryCloseTime)}
            </div>
          )}
        </div>
        <RightArrow />
      </div>
      <Desc color={colors.gray.gray40} sideMargins={2}>
        {`Compete against all groups for custom prizes. \nEnter your groups now!`}
      </Desc>
      {!competitionIsPast && (
        <div className="enterGroupsButton" onClick={() => handleShowModal('enterGroups')}>
          <Head3 color={colors.black}>Enter Groups</Head3>
        </div>
      )}
    </GroupListCompetitionCalloutCon>
  )
}
