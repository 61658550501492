import { getShortDate } from "../formatting"

  /**
 *
 * Returns ends on string ie 'in 1h' or 'on 12/12'
 */
   export const getTimeUntilString = (timestamp: number, includeDay?: boolean) => {
    let currentTime = new Date().getTime() / 1000
    let diff = timestamp - currentTime
    let secondsInDay = 86400

    if(diff <= 0){
        //End time is in past
        return 'past'
    }
    if(diff > 1209600){
        //Greater than 2 week, don't return anything
        return `on ${getShortDate(timestamp, includeDay)}`
    }
    if(diff > 604800){
        //Greater than 1 week
        return `on ${getShortDate(timestamp, includeDay)}`
    }
    if(diff === 604800){
        //Greater than 1 week, don't return date
        return 'in 1 week'
    }
    if(diff >= 86400){
        //Between 1 day & 7 days
        let inDays = diff/secondsInDay
        let dayRounded = Math.round(inDays)
        return dayRounded === 1 ? 'in 1 day' : `in ${dayRounded} days` 
    }
    if(diff >= 3000){
        //Between 50 mins & 1 day
        let inHrs = diff/(60*60)
        let hrRounded = Math.round(inHrs)
        return hrRounded === 1 ? 'in 1 hr' : `in ${hrRounded} hrs` 
    }
    if(diff > 0){
        //Between ended & 50 mins
        return 'in less than 1 hr'
    }
    return ''
}

export const getTimeSinceString = (timeStamp: string | number) => {
    let time = new Date(timeStamp).getTime()
    let currentTime = new Date().getTime()
    let diff = currentTime > time ? (currentTime - time)/1000 : (time - currentTime)/1000

    // less than a minute
    if(diff <= 59){
        return '1 m'
    }
    // Minutes passed that are less than 5
    if (diff < 300){
        let minutes = Math.round(diff/60)
        return `${minutes} m`
    }
    // Minutes passed in 5 minute intervals up to an hour
    if (diff < 3600){
        let minutes = Math.floor(diff / 300)*5
        return `${minutes} m`
    }
    // Hours passed up to 1 day passed
    if (diff < 86400){
        let hours = Math.round(diff/3600)
        return `${hours} hr`
    }
    // days passed
    let days = Math.round(diff/86400)
    return `${days} d`
}

export const currentTime = (new Date()).getTime() / 1000

export const getIsoDate = (date: Date) => {
    return date.toISOString().split('T')[0]
}