import React from 'react'
import { styled } from '@mui/system'
import { colors } from '../consts'

type PageContainerProps = {
  color?: string
  sidePadding?: number
  sponsor?: boolean
  fullScreen?: boolean
}

export const MainPageStyle = styled('div')<PageContainerProps>(
  ({ color = colors.pageBackground, sidePadding, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: `calc(100vh - 120px)`,
    right: 0,
    left: 0,
    bottom: 70,
    width: '100vw',
    background: color
  })
)

export const PageContainer: React.FC<
  React.PropsWithChildren<PageContainerProps>
> = ({ color = colors.pageBackground, sidePadding, children }) => {
  return (
    <MainPageStyle color={color} sidePadding={sidePadding}>
      {children}
    </MainPageStyle>
  )
}
