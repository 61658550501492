import { Achievement, BadgeType } from '../../Api'
import { UserPlayerStars } from '../../Api/types/users.types'
import { extractLink, extractPageID } from '../../Routing'
import { getIsoDate } from '../DateTime/dateTimeHelpers'
import { isEmbeddedWebView } from '../WindowHelpers/windowHelpers'
import { addCommas } from '../formatting'

export const badgeCategories = ['referral', 'bonus']

export const COLLECT_STARS_LINK =
  'https://premierlacrosseleague.com/playoff-stars/how-to-collect'

export const filterAchievements = (
  achievements: Achievement[],
  achType: string
) => {
  return achievements.filter((ach) => ach.achStructure === achType)
}

/**
 *
 * Return cleaned Achievement or Benefit link for embedded webview
 */
export const generatePLLNLink = (lnk?: string) => {
  if (!lnk) return ''
  let embedded = isEmbeddedWebView()

  // If embedded, we need to remove the page.link and just use the link= param.
  //If no page.link, just return the original link
  let pageId = extractPageID(lnk)
  let linkParam = extractLink(lnk)
  if (pageId?.includes('Fantasy') && embedded) {
    //Don't show Fantasy links in embedded webview
    return ''
  }
  return linkParam || lnk
}
/**
 *
 * Gets badge data for specific badge
 */
export const findBadge = (badgeTypes: BadgeType[], badge: string) => {
  return badgeTypes.find((b) => b.code === badge)
}

export const getHasClaimed = (lastClaim: number | undefined) => {
  if (!lastClaim) return false

  let today = getIsoDate(new Date())
  let lastClaimDate = getIsoDate(new Date(lastClaim * 1000))
  let currentUTC = Date.now()

  if (today === lastClaimDate) {
    console.log('Claimed Today')
    return true
  } else if (currentUTC > lastClaim) {
    return false
  }

  return false
}

export const findPlayerInUnlocked = (
  playerStarId?: string,
  userStars?: UserPlayerStars[]
) => {
  if (!userStars || !playerStarId) return false

  return userStars.find((us) => us.playerStarId === playerStarId)
}

export const splitPublicStatValue = (
  value: string | number,
  statType: string
) => {
  if (!value) return []
  if (typeof value === 'number') {
    let num = addCommas(value)
    return [num]
  }
  if (!value.includes('-')) return [value]
  let splitRecord = value.split('-')
  if (statType === 'sum' && splitRecord.length > 1) {
    // total up denomination, assume number
    const denom =
      (parseInt(splitRecord[0]) || 0) + (parseInt(splitRecord[1]) || 0)
    return [splitRecord[0], denom]
  }

  return value.split('-')
}
