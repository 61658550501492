import React from 'react'
import { styled } from '@mui/system'
import { colors, zIndex } from '../../consts'

type OverlayProps = {
  type: 'dialog' | 'video' | 'popup' | 'slideup'
  orientation?: 'center' | 'bottom'
  onClick?: () => void
}

export const ModalOverlay = styled('div')<OverlayProps>(
  ({ type, orientation, theme }) => ({
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    height: type === 'slideup' ? '40vh' : '100%',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: orientation === 'bottom' ? 'flex-end' : 'center',
    alignItems: 'center',

    // layer and background color depends on type
    ...(type === 'slideup' && {
      zIndex: zIndex.loginOverlay,
      backgroundColor: 'none',
    }),
    ...(type === 'dialog' && {
      zIndex: zIndex.loginOverlay,
      backgroundColor: 'rgba(255,255,255,0.8)',
    }),
    ...(type === 'video' && {
      zIndex: zIndex.videoOverlay,
      backgroundColor: 'rgba(0,0,0,0.7)',
      width: '100vw',
      height: '100vh',
    }),
    ...(type === 'popup' && {
      zIndex: zIndex.loginOverlay,
      backgroundColor: 'rgba(0,0,0,0.4)',
    }),
    '& .closeX': {
      position: 'fixed',
      top: orientation === 'bottom' ? '48%' : 80,
      right: 25,
      color: colors.white,
      background: colors.text,
      borderRadius: '50%',
      padding: '10px 12px',
      fontSize: 18,
      fontWeight: 800,
      cursor: 'pointer',
      zIndex: 99,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        top: orientation === 'bottom' ? '55%' : 70,
        right: 10,
        padding: '8px 10px',
      },
    },
  })
)

export const Overlay: React.FC<
  React.PropsWithChildren<OverlayProps>
> = ({ type, orientation = 'center', children, onClick }) => {
  return (
    <ModalOverlay type={type} orientation={orientation}>
      <div className="closeX" onClick={onClick}>
        X
      </div>
      {children}
    </ModalOverlay>
  )
}
