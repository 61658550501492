import React from 'react'
import { MobileBox, PageContainer } from '../Components/Layout'
import { NavComponent } from '../Components/Navbar/SiteNavbar/NavComponent'

export const BasePage: React.FC = () => {

  return (
    <>
      <NavComponent>
      <PageContainer>
        <MobileBox
          vertMargins={0}
          sideMargins={0}
          color="dark"
          padding={0}
          fullHeight
        >
        </MobileBox>
      </PageContainer>
      </NavComponent>
    </>
  )
}