export const getAllEvents = `
query($season: Int!, $includeCS: Boolean!) {
    seasonEvents(season: $season, includeCS: $includeCS) {
      id
      gameNumber
      slugname
      eventId: externalId
      startTime
      week
      venue
      location
      description
      gameNumber
      broadcaster
      seasonSegment
      eventStatus
      period
      visitorScore
      visitorPeriodScores
      homeScore
      homePeriodScores
      urlTicket
      urlPreview
      urlStreaming
      addToCalendarId
      awayTeam {
        officialId
        fullName
        teamWins
        teamLosses
        teamTies
        seed
        champSeries (year: $season) @include(if: $includeCS) {
          teamWins
          teamLosses
          teamTies
        }
      }
      homeTeam {
        officialId
        fullName
        teamWins
        teamLosses
        teamTies
        seed
        champSeries (year: $season) @include(if: $includeCS) {
          teamWins
          teamLosses
          teamTies
        }
      }
    }
}
`

export const eventFanVotesByWeekQuery = `
query(
  $year: Int!
  $week: String!
) {
  seasonEvents(season: $year, week: $week) {
    id
    fanResults{
      teamId
      count
    }
  }
}
`

export const getWeekEvents = `
query($season: Int!, $includeCS: Boolean!, $includeFanCount: Boolean) {
    seasonEvents(season: $season, includeCS: $includeCS) {
      id
      gameNumber
      slugname
      eventId: externalId
      startTime
      week
      venue
      location
      description
      gameNumber
      broadcaster
      seasonSegment
      eventStatus
      period
      visitorScore
      visitorPeriodScores
      homeScore
      homePeriodScores
      urlTicket
      urlPreview
      urlStreaming
      addToCalendarId
      fanResults{
        teamId
        count
      }
      awayTeam {
        officialId
        fullName
        teamWins
        teamLosses
        teamTies
        seed
        champSeries (year: $season) @include(if: $includeCS) {
          teamWins
          teamLosses
          teamTies
        }
      }
      homeTeam {
        officialId
        fullName
        teamWins
        teamLosses
        teamTies
        seed
        champSeries (year: $season) @include(if: $includeCS) {
          teamWins
          teamLosses
          teamTies
        }
      }
    }
}
`
