import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AuthContext } from '../../Api/firebase'
import { PLAYER_STAR_ACTIONS } from '../../Configs/PlayerStars/playerStars.config'
import { useQuery } from '../../Routing'
import { PlayerStarType } from '../../Api/types/productConfigs.types'
import { CodeClaimContext } from '../CodeClaimContext/CodeClaimContext'
import { SnackbarData } from '../../Api/types/picks.types'
import { GeneralSnackbar } from '../../Components/Snackbar/GeneralSnackbar'
import { PlayerStarsContext } from './PlayerStarsContext'
import { logEvent } from '../../Utils'

type PlayerStarRevealContextType = {
  star: PlayerStarType | null
  isLoading: boolean
  revealType: string
  code: string | null
  hasPlayer: boolean
  showReveal: boolean
  hasRevealed: boolean
  showSnackbar: boolean
  snackbar: SnackbarData
  handleRevealClick: () => void
  handleClaimClick: () => Promise<
    { success: boolean; status: number | null; error: any } | undefined
  >
}

export const PlayerStarRevealContext =
  createContext<PlayerStarRevealContextType | null>(null)

export const PlayerStarRevealProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  let { uid, isLoggedIn, login, pllNation } = useContext(AuthContext)!
  let { playerStarsArray, updatePlayerStarsArray } = useContext(PlayerStarsContext)!
  let { handleCodeSubmit } = useContext(CodeClaimContext)!
  let query = useQuery()
  let starQuery = query && query.get('playerStarId')
  const altStarQuery = query && query.get('playoffStarId') //factor in playoff star issue
  let starId = starQuery || altStarQuery
  const code = query && query.get('code')
  const [revealType, setRevealType] = useState<string>('')
  const [star, setStar] = useState<PlayerStarType | null>(null)
  const [hasPlayer, setHasPlayer] = useState<boolean>(false)
  const [showReveal, setShowReveal] = useState<boolean>(false)
  const [hasRevealed, setHasRevealed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<SnackbarData>({
    text: '',
    color: 'green',
  })

  useEffect(() => {
    // Set timer to clear copied so user can click multiple times
    if (showSnackbar) {
      const timer = setTimeout(() => {
        setShowSnackbar(false)
        setSnackbar({
          text: '',
          color: 'green',
        })
      }, 3000)
      return () => clearTimeout(timer)
    }
    return
  }, [showSnackbar])

  useEffect(() => {
    code
      ? setRevealType(PLAYER_STAR_ACTIONS.claim)
      : setRevealType(PLAYER_STAR_ACTIONS.reveal)
  }, [])

  useEffect(() => {
    if (!starId || playerStarsArray.length < 1)
      return setIsLoading(false)

    let foundStar = playerStarsArray.find(
      (star) => star.playerStarId === starId
    )
    if (!foundStar) return setIsLoading(false)
    const unlocked = foundStar.unlocked ? true : false
    setStar(foundStar)
    setHasPlayer(unlocked)
    setIsLoading(false)
  }, [starId, playerStarsArray])

  const handleRevealClick = () => {
    // use player star id stored in context
    if (!uid || !isLoggedIn) {
      return login()
    }
    logEvent('playoff_star_reveal', {
      player_star_id: starId,
      current_player_stars: pllNation?.playerStars
    })
    setShowReveal(true)
    setTimeout(() => {
      setShowReveal(false)
      setHasRevealed(true)
      logEvent('playoff_star_reveal_success', {
        player_star_id: starId,
        current_player_stars: pllNation?.playerStars
      })
    }, 3000)
  }

  const handleClaimClick = async () => {
    // use code stored in context
    if (!uid || !isLoggedIn) {
      return Promise.reject(login())
    }
    if (!code) return Promise.resolve(undefined)
    
    logEvent('playoff_star_claim', {
      player_star_id: starId,
      current_player_stars: pllNation?.playerStars,
      code: code
    })

    let res = await handleCodeSubmit(code)

    if (res?.success) {
      setSnackbar({
        text: 'Success! Player has been claimed.',
        color: 'green',
      })
      setShowReveal(true)
      setShowSnackbar(true)
      updatePlayerStarsArray(starId)

      logEvent('playoff_star_claim_success', {
        player_star_id: starId,
        current_player_stars: pllNation?.playerStars,
        code: code
      })
        
      return res
    }

    logEvent('playoff_star_claim_fail', {
      player_star_id: starId,
      current_player_stars: pllNation?.playerStars,
      code: code,
      error_message: res?.error
    })

    setSnackbar({
      text: res?.error,
      color: 'red',
    })
    setShowSnackbar(true)
    return res
  }

  return (
    <PlayerStarRevealContext.Provider
      value={{
        star,
        isLoading,
        revealType,
        code,
        hasPlayer,
        hasRevealed,
        showSnackbar,
        snackbar,
        showReveal,
        handleRevealClick,
        handleClaimClick,
      }}
    >
      {children}
      {showSnackbar && (
        <GeneralSnackbar
          text={snackbar.text || ''}
          color={snackbar.color || 'red'}
          show={showSnackbar}
        />
      )}
    </PlayerStarRevealContext.Provider>
  )
}
