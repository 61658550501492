import { BenefitsObjType, FanProfileRow } from '../Api'
import {
  ACHIEVEMENTS_URL,
  BADGES_URL,
  BENEFITS_URL
} from '../Routing/urls'

export type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type FeatureListType = {
  featureId: string
  level: number
}

export const PROFILE_ROWS: FanProfileRow[] = [
  {
    title: 'Achievements',
    id: 1,
    rowType: 'link',
    link: ACHIEVEMENTS_URL,
    showOnPublic: false
  },
  {
    title: 'PLL Nation Benefits',
    id: 2,
    rowType: 'link',
    link: BENEFITS_URL,
    showOnPublic: false
  },
  {
    title: 'Badges',
    id: 3,
    rowType: 'link',
    link: BADGES_URL,
    showOnPublic: true
  },
  // {
  //   title: 'Favorite Players',
  //   id: 4,
  //   rowType: 'accordian',
  //   showOnPublic: false
  // },
]

export const FEATURE_LEVEL_NAMES = {
  featureLevels: [
    { featureId: 'editUsername', level: 3 },
    { featureId: 'fanLeaderboard', level: 5 },
    { featureId: 'nameTeamColor', level: 6 },
    { featureId: 'nameTeamBadge', level: 7 },
    { featureId: 'appIconChange', level: 8 },
  ],
}

export const BENEFIT_TYPES: BenefitsObjType[] = [
  {
    id: 0,
    title: 'Benefits',
    benType: 'unlockedBenefits',
  },
  {
    id: 1,
    title: 'Locked',
    benType: 'lockedBenefits',
  },
]

export const getFeatureFromConfig = (
  title: string,
  featureList?: FeatureListType[]
) => {
  let list = featureList || FEATURE_LEVEL_NAMES.featureLevels

  return list.find((feat) => feat.featureId === title)
}

export const getIsAboveLevel = (
  userLevel: number,
  feature?: FeatureListType
) => {
  if (!feature || !userLevel) return false
  return userLevel >= feature.level
}
