import React, { createContext, useState } from 'react'
import { GeneralSnackbar } from '../../Components/Snackbar/GeneralSnackbar'
import { SnackbarData } from '../../Api/types/picks.types'

type SnackbarContextType = {
  toggleShowSnackbar: (snackbarData: SnackbarData) => void
}

export const SnackbarContext =
  createContext<SnackbarContextType | null>(null)

export const SnackbarProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarData, setSnackbarData] = useState<SnackbarData | null>(
    null
  )

  const toggleShowSnackbar = async (snackbarData: SnackbarData) => {
    setSnackbarData(snackbarData)
    setShowSnackbar(true)
  }

  return (
    <SnackbarContext.Provider
      value={{
        toggleShowSnackbar,
      }}
    >
      {children}
      {showSnackbar && snackbarData && (
        <GeneralSnackbar
          text={snackbarData.text}
          color={snackbarData.color}
          show={showSnackbar}
        />
      )}
    </SnackbarContext.Provider>
  )
}
