import { colors, fontSizes } from '../../Components/consts'
import { styled } from '@mui/system'

export const ErrorCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: colors.white,
  width: '100%',
}))

export const SadChazImg = styled('img')(({ theme }) => ({
  width: 220,
  height: 300,
  borderRadius: 30,
  [theme.breakpoints.down('sm')]: {
    width: 146,
    height: 200,
    borderRadius: 15,
  },
}))

export const LinkCon = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0.5),
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  '& a': {
    color: colors.text,
    fontSize: fontSizes.medium,
    fontWeight: 600,
    margin: theme.spacing(1, 0.5),
  },
}))
