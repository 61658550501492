import React, { useState, useContext } from 'react'
import {
  CancelRow,
  ErrorStyle,
  InputStyle,
  MoreDataCon,
} from './styles'
import { AuthContext } from '../../Api/firebase'
import { validateEmail } from './authHelpers'
import { updateUserProfile } from '../../Api'
import { Head } from '../Typography/Text.styles'
import { colors } from '../consts'
import { UpdateUserParams } from '../../Api/types/users.types'

type SignInConProps = {
  onClose?: () => void
  extraFields: string[]
  allowSkip: boolean
  onFinishedSubmit?: () => void
  uiTheme?: 'light' | 'dark'
}

export const SignInMoreData: React.FC<
  React.PropsWithChildren<SignInConProps>
> = ({
  onClose,
  extraFields,
  allowSkip = true,
  onFinishedSubmit,
  uiTheme = 'light',
}) => {
  const auth = useContext(AuthContext)!
  const [moreData, setMoreData] = useState<{
    [key: string]: string
  } | null>(null)
  const [errorMsg, setErrorMsg] = useState<{ [key: string]: string }>(
    {}
  )
  const [updating, setUpdating] = useState<boolean>(false)
  const [buttonText, setButtonText] = useState<string>('Submit')

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let val = e.target.value
    let name = e.target.name
    if (!val) {
      setMoreData({ ...moreData, [name]: '' })
      return
    }
    if (name === 'alternateEmail') {
      val = val.toLowerCase()
    }
    setMoreData({ ...moreData, [name]: val })
  }

  const handleSubmit = async () => {
    if (!auth.uid || !moreData) return
    let userUpdateData: UpdateUserParams = {
      firstName: auth.firstName ? auth.firstName : '',
      lastName: auth.lastName ? auth.lastName : '',
      email: auth.email ? auth.email : '',
      birthday: auth.birthday ? auth.birthday : '',
    }
    if (moreData?.alternateEmail) {
      const validated = validateEmail(moreData.alternateEmail)
      if (!validated.errorMsg) {
        setErrorMsg({ ...errorMsg, alternateEmail: '' })
      } else {
        setErrorMsg({ ...errorMsg, alternateEmail: validated.errorMsg })
        return
      }
    }
    userUpdateData = { ...userUpdateData, ...moreData }
    setUpdating(true)
    setButtonText('Submitting...')
    try {
      await updateUserProfile(userUpdateData)
      setUpdating(false)
      setButtonText('Success!')
      auth.updateProfileInState(userUpdateData)
      onFinishedSubmit && onFinishedSubmit()
    } catch (e) {
      console.error('There was an error logging in: ' + e)
      setUpdating(false)
      setButtonText('Submit')
      alert('Something went wrong updating info.')
    }
  }

  const handleSkipClick = () => {
    return onClose && onClose
  }

  return (
    <MoreDataCon uiTheme={uiTheme}>
      <Head
        color={uiTheme === 'light' ? colors.text : colors.white}
        vertMargins={4}
      >
        Set Up Your Profile
      </Head>
      {extraFields.includes('email') &&
        !auth.email &&
        !auth.alternateEmail && (
          <div className="inputCon">
            <InputStyle
              required
              uiTheme={uiTheme}
              type="string"
              variant="outlined"
              margin="normal"
              id="temailOutlined"
              name="alternateEmail"
              placeholder="Enter your email"
              value={moreData?.alternateEmail}
              onChange={(e) => {
                handleInputChange(e)
              }}
            />
            {errorMsg.email && (
              <ErrorStyle>{errorMsg.email}</ErrorStyle>
            )}
          </div>
        )}
      {extraFields.includes('name') && (
        <div className="multiInputCon">
          <InputStyle
            required
            uiTheme={uiTheme}
            type="text"
            variant="outlined"
            margin="normal"
            id="tfirstOutlined"
            name="firstName"
            placeholder="First Name"
            value={moreData?.firstName || auth?.firstName}
            onChange={(e) => {
              handleInputChange(e)
            }}
          />
          <InputStyle
            required
            uiTheme={uiTheme}
            type="text"
            variant="outlined"
            margin="normal"
            id="tlastOutlined"
            name="lastName"
            placeholder="Last Name"
            value={moreData?.lastName || auth?.lastName}
            onChange={(e) => {
              handleInputChange(e)
            }}
          />
          {errorMsg.name && <ErrorStyle>{errorMsg.name}</ErrorStyle>}
        </div>
      )}
      {extraFields.includes('zip') && !auth.zip && (
        <div className="inputCon">
          <InputStyle
            required
            uiTheme={uiTheme}
            type="string"
            variant="outlined"
            margin="normal"
            id="tzipOutlined"
            name="zip"
            placeholder="Zipcode"
            value={moreData?.zipcode}
            onChange={(e) => {
              handleInputChange(e)
            }}
          />
          {errorMsg.zip && <ErrorStyle>{errorMsg.zip}</ErrorStyle>}
        </div>
      )}
      <button className="submitBtn" onClick={handleSubmit}>
        {buttonText}
      </button>
      {allowSkip && (
        <CancelRow uiTheme={uiTheme}>
          <button
            className="cancelBtn"
            disabled={!errorMsg && !updating}
            onClick={() => handleSkipClick()}
          >
            later
          </button>
        </CancelRow>
      )}
    </MoreDataCon>
  )
}
