import { styled } from '@mui/system'
import { colors, fontSizes } from '../../consts'

export const PrizeItemCon = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  '& .headerCon': {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
    width: '100%',
    '& h3': {
      fontSize: fontSizes.semiLarge,
      fontWeight: 800,
      color: colors.white,
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizes.medium,
      },
    },
    '& .prizeIc': {
      width: 30,
      [theme.breakpoints.down('md')]: {
        width: 24,
      },
    },
  },
  '& .prizeTitle': {
    fontSize: fontSizes.medium,
    fontWeight: 500,
    color: colors.white,
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      fontSize: fontSizes.small,
    },
  },
  '& .imgCon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 2),
    background: colors.black,
    borderRadius: 12,
    width: '90%',
    maxWidth: 300,
    '& img': {
      borderRadius: 12,
      height: 'auto',
      width: '100%'
     
    },
  },
}))

export const PrizeCircleCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  background: colors.black,
  borderRadius: '50%',
  width: 35,
  height: 35,
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: 25,
    height: 25,
  },
  '& img': {
    width: 30,
    height: 30,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
}))
