export const insertWeeklyPicks = `
mutation($userId: Int!, $week: Int, $tiebreaker: Int, $picks: [PickDataInput]) {
    weeklyPicksInsert(userId: $userId, week: $week, tiebreaker: $tiebreaker, picks: $picks) {
      weeklyPickId
      userId
      week
      score
      tiebreaker
    }
  }
`

export const insertPick = `
mutation($userId: ID!, $year: Int!, $week: Int!, $id: Int!, $teamId: String){
  pickInsert(userId: $userId, year: $year, week: $week, eventId: $id, teamId: $teamId) {
    weeklyPickId
    id
    userId
    pickedTeam
    correct
    fanResults {
        teamId
        count
      }
  }
}
`