import { styled } from '@mui/system'

export const NavActionCon = styled('a')(({ theme }) => ({
  display: 'inline-block',
  background: '#ffcb06',
  borderRadius: 10,
  marginRight: 5,
  padding: '5px 15px',
  textDecoration: 'none',
  '& p': {
    color: '#000',
    fontSize: '1rem',
    fontWeight: 700,
    margin: 'auto 5px',
  },
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    top: 15,
    right: 60,
    marginRight: 5,
    padding: '3px 8px',
  },
}))

export const NavbarCon = styled('section')(({ theme }) => ({
  height: 70,
  background: '#000',
  margin: 0,
  width: '100vw',
  [theme.breakpoints.down('sm')]: {
    height: 50,
  },
  '& .brand': {
    display: 'block',
    position: 'absolute',
    marginTop: 0,
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: 25,
      float: 'left',
    },
    '& a img': {
      maxHeight: 70,
      [theme.breakpoints.down('sm')]: {
        maxHeight: 50,
      },
    },
  },
  '& .navMain': {
    float: 'left',
    marginLeft: 200,
    '& .navMobile': {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        background: '#000',
        height: 50,
        width: '100%',
      },
      '& .toggleContainer': {
        position: 'absolute',
        display: 'none',
        top: 0,
        left: 0,
        width: 41,
        height: 50,
        background: '#ffcb06',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      },
    },
    '& .navList': {
      display: 'block',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      float: 'left',
      position: 'relative',
      '& li': {
        float: 'left',
        position: 'relative',
        '& a': {
          display: 'block',
          padding: '0 10px',
          lineHeight: '70px',
          background: 'transparent',
          color: '#ffffff',
          fontWeight: 500,
          fontSize: '1rem',
          textDecoration: 'none',
          '&:hover': {
            background: '#000',
            color: '#ffcb06',
          },
          [theme.breakpoints.down('lg')]: {
            fontSize: '0.8rem',
          },
        },
        '& button': {
          display: 'block',
          padding: '0 10px',
          lineHeight: '70px',
          background: 'transparent',
          color: '#ffffff',
          fontWeight: 600,
          fontSize: '1rem',
          textDecoration: 'none',
          border: 'none',
          '&:hover': {
            background: '#000',
            color: '#ffcb06',
          },
          '&:focus': {
            outline: 'none',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  '& .rightCol': {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    height: 70,
    [theme.breakpoints.down('sm')]: {
      height: 50,
    },
      '& .navProfile': {
        margin: 'auto 5px',
        right: 10,
        width: 40,
        zIndex: 2,
        [theme.breakpoints.down('md')]: {
          marginRight: 0,
        },
        '& svg': {
          color: '#fff'
        }
      },
  },
}))

export const SubDropdown = styled('ul')(({ theme }) => ({
  position: 'absolute',
  background: '#000',
  zIndex: 99,
  boxShadow: '0 3px 12px rgba(0, 0, 0, 015)',
  '& li': {
    minWidth: 190,
    '& a': {
      height: 50,
      padding: 5,
      fontSize: '1rem',
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.8rem',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    position: 'static',
  },
}))

type mobileNavParams = {
  open: boolean
}

export const MobileNavCon = styled('div')<mobileNavParams>(
  ({ open, theme }) => ({
    display: open ? 'block' : 'none',
  })
)

export const MobileNavList = styled('ul')<mobileNavParams>(
  ({ open, theme }) => ({
    display: open ? 'flex' : 'none',
    flexDirection: 'column',
    textAlign: 'left',
    position: 'relative',
    top: 40,
    background: '#000',
    width: '100%',
    height: '100%',
    zIndex: 10,
    padding: '15px 20px',
    '& a': {
      padding: '0 10px',
      lineHeight: '40px',
      background: 'transparent',
      color: '#ffffff',
      fontWeight: 500,
      fontSize: '1rem',
      textDecoration: 'none',
      '&:hover': {
        background: '#000',
        color: '#ffcb06',
      },
    },
    '& button': {
      padding: '0 10px',
      lineHeight: '40px',
      background: 'transparent',
      color: '#ffffff',
      fontWeight: 600,
      fontSize: '1rem',
      textDecoration: 'none',
      border: 'none',
      '&:hover': {
        background: '#000',
        color: '#ffcb06',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  })
)
