import { Achievement } from '../../Api'

export const getDailyProgress = (daily: Achievement, completed?: number[]) => {
  if (!daily.day){
    return {
      active: false,
      completed: false,
      locked: true,
    }
  }
  let isCompleted = completed?.includes(daily.day)
  let hasStarted = daily.day <= new Date().getDay()
  let hasEnded = new Date().getTime() > daily.expireTime * 1000
  return {
    active: hasStarted && !hasEnded,
    completed: isCompleted ?? false,
    locked: !hasStarted,
  }
}

export const getCorrectRatio = (
  group?: Achievement[]
): { correct: number; total: number } => {
  if (!group) return { correct: 0, total: 0 }
  let completedArr: Achievement[] = []
  let endedArr: Achievement[] = []
  group.map((ach) => {
    // let hasEnded = new Date().getTime() > ach.expireTime * 1000
    if (ach.status === 'COMPLETE') {
      completedArr.push(ach)
      endedArr.push(ach)
      return
    }
    endedArr.push(ach)
  })

  return { correct: completedArr.length, total: endedArr.length }
}
