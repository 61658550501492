
export const userEndpoints = {
    GET_USER: 'get-create-user',
    UPDATE_USER: 'user',
    ADD_REFERRAL: 'addReferral',
    PUBLIC_PROFILES: 'users',
    USER_RECORDS: 'user-records'
}

export const API_ENDPOINTS = {
    GET_FEED_BY_ID: 'feed-by-id',
    GET_FEED_BY_TAG: 'feed-by-tag'
}

export const gamificationEndpoints = {
    GET_CONFIG: 'configuration',
    PROFILE: `user-profile`,
    GET_ACHIEVEMENTS: 'achievements',
    ENGAGEMENT: `user-engagement`,
    CLAIM_CODE: `claimCode`,
    DAILY_EXP_CLAIM: `claimExp`,
    LEADERBOARDS: `leaderboards`,
    REWARDS: 'rewards',
    UPDATE_USER: 'users'
}


export const FANTASY_ENDPOINTS = {
    GET_CONFIG: 'configuration',
    UPDATE_FANTASY_USER: 'user',
    ACCEPT_CHALLENGER: 'acceptFantasyChallenger'
}