import React from 'react'
import { PLL_LINKS } from '../../Configs/config'
import { NavComponent } from '../../Components/Navbar/SiteNavbar/NavComponent'
import { ErrorCon, LinkCon, SadChazImg } from './styles'
import { Desc, Head } from '../../Components/Typography/Text.styles'
import { colors } from '../../Components/consts'

type ErrorProps = {
  errorNo?: number
  error?: Error | null | string
}

export const Error: React.FC<ErrorProps> = ({ errorNo }) => {
  return (
    <NavComponent>
      <ErrorCon>
        <Head color={colors.gold} fontSize={40}>
          404
        </Head>
        <SadChazImg
          src="https://premierlacrosseleague.com/wp-content/uploads/2021/03/sad_chazz.jpg"
          alt=""
        />
        <Head fontSize={40}>Whoops</Head>
        <Desc>
          Looks like you found Sad Chazz. He thinks you should try
          these:
        </Desc>
        <LinkCon>
          <a href={PLL_LINKS.schedule}>Schedule</a>
          <a href={PLL_LINKS.stats}>Stats</a>
          <a href={PLL_LINKS.shop}>Shop</a>
        </LinkCon>
      </ErrorCon>
    </NavComponent>
  )
}
