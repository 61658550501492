import { styled } from '@mui/system'
import { colors, fontSizes } from '../../consts'

export const CreateGroupCon = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
  '& .inputCon': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  '& .submitCon': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}))

export const GoToGroupCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  '& .title': {
    colors: colors.white,
    fontSize: fontSizes.semiLarge,
    whitespace: 'nowrap',
    fontWeight: 800,
    [theme.breakpoints.down('md')]: {
      fontSize: fontSizes.medium,
    },
  },
  '& .goToLink': {
    textDecoration: 'none',
    color: colors.black,
    background: colors.white,
    borderRadius: 12,
    padding: theme.spacing(1, 2),
    fontSize: fontSizes.small,
    fontWeight: 800,
    cursor: 'pointer',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizes.xSmall,
      fontWeight: 600,
    },
  },
}))
