import React from 'react'
import { NavListCon, NavListItemCon } from './styles'
import { Lk } from '../../../Routing/utils'
import { logEvent } from '../../../Utils'

export type PageNavList = {
  pageId: number
  pageTitle: string
  link: string | undefined
}

type PageNavProps = {
  navList: PageNavList[]
  selectedTab: number
  setSelectedTab?: (id: number) => void
  navName: string
  height?: number
  onTabPress?: (tabIndex?: number) => void
}

export const PageNav: React.FC<PageNavProps> = ({
  navList,
  selectedTab,
  setSelectedTab,
  height,
  navName,
  onTabPress,
}) => {
  const analyticsName = navName
    ? `${navName}_page_nav_click`
    : 'page_nav_click'

  const handlePageChange = (page: PageNavList) => {
    onTabPress && onTabPress(page.pageId)
    logEvent(analyticsName, {
      page_id: page.pageId,
      page_title: page.pageTitle,
      link: page.link,
    })
    return setSelectedTab && setSelectedTab(page.pageId)
  }

  return (
    <NavListCon
      height={height}
      role="navigation"
      aria-label="Page Navigation"
    >
      {navList.map((page) => {
        const { pageId, pageTitle, link } = page

        const getPageName = () => {
          return pageTitle
            .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Handle cases where lowercase followed by uppercase
            .replace(/&/g, ' & ') // Add space before and after ampersand
            .trim() // Remove any leading/trailing whitespace
            .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
            .toUpperCase() // Convert all letters to uppercase
        }

        return (
          <NavListItemCon
            key={pageId}
            selected={selectedTab}
            pageId={pageId}
            listLength={navList.length}
            onClick={() => handlePageChange(page)}
            role="tab"
            aria-selected={selectedTab === pageId}
          >
            <Lk classNameObj="lnk" link={link}>
              <p>{getPageName()}</p>
            </Lk>
          </NavListItemCon>
        )
      })}
    </NavListCon>
  )
}
