import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { SuspensePage } from '../../Pages'

const FantasyPlayersProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.FantasyPlayersProvider,
  }))
)

export const FantasyPlayersContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
      <FantasyPlayersProvider>
        <Outlet />
      </FantasyPlayersProvider>
    </Suspense>
  )
}
