export const fantasySummaryFields = [
  { title: 'Total', value: 'weekPts' },
]
export const fantasyPositionFilterOrder = [
  'A',
  'M',
  'D',
  'FO',
  'F',
  'G',
]
export const FANTASY_GENERATE_WEEK_TOKENS = 2
export const fantasyDeepLink = 'https://pll.gg/HGe6tPU15Ib'

export const FANTASY_SAVE_BTN_TEXT = {
  save: 'Save Lineup',
  overCap: 'Over Cap',
  saving: 'Saving...',
}

// AI Team Generation //
export const AI_INFO_DETAILS = {
  mainDesc: `Use PLL AI to generate a team for you or as a starting point.`,
  disclaimer: `*PLL AI is experimental and can make mistakes. It cannot guarantee an elite fantasy performance or predict the future. Limit 2 requests per week.`,
}

export const AI_LOADING_TEXTS = {
  primary: 'Generating team...',
  secondary: 'Stringing stick...',
  success: 'Team generated!',
  error: 'Error generating team',
  limitReached:
    'You have reached the limit of 2 team generations per week',
}
