import React, {
  createContext,
  useState,
} from 'react'
import { CompiledPicksType } from '../../Api/Fantasy/fantasy.types'
import {
  defaultCompiledPicks
} from '../../Utils'

type FantasyPlayersContextType = {
  filteredPlayers: CompiledPicksType
}

export const FantasyPlayersContext =
  createContext<FantasyPlayersContextType | null>(null)

export const FantasyPlayersProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [filteredPlayers, setFilteredPlayers] =
    useState<CompiledPicksType>(defaultCompiledPicks)

  return (
    <FantasyPlayersContext.Provider value={{
      filteredPlayers
    }}>
      {children}
    </FantasyPlayersContext.Provider>
  )
}
