import React, { lazy, Suspense } from 'react'
import { MainRoutes } from './Routing'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { NavbarProvider } from './Context'
import {
  checkIfEmbededInNativeApp,
  checkIfEmbededInPLLApp,
} from './Utils'
import { SuspensePage } from './Pages'

const AuthProvider = lazy(() =>
  import('./Api/firebase').then((module) => ({
    default: module.AuthProvider,
  }))
)
const LinkProvider = lazy(() =>
  import('./Context/LinkContext').then((module) => ({
    default: module.LinkProvider,
  }))
)
const StorageProvider = lazy(() =>
  import('./Context').then((module) => ({
    default: module.StorageProvider,
  }))
)
const AdProvider = lazy(() =>
  import('./Context').then((module) => ({ default: module.AdProvider }))
)
const SnackbarProvider = lazy(() =>
  import('./Context/SnackbarContext').then((module) => ({
    default: module.SnackbarProvider,
  }))
)

const App: React.FC = () => {
  console.log('Is embedded in webview:', checkIfEmbededInNativeApp())
  console.log('Is embedded in PLl App:', checkIfEmbededInPLLApp())

  return (
    <HelmetProvider>
      <Helmet>
        <title>Premier Lacrosse League Free To Play</title>
        <meta
          name="description"
          content={`Premier Lacrosse League's Fan Free To Plays. Play Pick'em, complete a bracket, earn prizes, and more.`}
        />
      </Helmet>
      <BrowserRouter>
        <NavbarProvider>
          <Suspense fallback={<SuspensePage />}>
            <StorageProvider>
              <LinkProvider>
                <SnackbarProvider>
                  <AdProvider>
                    <AuthProvider allowAnonymous>
                      <MainRoutes />
                    </AuthProvider>
                  </AdProvider>
                </SnackbarProvider>
              </LinkProvider>
            </StorageProvider>
          </Suspense>
        </NavbarProvider>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App
