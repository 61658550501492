import React from 'react'
import { MobileNavCon, MobileNavList } from '../SiteNavbar/styles'
import { NavList } from './NavList'

type MenuProps = {
  open: boolean
}

export const NavItems: React.FC<MenuProps> = ({ open }) => {
  return (
    <MobileNavCon open={open}>
      <MobileNavList open={open}>
        <NavList isMobile />
      </MobileNavList>
    </MobileNavCon>
  )
}
