import React, {
    createContext,
    useContext,
  } from 'react'
import { FantasyContext } from './FantasyContext'
import { acceptChallengeInvite } from '../../Api/Fantasy/fantasy.api'
import { AuthContext } from '../../Api/firebase'
import { logEvent } from '../../Utils'
import { ANALYTICS_TAGS } from '../../Constants/analytics'
  
  type FantasyInviteAcceptContextType = {
    handleChallengeParams: (referrerId: string) => Promise<boolean | void>
  }
  
  export const FantasyInviteAcceptContext =
    createContext<FantasyInviteAcceptContextType | null>(null)
  
  export const FantasyInviteAcceptProvider: React.FC<
    React.PropsWithChildren<unknown>
  > = ({ children }) => {
    let { fantasyUser } = useContext(FantasyContext)!
    let { login } = useContext(AuthContext)!

    const handleChallengeParams = async (referrerId: string) => {
      if (!fantasyUser) {
        return login()
      }

      let data = {
        acceptedUserId: fantasyUser?.firebaseId,
        referrerUserId: referrerId
      }
      let acceptedChallenge = await acceptChallengeInvite(fantasyUser?.firebaseId, data)

      if (!acceptedChallenge){
        console.log('Issue accepting challenge')
        return false
      } else {
        logEvent(ANALYTICS_TAGS.fantasy_accept_challenge, {
          acceptedUserId: fantasyUser.firebaseId,
          referrerUserId: referrerId
        })
        console.log('Successfully accepted challenge')
        return true
      }
    }
  
    return (
      <FantasyInviteAcceptContext.Provider
        value={{
          handleChallengeParams
        }}
      >
        {children}
      </FantasyInviteAcceptContext.Provider>
    )
  }
  