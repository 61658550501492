import React from 'react'
import { MobileBox, PageContainer } from '../Components/Layout'

export const SuspensePage: React.FC = () => {
  return (
    <PageContainer>
      <MobileBox
        vertMargins={0}
        sideMargins={0}
        color="dark"
        padding={0}
        fullHeight
      ></MobileBox>
    </PageContainer>
  )
}
