import React, { useContext, useEffect, useState } from 'react'
import { SiteNavbar } from './SiteNavbar'
import { FooterComponent } from '../../Footers/FooterComponent'
import { NavbarContext } from '../../../Context/NavbarContext'

type NavParams = {
  showPLLNav?: boolean
  showFixedAd?: boolean
  zIndex?: number
  children?: React.ReactNode
}

export const NavComponent: React.FC<NavParams> = ({ children, showFixedAd = true, zIndex}) => {
  let { shouldShowNav } = useContext(NavbarContext)!
  const [show, setShow] = useState<boolean>(shouldShowNav)

  useEffect(() => {
    if (shouldShowNav === false) {
      setShow(false)
    }
    
  }, [shouldShowNav])

  return (
    <>
      {show && <SiteNavbar />}
      {children}
      {show && <FooterComponent showFixedAd={showFixedAd} zIndex={zIndex} />}
    </>
  )
}
