import React, { useContext, useEffect, useState } from 'react'
import { Desc, Head } from '../../Typography/Text.styles'
import { SubmitButton } from '../../Buttons/SubmitButton'
import {
  resetWindowLocationWithoutParams,
  verifyGroupDesc,
  verifyGroupName,
} from '../../../Utils'
import { colors } from '../../consts'
import { InputStyle } from '../../Inputs/styles'
import { FantasyContext, FantasyGroupContext } from '../../../Context'
import { useQuery } from '../../../Routing'
import { CreateGroupCon } from './styles'
import { GoToGroup } from './GoToGroup'
import { useNavigate } from 'react-router-dom'
import { FANTASY_GROUPS_URL } from '../../../Routing/urls'

type CreateGroupProps = {
  handleShowGetAppCallout?: (show: boolean) => void
  competition?: number
}

export const CreateGroup: React.FC<
  React.PropsWithChildren<CreateGroupProps>
> = ({ handleShowGetAppCallout, competition }) => {
  const query = useQuery()
  const nav = useNavigate()
  const { createNewFantasyGroup, joinCompetition } = useContext(
    FantasyGroupContext
  )!
  const { submitTeamName, teamName: currentTeamName } =
    useContext(FantasyContext)!
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [groupInfo, setGroupInfo] = useState<{ [key: string]: string }>(
    {}
  )
  let [teamName, setTeamName] = useState<string | null>(currentTeamName)
  const [compCode, setCompCode] = useState<string | null>(null)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [createdGroupId, setCreatedGroupId] = useState<number | null>()

  useEffect(() => {
    const groupCompetitionQuery = query.get('compCode')
    if (groupCompetitionQuery) {
      setGroupInfo({
        ...groupInfo,
        description: `#${groupCompetitionQuery} `,
      })
    }
    setCompCode(groupCompetitionQuery)
  }, [])

  useEffect(() => {
    if (!currentTeamName) return
    setTeamName(currentTeamName)
  }, [currentTeamName])

  const handleGroupNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let name = e.target.value
    if (name.length > 2) {
      let { error, disabled } = verifyGroupName(name)
      setErrorMsg(error)
      setIsDisabled(disabled)
    }
    setGroupInfo({ ...groupInfo, groupName: e.target.value })
  }

  const handleGroupDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let desc = e.target.value
    if (desc.length > 2) {
      let { error, disabled } = verifyGroupDesc(desc)
      setErrorMsg(error)
      setIsDisabled(disabled)
    }
    setGroupInfo({ ...groupInfo, description: e.target.value })
  }
  const handleTeamNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTeamName(e.target.value)
  }

  const handleSubmitGroup = async () => {
    if (isDisabled) return

    if (!groupInfo?.groupName || groupInfo.groupName.length < 3) {
      setErrorMsg('Group name must be at least 3 characters')
      setIsDisabled(true)
      return
    }

    const teamNameSuccess = await handleTeamNameSubmit()
    if (!teamNameSuccess) return

    setTimeout(() => {
      // use timer to fix create group issue
      createNewFantasyGroup(groupInfo).then(async (createdGroupRes) => {
        if (createdGroupRes?.id) {
          setCreatedGroupId(createdGroupRes.id)
          //Show app callout if group was created (desktop only)
          handleShowGetAppCallout && handleShowGetAppCallout(true)
          if (compCode) {
            //Remove the tag to prevent adding to new groups
            resetWindowLocationWithoutParams(['compCode'])
          }
          // Join competition if passed in
          if (competition) {
            let joined = await joinCompetition(
              competition,
              createdGroupRes.id
            )
            if (joined){
              nav(FANTASY_GROUPS_URL)
            }
          }
        }
      })
    }, 2000)
  }

  const handleTeamNameSubmit = async () => {
    if (isDisabled || !teamName) {
      setErrorMsg('Must have team name')
      return false
    }
    if (teamName.length < 3) {
      setErrorMsg('Team name must be at least 3 characters')
      setIsDisabled(true)
      return false
    }
    if (teamName === currentTeamName) return true
    const submitSuccess = await submitTeamName(teamName)
    return submitSuccess
  }

  return (
    <CreateGroupCon>
      <Head color={colors.white}>Create Group</Head>
      <Desc color={colors.white}>
        Compete against your friends in your own private group!
      </Desc>
      <div className="inputCon">
        <InputStyle
          uiTheme="dark"
          error={errorMsg.length > 0}
          minWidth={'30vw'}
          id="outlined-group-submit"
          label={errorMsg.length > 0 ? 'Error' : 'Group Name'}
          placeholder={`Enter Group Name`}
          helperText={errorMsg}
          onChange={handleGroupNameChange}
          value={groupInfo?.groupName || ''}
        />
        <InputStyle
          uiTheme="dark"
          error={errorMsg.length > 0}
          minWidth={'30vw'}
          id="outlined-group-submit"
          label={errorMsg.length > 0 ? 'Error' : 'Your Team Name'}
          placeholder={'Enter Your Team Name'}
          helperText={errorMsg}
          onChange={handleTeamNameChange}
          value={teamName}
        />
        <InputStyle
          uiTheme="dark"
          error={errorMsg.length > 0}
          minWidth={'40vw'}
          multiline
          minRows={3}
          id="outlined-group-description"
          label={
            errorMsg.length > 0 ? 'Error' : 'Description (Optional)'
          }
          placeholder={`Enter Group Description`}
          helperText={errorMsg}
          onChange={handleGroupDescriptionChange}
          value={groupInfo?.description || ''}
        />
      </div>
      <div className="submitCon">
        {!createdGroupId && (
          <SubmitButton
            disabled={isDisabled}
            requireAuth={true}
            handleSubmit={handleSubmitGroup}
          />
        )}
        {createdGroupId && <GoToGroup groupId={createdGroupId!} />}
      </div>
    </CreateGroupCon>
  )
}
