import React, { useEffect, useContext, useState, Suspense } from 'react'
import { PicksContext } from '../../Context'
import { Overlay } from '../Layout'
import { MAIN_PLL_URL } from '../../Configs/config'
import { CashTagPopupInner } from './styles'
import { logFirEvent } from '../../Utils/analytics/firAnalytics'
import TextField from '../LazyComponents/Inputs/TextField'

type CashTagModalParams = {
  toggleCashTagModal: () => void
}

export const CashTagPopup: React.FC<CashTagModalParams> = ({
  toggleCashTagModal,
}) => {
  const [cashTagInput, setCashTagInput] = useState<string | undefined>(
    ''
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { handleCashTagSubmit, toggleSnackbar } =
    useContext(PicksContext)!

  useEffect(() => {}, [])

  const handleCashTagChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let tg = e.target.value
    tg = tg.replace('$', '')
    setCashTagInput(tg)
  }

  const handleCashTagSubmission = async () => {
    setIsSubmitting(true)
    if (!cashTagInput) {
      alert('Need to add a valid $Cashtag')
      setIsSubmitting(false)
      return
    }
    let success = await handleCashTagSubmit(cashTagInput)
    if (!success) {
      alert('Something went wrong.')
      setIsSubmitting(false)
      return
    }
    logFirEvent('bc_cashtag_submit', {
      cashTag: cashTagInput,
    })
    setIsSubmitting(false)
    toggleCashTagModal()
    toggleSnackbar()
  }

  return (
    <Overlay type="dialog" onClick={toggleCashTagModal}>
      <CashTagPopupInner>
        <h1>{'Add your $CashTag'}</h1>
        <Suspense fallback={<></>}>
          <TextField
            className="textField"
            required
            size="small"
            type="input"
            variant="outlined"
            margin="normal"
            id="cashTagOutlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={cashTagInput}
            onChange={(e) => {
              handleCashTagChange(e)
            }}
          />
        </Suspense>
        <div className="subTextRow">
          <p>{`No $Cashtag? `}</p>
          <a
            href={'https://cash.app/'}
            onClick={() => logFirEvent('bc_get_cash_app_click')}
          >
            {'Get one now'}
          </a>
        </div>
        <button
          className={isSubmitting ? 'disabled' : 'submitBtn'}
          disabled={isSubmitting ? true : false}
          onClick={handleCashTagSubmission}
        >
          {isSubmitting ? `Submitting...` : `Submit $Cashtag`}
        </button>
        <div className="stackedTextCon">
          <p>
            {
              'By participating in the Cash App Challenge and submitting your $Cashtag, you agree to the'
            }
          </p>
          <a
            href={`https://${MAIN_PLL_URL}/cash-app-challenge-terms-of-service`}
          >
            {'Cash App Challenge Terms'}
          </a>
        </div>
      </CashTagPopupInner>
    </Overlay>
  )
}
