import React from 'react'
import { styled } from '@mui/system'
import { colors } from '../consts'

type Color = 'white' | 'yellow' | 'dark' | 'grey'
type ColorMapType = { [key in Color]: string }

const colsorMap: ColorMapType = {
  white: colors.white,
  yellow: colors.yellow,
  dark: colors.text,
  grey: colors.darkLayout,
}

type styleProps = {
  color: Color
  sideMargins: number
  vertMargins: number
  padding?: number
  bgImg?: string
  boxWidth?: string | number
  fullHeight?: boolean
  rounded?: number
}

const BoxStyle = styled('section')<styleProps>(
  ({
    color,
    boxWidth,
    vertMargins,
    sideMargins,
    padding,
    bgImg,
    rounded,
    fullHeight,
    theme,
  }) => ({
    background: color ? colsorMap[color] : colors['white'],
    padding:
      padding !== null && padding !== undefined
        ? theme.spacing(padding, padding, padding + 35, padding)
        : theme.spacing(0, 0, 25, 0),
    margin: theme.spacing(vertMargins, sideMargins, 8, sideMargins),
    backgroundImage: bgImg !== '' ? `url(${bgImg})` : 'none',
    backgroundPosition: 'right top',
    backgroundSize: 'cover',
    borderRadius: rounded || 0,
    width: boxWidth ? boxWidth : '100%',
    minHeight: fullHeight ? `calc(100vh - 140px)` : 'auto',
    [theme.breakpoints.between('md', 'lg')]: {
      width: boxWidth ? '50%' : '100%',
      minHeight: fullHeight ? `calc(100vh - 120px)` : 'auto',
      margin: theme.spacing(vertMargins * 0.75, sideMargins * 0.75),
      backgroundPosition: 'right center',
      borderRadius: rounded ? rounded * 0.75 : 0,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minHeight: fullHeight ? `100vh` : 'auto',
      margin: theme.spacing(vertMargins * 0.75, sideMargins * 0.75),
      backgroundPosition: 'right center',
      borderRadius: rounded ? rounded * 0.75 : 0,
    },
  })
)

export type BoxProps = {
  color?: Color
  sideMargins?: number
  vertMargins?: number
  padding?: number
  bgImg?: string
  className?: string
  rounded?: number
  boxWidth?: string | number
  fullHeight?: boolean
  children?: React.ReactNode
}

export const Box: React.FC<React.PropsWithChildren<BoxProps>> = ({
  color = 'white',
  sideMargins = 2,
  vertMargins = 2,
  bgImg,
  padding,
  rounded = 0,
  fullHeight = false,
  children,
}) => {
  return (
    <BoxStyle
      color={color as Color}
      sideMargins={sideMargins}
      vertMargins={vertMargins}
      bgImg={bgImg}
      padding={padding}
      rounded={rounded}
      fullHeight={fullHeight}
    >
      {children}
    </BoxStyle>
  )
}

export const MobileBox: React.FC<React.PropsWithChildren<BoxProps>> = ({
  color = 'white',
  sideMargins = 2,
  vertMargins = 2,
  bgImg,
  padding,
  rounded = 0,
  boxWidth = '50%',
  fullHeight = false,
  children,
}) => {
  return (
    <BoxStyle
      color={color as Color}
      sideMargins={sideMargins}
      vertMargins={vertMargins}
      bgImg={bgImg}
      padding={padding}
      boxWidth={boxWidth}
      rounded={rounded}
      fullHeight={fullHeight}
    >
      {children}
    </BoxStyle>
  )
}
