import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { SuspensePage } from '../../Pages'

const FantasyGroupProvider = lazy(() =>
  import('../../Context').then((module) => ({
    default: module.FantasyGroupProvider,
  }))
)

export const FantasyGroupContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<SuspensePage />}>
      <FantasyGroupProvider>
        <Outlet />
      </FantasyGroupProvider>
    </Suspense>
  )
}
