import React, { useState, useEffect, useContext } from 'react'
import { validateEmail } from './authHelpers'
import { EmailPromptCon, ErrorStyle, InputStyle } from './styles'
import { Head2 } from '../Typography/Text.styles'
import { Btn } from '../Buttons/Btn'
import { AuthContext } from '../../Api/firebase'

export const EmailAuthPrompt: React.FC<React.PropsWithChildren> = () => {
  const { showEmailConfirm, handleEmailLinkFromConfirmPrompt, onEmailPromptHandle } = useContext(AuthContext)!
  const [email, setEmail] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState<string>('')

  useEffect(() => {

  }, [])

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let email = e.target.value
    if (!email) {
      setEmail('')
      return
    }
    setEmail(email.toLowerCase())
  }

  const handleEmailSubmit = async () => {
    console.log('Email prompt set')
    const validated = validateEmail(email)
    if (!validated.errorMsg) {
      setErrorMsg('')
      const success = await handleEmailLinkFromConfirmPrompt(email)
      if (!success) {
        alert('Trouble signing in')
      } else {
        setEmail('')
        onEmailPromptHandle()
      }
    } else {
      setErrorMsg(validated.errorMsg)
      return
    }
  }

  return (
    <>
    {showEmailConfirm && <EmailPromptCon>
      <Head2 justify="center">Confirm Your Email</Head2>
      <InputStyle
        required
        uiTheme='light'
        type="string"
        variant="outlined"
        margin="normal"
        id="temailOutlined"
        placeholder='Enter your email'
        InputLabelProps={{
          shrink: true,
        }}
        value={email}
        onChange={(e) => {
          handleEmailChange(e)
        }}
      />
      <ErrorStyle>{errorMsg}</ErrorStyle>
      <Btn btnStyle="primary" handleClick={handleEmailSubmit}>
        Confirm
      </Btn>
    </EmailPromptCon>}
    </>
  )
}
