import { styled } from '@mui/system'
import { colors, fontSizes } from '../../consts'

export const PrivateCompIndicatorCon = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
    '& .top': {
        cursor: 'pointer',
        width: '90%',
        backgroundColor: colors.black,
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    '& .bottom': {
        width: '90%',
        backgroundColor: colors.gray.gray80,
        padding: theme.spacing(1)
    }
}))

export const EnterGroupButton = styled('div')(({theme}) => ({
    cursor: 'pointer',
    backgroundColor: colors.gray.gray60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    padding: theme.spacing(.5, 1),
}))

export const IneligibleIndicatorCon = styled('div')(({theme}) => ({
    backgroundColor: colors.gray.gray80,
    cursor: 'pointer',
    margin: theme.spacing(1, 1, 0, 1),
    padding: theme.spacing(1),
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .leftCon': {
        display: 'flex',
        alignItems: 'center',
        width: '85%'
    }
}))

export const GroupListCompetitionCalloutCon = styled('div')(({theme}) => ({
    backgroundColor: colors.gray.gray80,
    borderRadius: 2,
    width: '97%',
    paddingBottom: theme.spacing(.1),
    '& .top': {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        '& .topLeft': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .closesCon': {
            margin: theme.spacing(.5),
            borderRadius: 12,
            background: colors.black,
            color: colors.white,
            border: 'none',
            padding: theme.spacing(.5, 1),
            fontSize: fontSizes.xSmall,
            fontWeight: 800,
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
              fontSize: fontSizes.xxSmall,
              fontWeight: 600,
            },
        }
    },
    '& .enterGroupsButton': {
        cursor: 'pointer',
        backgroundColor: colors.gray.gray60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(.25, 0),
        margin: theme.spacing(2),
        borderRadius: 2,
    },
    '& .ribbon': {
        width: 15,
        height: 15,
        borderRadius: 2,
        background: `linear-gradient(to right bottom, #ffcb06 50%, #313131 50.3%);`
    }
}))