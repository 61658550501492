import { colors } from '../consts'
import { styled } from '@mui/system'
import { PersonIcon } from '../LazyComponents/lazyIcons'

const PersonCon = styled(PersonIcon)<{
  iconColor?: string
  height?: number
}>(({ theme, iconColor, height }) => ({
    display: 'flex',
    alignItems: 'end',
    color: iconColor ?? colors.white,
    height: height ?? 20,
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
}))

type PersonIconProps = {
  iconColor?: string
  height?: number
}

export const Person: React.FC<PersonIconProps> = ({
  iconColor,
  height,
}) => {
  return <PersonCon fontSize="large" iconColor={iconColor} height={height} />
}
