import lazyImport from '../../Utils/Loading/LazyImportHelper'

// ARROWS //
export const ArrowForward = lazyImport(() => import('@mui/icons-material/ArrowForward'))
export const ArrowDownward = lazyImport(() => import('@mui/icons-material/ArrowDownward'))
export const KeyboardArrowRightIcon = lazyImport(() => import('@mui/icons-material/KeyboardArrowRight'))
export const KeyboardArrowLeftIcon = lazyImport(() => import('@mui/icons-material/KeyboardArrowLeft'))

export const Schedule = lazyImport(() => import('@mui/icons-material/Schedule'))
export const MoreVert = lazyImport(() => import('@mui/icons-material/MoreVert'))
export const ModeEditIcon = lazyImport(() => import('@mui/icons-material/ModeEdit'))
export const NotificationsIcon = lazyImport(() => import('@mui/icons-material/Notifications'))
export const ErrorIcon = lazyImport(() => import('@mui/icons-material/Error'))
export const IosShareIcon = lazyImport(() => import('@mui/icons-material/IosShare'))
export const InfoOutlined = lazyImport(() => import('@mui/icons-material/InfoOutlined'))
export const RadioButtonUncheckedOutlined = lazyImport(() => import('@mui/icons-material/RadioButtonUncheckedOutlined'))
export const TollIcon = lazyImport(() => import('@mui/icons-material/Toll'))
export const WhatshotSharpIcon = lazyImport(() => import('@mui/icons-material/WhatshotSharp'))

// PROFILE //
export const AccountCircleOutlined = lazyImport(() => import('@mui/icons-material/AccountCircleOutlined'))
export const PeopleAlt = lazyImport(() => import('@mui/icons-material/PeopleAlt'))
export const PersonAddAlt1Icon = lazyImport(() => import('@mui/icons-material/PersonAddAlt1'))
export const PersonIcon = lazyImport(() => import('@mui/icons-material/Person'))

export const AddCircleIcon = lazyImport(() => import('@mui/icons-material/AddCircle'))
export const RemoveCircleIcon = lazyImport(() => import('@mui/icons-material/RemoveCircle'))
export const Cancel = lazyImport(() => import('@mui/icons-material/Cancel'))
export const DeleteOutlined = lazyImport(() => import('@mui/icons-material/DeleteOutlined'))
export const CheckCircle = lazyImport(() => import('@mui/icons-material/CheckCircle'))
export const CheckIcon = lazyImport(() => import('@mui/icons-material/Check'))
export const Close = lazyImport(() => import('@mui/icons-material/Close'))
export const SwapHorizIcon = lazyImport(() => import('@mui/icons-material/SwapHoriz'))
export const Handyman = lazyImport(() => import('@mui/icons-material/Handyman'))
export const QrCodeIcon = lazyImport(() => import('@mui/icons-material/QrCode'))

// QUESTIONS //
export const PollIcon = lazyImport(() => import('@mui/icons-material/Poll'))
export const LockIcon = lazyImport(() => import('@mui/icons-material/Lock'))

// SOCIAL //
export const GoogleIcon = lazyImport(() => import('@mui/icons-material/Google'))

// AI //
export const AutoAwesome = lazyImport(() => import('@mui/icons-material/AutoAwesome'))