import { Event, Pick, ReducedPick, Team, WeeklyPick } from '../../Api'
import { playoffEventIds } from '../../Configs/config'
export type PickStatus =
  | 'editing'
  | 'disabled'
  | 'complete'
  | 'incomplete'

export const checkPickemCorrect = (
  selections: Pick[],
  event: Event,
  teamId: string
) => {
  if (!event.awayTeam || !event.homeTeam) {
    return 'neutral'
  }
  let picked = selections.find((sel) => sel.id === event.id)
  if (
    picked &&
    picked?.pickedTeam === teamId &&
    event.eventStatus > 1
  ) {
    //Game has ended, check if right or wrong
    if (picked.correct === true) {
      return 'correct'
    }
    if (picked.correct === false) {
      //Picked specific game wrong
      return 'incorrect'
    }
    const winner = getWinner(event)
    if (winner && winner !== picked.pickedTeam) {
      return 'neutral'
    }
    return ''
  }
  return ''
}

export function getTeamRecord(
  team: Team,
  segment: string
): string | undefined {
  let recordStr = ''
  switch (segment) {
    case 'regular':
      recordStr = `${team.teamWins || 0}-${team.teamLosses || 0} ${
        team.teamTies ? `-${team.teamTies}` : ''
      }`
      break
    case 'post':
      recordStr = `${team.teamWins || 0}-${team.teamLosses || 0} ${
        team.teamTies ? `-${team.teamTies}` : ''
      }`
      break
    case 'champseries':
      if (team.champSeries) {
        recordStr = `${team.champSeries.teamWins || 0}-${
          team.champSeries.teamLosses || 0
        } ${
          team.champSeries.teamTies
            ? `-${team.champSeries.teamTies}`
            : ''
        }`
        break
      }
  }
  return recordStr
}

/**
 *
 * Get status of that specific week
 */
export const generatePickStatus = (
  week: number,
  weekEvents: Event[],
  selections: Pick[]
) => {
  const userPicks = getPicksCount(selections, week)
  if (userPicks >= 1) {
    return 'complete'
  }
  return 'incomplete'
}

/**
 *
 * Get number of picks total for the specific week
 */
export const getPicksCount = (selections: Pick[], week: number) => {
  return selections.filter((sel) => sel.week === week).length
}

/**
 *
 * Mapping picks to send to database
 */
export const mapPickObjs = (selections: Pick[]): ReducedPick[] => {
  let picks: ReducedPick[] = []
  selections.map((sel) => {
    const pick = {
      id: sel.id,
      pickedTeam: sel.pickedTeam,
    }
    return picks.push(pick)
  })
  return picks
}

/**
 *
 * Check if picks are open for fans to select
 */
export const cleanSubmittedPicks = (
  weekEvents: Event[],
  wkPicks: Pick[]
) => {
  const now = new Date()
  const currentTime = now.getTime() / 1000

  let picks: Pick[] = []
  weekEvents.map((ev) => {
    const diff = ev.startTime - currentTime
    if (diff > 0 && ev.eventStatus === 0) {
      // Games have not started
      const sel = wkPicks.find((pick) => pick.id === ev.id)
      if (!sel) return null
      picks.push(sel)
      return ev
    } else {
      return
    }
  })
  return picks
}

/**
 *
 * Check if picks are open for fans to select
 */
export const checkSinglePickEnabled = (id: number, events: Event[]) => {
  const ev = events.find((event) => event.id === id)
  if (!ev) return false
  const now = new Date()
  //Set current time to seconds
  const currentTime = now.getTime() / 1000
  const diff = ev.startTime - currentTime
  if (diff < 0 || ev.eventStatus > 0) {
    // Games are too close to start or already happened
    return false
  } else {
    return true
  }
}

/**
 *
 * Check if picks are open for fans to select with single event
 */
export const checkPickEnabledByEvent = (event: Event) => {
  const now = new Date()
  const currentTime = now.getTime() / 1000
  const diff = event.startTime - currentTime
  if (diff < 0 || event.eventStatus > 0) {
    // Games are too close to start or already happened
    return false
  } else {
    return true
  }
}

/**
 *
 * Finding score of Pickem Week
 */
export const calcPickemWeekScore = (picks: Pick[]) => {
  let score: number = 0
  picks.map((pick) => {
    if (pick.correct === true) {
      score++
    }
  })
  return score
}

/**
 *
 * Finding score by week
 */
export const getWeeklyScore = (
  weekPicks: WeeklyPick[],
  week: number
) => {
  const current = weekPicks.find((wk) => wk.week === week)
  if (!current) return 0
  let score: number = 0
  current.picks.map((pick) => {
    if (pick.correct) {
      score++
    }
  })

  return score
}

export const getCorrect = (
  selections: Pick[],
  event: Event,
  teamId: string
) => {
  //Get any selections where user picked that team
  let playoffSelections = selections.filter(
    (pick) => pick?.id >= playoffEventIds.q1
  )

  //Anytime a user picked that team
  let teamPicks = playoffSelections.filter(
    (pick) => pick.pickedTeam === teamId
  )
  const gamePick = playoffSelections.find(
    (pick) => pick.id === event.id
  )

  if (
    gamePick &&
    gamePick.pickedTeam === teamId &&
    event.eventStatus > 1
  ) {
    //Game has ended, check if right or wrong
    const winner = getWinner(event)
    if (gamePick.correct === true) {
      return 'correct'
    }
    if (
      gamePick.correct === false &&
      gamePick.id < playoffEventIds.s1
    ) {
      //Picked specific game wrong
      return 'incorrect'
    }
    if (winner && winner !== gamePick.pickedTeam) {
      return 'neutral'
    }
    return ''
  }

  //Game is later round
  //AND team pick was incorrect earlier
  //iterate through to see if picked team was ever incorrect before
  const teamAlreadyIncorrect = teamPicks.find(
    (pick) => pick.correct === false && pick.id < event.id
  )
  return teamAlreadyIncorrect ? 'incorrect' : ''
}

export const showCorrectTeam = (
  selections: Pick[],
  event: Event,
  gamesList: Event[],
  teamId: string,
  home: boolean
) => {
  selections = selections.filter(
    (pick) => pick?.id >= playoffEventIds.q1
  )
  const pick = selections.find((pick) => pick.id === event.id)
  if (!pick) return
  const games = mapPreviousBracket(event.id)
  if (games.length >= 1) {
    let gamesToCheck: Event[] = []

    let g = games.map((gm) => {
      const ev = gamesList.find((event) => event.id === gm)
      if (!ev) return
      gamesToCheck.push(ev)
    })
    if (g.length > 0) {
      let correctTeam = findCorrectTeam(
        selections,
        gamesList,
        gamesToCheck,
        event,
        teamId,
        home
      )
      return correctTeam
    }
    return
  }
  return
}

const findCorrectTeam = (
  selections: Pick[],
  gamesList: Event[],
  gamesToCheck: Event[],
  event: Event,
  teamId: string,
  home: boolean
) => {
  let foundTeam = ''
  //CHAMPIONSHIP GAME
  if (event.id === playoffEventIds.c) {
    foundTeam = correctChampTeam(
      selections,
      gamesList,
      teamId,
      event,
      home
    )
  } else {
    //CHECKING ALL QUARTERFINALS
    gamesToCheck.map((gmc) => {
      if (!gmc.awayTeam || !gmc.homeTeam) return
      const pick = selections.find((pick) => pick.id === gmc.id)
      if (pick && pick.correct === false) {
        //Check if correct game to check

        if (
          teamId === gmc.awayTeam.officialId ||
          teamId === gmc.homeTeam.officialId
        ) {
          // return opposite team because the pick was incorrect
          foundTeam =
            teamId === gmc.awayTeam.officialId
              ? gmc.homeTeam.fullName
              : gmc.awayTeam.fullName
          return
        }
        return
      }
    })
  }

  return foundTeam
}

const correctChampTeam = (
  selections: Pick[],
  gamesList: Event[],
  teamId: string,
  currentEvent: Event,
  home: boolean
) => {
  let correctTeam = ''

  if (!home) {
    // SEMI 1 AWAY TEAM
    const semi1 = gamesList.find(
      (event) => event.id === playoffEventIds.s1
    )
    if (semi1 && semi1?.eventStatus > 1) {
      //Find out if selection was correct
      const pick = selections.find(
        (pick) => pick.id === playoffEventIds.s1
      )
      if (currentEvent?.awayTeam?.officialId === pick?.pickedTeam) {
        return ''
      }
      let pickStatus = getCorrect(selections, semi1, teamId)
      if (pickStatus === 'incorrect') {
        return (correctTeam =
          semi1.homeScore > semi1.visitorScore
            ? semi1.homeTeam.fullName
            : semi1.awayTeam.fullName)
      }
    }
  } else {
    const semi2 = gamesList.find(
      (event) => event.id === playoffEventIds.s2
    )
    if (semi2 && semi2?.eventStatus > 1) {
      //Find out if selection was correct
      const pick = selections.find(
        (pick) => pick.id === playoffEventIds.s2
      )
      if (currentEvent?.homeTeam?.officialId === pick?.pickedTeam) {
        return ''
      }
      let pickStatus = getCorrect(selections, semi2, teamId)
      if (pickStatus === 'incorrect') {
        return (correctTeam =
          semi2.homeScore > semi2.visitorScore
            ? semi2.homeTeam.fullName
            : semi2.awayTeam.fullName)
      }
    }
  }
  return correctTeam
}

export const getWinner = (event: Event) => {
  if (event.eventStatus < 2) return
  return event.homeScore > event.visitorScore
    ? event.homeTeam.officialId
    : event.awayTeam.officialId
}

const mapPreviousBracket = (eventId: number) => {
  switch (eventId) {
    case playoffEventIds.s1:
      return [playoffEventIds.q1, playoffEventIds.q2]
    case playoffEventIds.s2:
      return [playoffEventIds.q3]
    case playoffEventIds.c:
      return [
        playoffEventIds.s1,
        playoffEventIds.s2,
        playoffEventIds.q1,
        playoffEventIds.q2,
        playoffEventIds.q3,
      ]
    default:
      return []
  }
}
