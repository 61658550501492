import { QueryParams, makeAPIRequest } from '../utils/request'
import { gamificationEndpoints } from '../index'
import { AchievementsGroup } from '../types/achievements.types'
import { getFirAuth } from '../firebase'
import {
  GamificationConfigRes,
  UserRewardRes,
} from '../types/productConfigs.types'
import {
  GamificationEngagementType,
  LeaderboardRes,
} from './pllNation.types'

/**
 * Fetch config file for PLL Nation
 */
export const fetchGamificationConfig =
  async (): Promise<GamificationConfigRes | null> => {
    try {
      const configRes = await makeAPIRequest(
        gamificationEndpoints.GET_CONFIG,
        {
          method: 'GET',
          apiVersion: '2',
          headerParams: {
            Authorization:
              `Bearer ${process.env.REACT_APP_API_ACCESS_KEY}` || '',
          },
          queryParams: {
            products: 'gamification',
          },
        }
      )
      if (!configRes?.data || configRes?.error) {
        console.error(configRes.error?.message)
        return null
      }
      return configRes.data
    } catch (err: unknown) {
      console.error(err)
      return null
    }
  }

/**
 * Fetch data for fanbase race by week
 */
export const fetchAchievements = async (
  userId: string
): Promise<AchievementsGroup | null> => {
  const usr = (await getFirAuth())?.currentUser
  if (!usr) {
    console.log('User is not signed in. Cannot fetch achievements.')
    return null
  }
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  try {
    const achRes = await makeAPIRequest(gamificationEndpoints.PROFILE, {
      method: 'GET',
      apiVersion: '1',
      headerParams,
      queryParams: {
        userId,
      },
    })
    if (!achRes?.data || achRes?.error) {
      console.error(achRes.error?.message)
      return null
    }
    return achRes.data
  } catch (err) {
    console.log('Failed to fetch achievements.')
    return null
  }
}

export const claimCode = async (code: string) => {
  const usr = (await getFirAuth())?.currentUser
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  let body = JSON.stringify({ code: code })
  try {
    const response = await makeAPIRequest(
      gamificationEndpoints.CLAIM_CODE,
      {
        method: 'POST',
        apiVersion: '2',
        headerParams: headerParams,
        body: body,
      }
    )

    if (response?.error) {
      console.error(response.error?.message)
      return {
        success: false,
        status: response.error?.code || null,
        error:
          response.error?.message ||
          'Trouble submitting code. Try again later',
      }
    }
    return {
      success: true,
      status: 200,
      error: null,
    }
  } catch (err: any) {
    return {
      success: false,
      status: 400,
      error:
        'Whoops! Code was already claimed or cannot be claimed at this time.',
    }
  }
}

export const claimDailyXP = async () => {
  const usr = (await getFirAuth())?.currentUser
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  try {
    const response = await makeAPIRequest(
      gamificationEndpoints.DAILY_EXP_CLAIM,
      {
        method: 'POST',
        apiVersion: '2',
        headerParams: headerParams,
      }
    )

    if (response?.error) {
      console.error(response.error?.message)
      return {
        success: false,
        status: response.error?.code || null,
        error:
          response.error?.message ||
          'Trouble claiming XP. Try again later',
      }
    }

    return {
      success: true,
      status: 200,
      error: null,
    }
  } catch (err: any) {
    console.log(err)
    return {
      err,
    }
  }
}

export const fetchLeaderboardData =
  async (): Promise<LeaderboardRes> => {
    const usr = (await getFirAuth())?.currentUser
    let headerParams: QueryParams = {}
    if (usr) {
      const token = await usr.getIdToken()
      headerParams.Authorization = `Bearer ${token}`
    }
    try {
      const achRes = await makeAPIRequest(
        gamificationEndpoints.LEADERBOARDS,
        {
          method: 'GET',
          apiVersion: '1',
          headerParams,
        }
      )
      if (!achRes?.data || achRes?.error) {
        console.error(achRes.error?.message)
        return { seasonLeaderboard: [], weeklyLeaderboard: [] }
      }
      return achRes.data
    } catch (err) {
      console.error('Failed to fetch leaderboards.')
      return { seasonLeaderboard: [], weeklyLeaderboard: [] }
    }
  }

/**
 * Get User's Badges and Benefits
 */
export const fetchUserRewards = async (
  userId: string
): Promise<UserRewardRes | null> => {
  const usr = (await getFirAuth())?.currentUser
  if (!usr) return null
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  const route = `${gamificationEndpoints.UPDATE_USER}/${userId}/${gamificationEndpoints.REWARDS}`
  try {
    const response = await makeAPIRequest(route, {
      method: 'GET',
      apiVersion: '1',
      headerParams: headerParams,
    })

    if (!response?.data || response?.error) {
      console.error(response.error?.message)
      return null
    }
    return response.data
  } catch (err) {
    console.error('Error getting user rewards.')
    return null
  }
}

/**
 * Send Gamification Achievement API
 */
export const sendGamificationEngagement = async (
  userId: string,
  event: GamificationEngagementType | string,
  engagementId: string | number
) => {
  const usr = (await getFirAuth())?.currentUser
  if (!usr) return null
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  let body = {
    userId: userId,
    timestamp: new Date().getTime(),
    source: 'web',
    type: 'participation',
    event: event,
    engagementSourceId: engagementId,
  }
  try {
    const response = await makeAPIRequest(
      gamificationEndpoints.ENGAGEMENT,
      {
        method: 'POST',
        apiVersion: '1',
        headerParams,
        body: JSON.stringify(body),
      }
    )

    if (response?.error) {
      console.error(response.error?.message)
      return false
    }
    return true
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}
