import { styled } from '@mui/system'
import { colors, fontSizes } from '../../../Components/consts'

export const GroupListCon = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 2),
}))

export const GroupListHeaderCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const GroupRowOuter = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: 6,
  background: colors.gray.gray80,
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(1, 0),
  },
  '& a': {
    textDecoration: 'none',
  },
}))

export const GroupRowInner = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0),
  },
  '& .groupInfoCon': {
    display: 'flex',
    alignItems: 'center',
    '& .imgCon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(1, 2),
      borderRadius: '50%',
      padding: theme.spacing(1),
      background: colors.black,
      width: 100,
      height: 100,
      [theme.breakpoints.down('sm')]: {
        width: 50,
        height: 50,
      },
      '& img': {
        width: 65,
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
          width: 35,
          borderRadius: 2,
        },
      },
    },
    '& .nameCon': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& h2': {
        fontSize: fontSizes.semiLarge,
        fontWeight: 800,
        margin: theme.spacing(0, 2, 0.5),
        [theme.breakpoints.down('sm')]: {
          fontSize: fontSizes.medium,
        },
      },
      '& .groupMembers': {
        fontSize: fontSizes.medium,
        fontWeight: 500,
        color: colors.gray.gray40,
        margin: theme.spacing(0, 2),
        [theme.breakpoints.down('sm')]: {
          fontSize: fontSizes.xSmall,
        },
      },
    },
  },
  '& .actionCon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    [theme.breakpoints.down('sm')]: {
      width: 40,
    },
  },
}))

export const ButtonCreate = styled('button')(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: 12,
  background: colors.black,
  color: colors.white,
  border: 'none',
  padding: theme.spacing(1, 2),
  fontSize: fontSizes.small,
  fontWeight: 800,
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: fontSizes.xSmall,
    fontWeight: 600,
  },
}))

export const CreatGroupCon = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
  '& .inputCon': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  '& .submitCon': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}))

export const GoToGroupCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& .goToLink': {
    textDecoration: 'none',
    color: colors.black,
    background: colors.white,
    borderRadius: 12,
    padding: theme.spacing(1, 2),
    fontSize: fontSizes.small,
    fontWeight: 800,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizes.xSmall,
      fontWeight: 600,
    },
  },
}))

export const MyGroupsCon = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  '& .buttonCon': {
    cursor: 'pointer',
    backgroundColor: colors.yellow,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    padding: theme.spacing(1, 0),
    marginTop: theme.spacing(2),
  },
}))

export const InfoSlideUpCon = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  borderTop: `2px solid ${colors.gray.gray40}`,
  borderRadius: 6,
  width: '100%',
  '& .text': {
    marginLeft: theme.spacing(2),
  },
}))
