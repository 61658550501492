export const questionsWithUserQuery = `
query($group: String,  $eventId: String, $questionId: String, $userId: String, $type: String, $week: Int, $year: Int, $timing: String, $isActive: Boolean){
  questions(group: $group,  eventId: $eventId, questionId: $questionId, userId: $userId, type: $type, week: $week, year: $year, timing: $timing, isActive: $isActive){
    id
    questionId
    question
    group
    groupText
    groupSort
    eventId
    year
    week
    timing
    startTime
    endTime
    period
    type
    stat
    player
    team
    active
    isOpen
    closeTime
    isChangeable
    showPctAfter
    showPctBefore
    showAfterSubmit
    appExclusive
    correctAnswer
    authRequired
    userAnswerCode
    shareLink
    answerChoices{
      questionId
      value
      code
      image
      count
      sort
      extraText
      correct
    }
  }
}
`

export const singleQuestionQuery = `
query($questionId: String, $userId: String){
  questions(questionId: $questionId, userId: $userId){
    id
    questionId
    question
    eventId
    year
    week
    timing
    startTime
    endTime
    period
    type
    correctAnswer
    stat
    player
    team
    active
    isOpen
    closeTime
    isChangeable
    showPctAfter
    showPctBefore
    showAfterSubmit
    authRequired
    event{
      homeScore
      visitorScore
    }
    answerChoices{
      value
      code
      image
      users
      sort
      extraText
    }
  }
}
`

export const activeQuestionsQuery = `
query($timing: String, $week: Int, $year: Int, $gamificationWeek: Int, $userId: String, $timing: String, $isActive: Boolean){
  questions(isActive:true, timing: $timing, week: $week, year: $year, gamificationWeek: $gamificationWeek, userId: $userId, timing: $timing, isActive: $isActive){
    id
    questionId
    question
    eventId
    group
    groupText
    groupSort
    year
    week
    timing
    startTime
    closeTime
    endTime
    period
    type
    correctAnswer
    stat
    player
    team
    active
    isChangeable
    showPctAfter
    showPctBefore
    showAfterSubmit
    appExclusive
    authRequired
    shareLink
    answerChoices{
      questionId
      value
      code
      image
      count
      sort
      extraText
      correct
    }
  }
}
`

export const submitAnswerMutation = `
mutation($userId:String!, $questionId: Int!, $answerCode: [String]!){
  submitAnswer(userId:$userId, questionId: $questionId, answerCode: $answerCode) {
		questionId
    userAnswerCode
  }
}
`
