import React, { useContext, useEffect, useState } from 'react'
import { Overlay } from '../Layout'
import { ModalFrame } from './styles'
import { SignInCon } from './SignInCon'
import { AuthContext } from '../../Api/firebase'
import { SignInMoreData } from './SignInMoreData'

type SignInModalProps = {
  onClose: () => void
  signInWithSocial: (provider: string) => Promise<void>
  signInWithEmLink: (
    redirectTo: string,
    email: string
  ) => Promise<boolean>
  extraFields?: string[]
  uiTheme?: 'light' | 'dark'
}

export const SignInModal: React.FC<
  React.PropsWithChildren<SignInModalProps>
> = ({
  onClose,
  signInWithSocial,
  signInWithEmLink,
  extraFields = [],
  uiTheme = 'dark'
}) => {
  const { isLoading, isLoggedIn, getMissingUserFields } =
    useContext(AuthContext)!
  const [flowStep, setFlowStep] = useState<number>(1)

  useEffect(() => {
    if (isLoading || !isLoggedIn) return
    if (extraFields.length < 1) return onClose()
    //Check for missing profile info
    let missingFields = getMissingUserFields(extraFields)
    if (missingFields.length < 1) return onClose()
    setFlowStep(2)
  }, [isLoading])

  return (
    <Overlay type="slideup" orientation="bottom" onClick={onClose}>
      <ModalFrame uiTheme={uiTheme}>
        {flowStep === 1 && (
          <SignInCon
            onClose={onClose}
            signInWithSocial={signInWithSocial}
            signInWithEmLink={signInWithEmLink}
            uiTheme={uiTheme}
          />
        )}
        {flowStep === 2 && (
          <SignInMoreData
            extraFields={extraFields}
            onFinishedSubmit={onClose}
            allowSkip={false}
            uiTheme="dark"
          />
        )}
      </ModalFrame>
    </Overlay>
  )
}
