import { colors, fontSizes } from '../../consts'
import { styled } from '@mui/system'

export const AppCalloutCon = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
}))

export const OptionCon = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    right: 0,
    left: 0,
    padding: theme.spacing(1, 0),
    backgroundImage: `linear-gradient(to right, #000000, #434343)`,
  },
  '& img': {
    width: 20,
    height: 20,
    marginRight: theme.spacing(2),
    borderRadius: 3,
  },
  '& .text': {
    fontSize: fontSizes.small,
    color: colors.white,
    fontWeight: 'bold',
    marginRight: 10,
  },
}))

export const CloseCon = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 40,
  justifyContent: 'center',
  borderRight: `solid 1px ${colors.white}`,
  margin: theme.spacing(0, 1, 0, 0),
  '& p': {
    fontSize: fontSizes.medium,
    fontWeight: 'bold',
    color: colors.white,
  },
}))

type BottomConProps = {
  zIndex?: number
}
export const BottomCon = styled('div')<BottomConProps>(({ theme, zIndex }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 70,
  zIndex: zIndex ?? 999,
  [theme.breakpoints.down('md')]: {
    bottom: 35
  }
}))

export const AppLinkButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(3),
  fontWeight: 'bold',
  color: colors.text,
  cursor: 'pointer',
  backgroundColor: colors.white,
  marginRight: theme.spacing(1),
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
}))
