import { Event } from '../../Api'
import { CURRENT_SEASON_WEEKS, WeekObj } from '../../Configs/config'

// Post Season begins after this week
export const REGULAR_SEASON_END_WEEK = {
  2023: 11,
  2024: 11
}
// Options for season segments
export const SEASON_SEGMENT_NAMES = {
  regular: 'regular',
  post: 'post',
  allstar: 'allstar',
  champseries: 'champseries',
}

/**
 *
 * Returns the current PLL week number based on passed in weeks or uses default weeks
 */
export const findCurrentWeekByDate = (weeks?: WeekObj[]) => {
  const currentTime = Math.floor(Date.now() / 1000)
  if (!weeks || weeks.length === 0) {
    //Use default hardcoded weeks
    return findCurrentWeekWithDefaults(currentTime)
  }
  const currentWeek = CURRENT_SEASON_WEEKS.find(
    (week) =>
      currentTime >= week.openTime && currentTime <= week.closeTime
  )
  if (!currentWeek) {
    //If outside of season, return first week or last week
    return findFirstOrLastWeek(currentTime, CURRENT_SEASON_WEEKS)
  }
  return currentWeek.week
}

/**
 *
 * Returns the current PLL week number based on hardcoded week list
 */
export const findCurrentWeekWithDefaults = (currentTime: number) => {
  const currentWeek = CURRENT_SEASON_WEEKS.find(
    (week) =>
      currentTime >= week.openTime && currentTime <= week.closeTime
  )
  if (!currentWeek) {
    //If outside of season, return first week or last week
    return findFirstOrLastWeek(currentTime, CURRENT_SEASON_WEEKS)
  }
  return currentWeek.week
}

const findFirstOrLastWeek = (currentTime: number, weeks: WeekObj[]) => {
  if (currentTime < weeks[0].openTime) {
    return weeks[0].week
  } else {
    return weeks[weeks.length - 1].week
  }
}

/**
 *
 * Returns if current week is a playoff week by week passed in
 */
export const isPostseasonByWeek = (
  currentWeek: number,
  year: number
) => {
  return currentWeek > REGULAR_SEASON_END_WEEK[year]
}

/**
 *
 * Returns if current week is a playoff week by an events passed in
 */
export const isPostseasonByEvent = (events: Event[]) => {
  if (events.length < 1) return false
  const finalEventStatus = events[events.length - 1].eventStatus
  if (finalEventStatus > 1) {
    // Season has ended, consider it postseason
    return true
  }
  const nextGame = events.find((ev) => ev.eventStatus === 0)
  if (
    nextGame &&
    nextGame.seasonSegment === SEASON_SEGMENT_NAMES.post &&
    nextGame.awayTeam !== null
  ) {
    //Game is in the postseason, with teams
    return true
  }
  return false
}

/**
 *
 * Returns if current week is a playoff week by an events passed in
 */
export const getWeekTitle = (week: number) => {
  if(week === 0) return 'Championship Series'
  return `Week ${week}`
}

export const PLLNSeasons = [
  {
    start: 1675742401,
    season: 1,
  },
  {
    start: 1706590800,
    season: 2,
  },
  // Add more seasons as needed
]
