import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import {
  FantasyUser,
  fetchFantasyConfig,
  fetchFantasyUser,
} from '../../Api'
import { SEASON } from '../../Configs/config'
import { AuthContext } from '../../Api/firebase'
import {
  defaultCompiledPicks,
  findCurrentWeekByDate,
  getPickedPlayers,
  logEvent,
  teamHasPlayerWithGameStarted,
  hasGameStarted,
  checkPlayerCount,
  getCapLeft,
} from '../../Utils'
import {
  FantasyConfig,
  FantasyPlayer,
  CompiledPicksType,
  FantasyPositionTypes,
} from '../../Api/Fantasy/fantasy.types'
import {
  fetchAllFantasyPlayers,
  fetchFantasyPlayer,
} from '../../Api/Players/players.api'
import {
  submitFantasyTeam,
  updateFantasyUser,
} from '../../Api/Fantasy/fantasy.api'
import { SnackbarData } from '../../Api/types/picks.types'
import { GeneralSnackbar } from '../../Components/Snackbar/GeneralSnackbar'
import { SeasonContext } from '../SeasonContext'
import {
  getPicksArray,
  getPositionLong,
  reduceTotalNeededPlayers,
  selectProjectedPoints,
  sortPositionFilters,
} from '../../Utils/Fantasy'
import {
  FANTASY_SALARY_CAP,
  fantasyPositionTypes,
} from '../../Configs/Fantasy/fantasy.config'
import { ANALYTICS_TAGS } from '../../Constants/analytics'
import { logFirEvent } from '../../Utils/analytics/firAnalytics'
import { STORAGE_NAMES } from '../../Constants/storage'
import { StorageContext } from '../StorageContext'

type FantasyContextType = {
  currentWeek: number
  fantasyConfig: FantasyConfig | null
  fantasyUser: FantasyUser | null
  loading: boolean
  isPlayersLoading: boolean
  playerCardLoading: boolean
  capLeft: number | null
  maxCap: number
  showPlayerSwap: boolean
  compiledPicks: CompiledPicksType
  teamHasChanged: boolean
  showPlayerCard: boolean
  showModal: { [key: string]: { show: boolean; modalTitle: string } }
  playerCardPlayer: FantasyPlayer | undefined
  playerSwapPosition: string | undefined
  allPlayers: FantasyPlayer[]
  projPoints: string
  showSnackbar: boolean
  snackbarData: SnackbarData | null
  showTeamNameSubmit: boolean
  teamName: string | null
  showTeamOptions: boolean
  weekPosTypes: FantasyPositionTypes[]
  handleTeamOptionsShow: () => void
  removePick: (position: string, player?: FantasyPlayer) => void
  actionButtonClick: (
    type: string,
    position: string,
    player?: FantasyPlayer
  ) => void
  addPick: (position: string, player?: FantasyPlayer) => void
  getProjPoints: (compiled: CompiledPicksType) => void
  submitTeam: () => Promise<boolean>
  clearTeam: () => Promise<boolean>
  toggleSnackbar: (message: SnackbarData) => void
  submitTeamName: (teamName: string) => Promise<boolean | undefined>
  handleShowTeamSubmit: () => void
  handlePlayerSwapClick: () => void
  handleWeekChange: (week: number) => void
  playerCardShow: (playerId?: string | null) => void
  refreshFantasyUser: () => Promise<void>
  handleShowModal: (modal: string) => void
}

export const FantasyContext = createContext<FantasyContextType | null>(
  null
)

export const FantasyProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  let { isLoggedIn, uid, login, isLoading } = useContext(AuthContext)!
  const { isStorageReady, store, getStoredData, deleteStoredData } =
    useContext(StorageContext)!
  let { onWeekDropdownChange, activeWeek, seasonSelect } =
    useContext(SeasonContext)!
  const [currentWeek, setCurrentWeek] = useState<number>(
    findCurrentWeekByDate()
  )
  const [fantasyConfig, setFantasyConfig] =
    useState<FantasyConfig | null>(null)
  const [fantasyUser, setFantasyUser] = useState<FantasyUser | null>(
    null
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [isPlayersLoading, setIsPlayersLoading] =
    useState<boolean>(true)
  const [playerCardLoading, setPlayerCardLoading] =
    useState<boolean>(true)
  const [showPlayerSwap, setShowPlayerSwap] = useState<boolean>(false)
  const [compiledPicks, setCompiledPicks] = useState<CompiledPicksType>(
    defaultCompiledPicks
  )
  const [teamHasChanged, setTeamHasChanged] = useState<boolean>(false)
  const [showPlayerCard, setShowPlayerCard] = useState<boolean>(false)
  const [playerCardPlayer, setPlayerCardPlayer] = useState<
    FantasyPlayer | undefined
  >()
  const [playerCardId, setPlayerCardId] = useState<string | null>(null)
  const [playerSwapPosition, setPlayerSwapPosition] = useState<string>()
  const [allPlayers, setAllPlayers] = useState<FantasyPlayer[]>([])
  const [projPoints, setProjPoints] = useState<string>('0')
  const [maxCap, setMaxCap] = useState<number>(FANTASY_SALARY_CAP)
  const [capLeft, setCapLeft] = useState<number | null>(null)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarData, setSnackbarData] = useState<SnackbarData | null>(
    null
  )
  const [showTeamNameSubmit, setShowTeamNameSubmit] =
    useState<boolean>(false)
  const [teamName, setTeamName] = useState<string | null>(
    fantasyUser ? fantasyUser.teamName : null
  )
  const [isFetchingPlayers, setIsFetchingPlayers] =
    useState<boolean>(false)
  const [showTeamOptions, setShowTeamOptions] = useState<boolean>(false)
  const [weekPosTypes, setWeekPosTypes] = useState<
    FantasyPositionTypes[]
  >([])
  const [posCount, setPosCount] = useState<{
    [key: string]: number
  } | null>(null)
  const [showModal, setShowModal] = useState<{
    [key: string]: {
      show: boolean
      modalTitle: string
    }
  }>({
    generateTeam: { show: false, modalTitle: 'generateTeam' },
    prize: { show: false, modalTitle: 'prize' },
    groupActions: { show: false, modalTitle: 'groupActions' },
  })

  useEffect(() => {
    getFantasyConfig()
  }, [isStorageReady])

  useEffect(() => {
    getWeekPosTypes()
    if (isLoading) return

    if (!isLoggedIn) {
      getAllFantasyPlayers()
      setLoading(false)
      return
    }
    getMaxSalaryCap()
    Promise.all([getFantasyUser(), getAllFantasyPlayers()])
  }, [activeWeek, isLoading])

  useEffect(() => {
    if (playerCardId !== null) {
      getSingleFantasyPlayer()
    }
    return
  }, [playerCardId])

  const getWeekPosTypes = () => {
    if (!fantasyConfig?.fantasyWeeks) return
    let week = fantasyConfig.fantasyWeeks.find(
      (week) => week.week === activeWeek
    )
    if (!week) return
    setPosCount(week.playerCount)
    let posTypes: FantasyPositionTypes[] = []
    Object.keys(week.playerCount).forEach((pos) => {
      let type = fantasyPositionTypes.find(
        (posType) => posType.label.toLowerCase() === pos
      )
      if (type) {
        posTypes.push(type)
      }
    })
    posTypes = sortPositionFilters(posTypes)
    setWeekPosTypes([{ label: 'All', value: 'All' }, ...posTypes])
  }

  const handleShowTeamSubmit = () => {
    return setShowTeamNameSubmit(!showTeamNameSubmit)
  }

  const handleShowModal = (modal: string) => {
    setShowModal({
      ...showModal,
      [modal]: {
        show: !showModal[modal].show,
        modalTitle: showModal[modal].modalTitle,
      },
    })
  }

  const handleWeekChange = (week: number) => {
    onWeekDropdownChange(week)
    setCurrentWeek(week)
    setLoading(true)

    logEvent(ANALYTICS_TAGS.fantasy_week_select, {
      week: week,
    })
  }

  const getFantasyConfig = async () => {
    getCachedConfig()
    const config = await fetchFantasyConfig()
    if (config?.fantasyConfig) {
      setupFantasyConfig(config.fantasyConfig)
      store(STORAGE_NAMES.config_fantasy, config)
    }
  }

  const setupFantasyConfig = (fantasyConfig: FantasyConfig) => {
    setFantasyConfig(fantasyConfig)
    const currentWeek = findCurrentWeekByDate(fantasyConfig?.weeks)
    setCurrentWeek(currentWeek)

    if (fantasyConfig?.maxCap === FANTASY_SALARY_CAP) return
    setMaxCap(fantasyConfig.maxCap)
    setCapLeft(fantasyConfig.maxCap)
  }

  const getCachedConfig = async (): Promise<void> => {
    const storedConfig = await getStoredData(
      STORAGE_NAMES.config_fantasy
    )
    if (storedConfig?.data) {
      setupFantasyConfig(storedConfig.data)
    }
  }

  const getFantasyUser = async () => {
    if (!uid) {
      setLoading(false)
      return
    }
    const usr = await fetchFantasyUser(
      uid,
      currentWeek,
      SEASON,
      seasonSelect.seasonType
    )
    if (!usr) return setLoading(false)
    usr?.teamName && setTeamName(usr.teamName)
    setFantasyUser(usr)
    setupPicksForWeek(usr)
    setLoading(false)
  }

  const getAllFantasyPlayers = async () => {
    if (isFetchingPlayers) return
    const { data, refetch } = await getStoredFantasyPlayers()
    if (data && !refetch) return

    if (!data) {
      setIsFetchingPlayers(true)
      setIsPlayersLoading(true)
    }
    try {
      const allFantasyPlayers = await fetchAllFantasyPlayers(
        SEASON,
        currentWeek
      )
      if (allFantasyPlayers.length < 1) {
        setIsPlayersLoading(false)
        setIsFetchingPlayers(false)
        setAllPlayers([])
        deleteStoredData(`${STORAGE_NAMES.fantasy_all_players}`)
        return []
      }

      setAllPlayers(allFantasyPlayers)
      setIsFetchingPlayers(false)
      setIsPlayersLoading(false)
      store(`${STORAGE_NAMES.fantasy_all_players}`, allFantasyPlayers)
      return
    } catch (err) {
      setIsFetchingPlayers(false)
      setIsPlayersLoading(false)
      setAllPlayers([])
      deleteStoredData(`${STORAGE_NAMES.fantasy_all_players}`)
      return err
    }
  }

  const getStoredFantasyPlayers = async (): Promise<{
    data: any
    refetch: boolean
  }> => {
    const storedData = await getStoredData(
      `${STORAGE_NAMES.fantasy_all_players}`
    )
    if (storedData?.data && storedData.data.length > 0) {
      setAllPlayers(storedData.data)
      setIsPlayersLoading(false)
    }
    return storedData
  }

  const submitTeamName = async (teamName: string) => {
    if (!uid) {
      login()
      return false
    }
    const data = {
      userId: uid,
      teamName: teamName,
    }
    let user = await updateFantasyUser(uid, data)

    if (!user) {
      toggleSnackbar({
        text: 'Unable to update team name.',
        color: 'red',
      })

      return false
    }

    logEvent(ANALYTICS_TAGS.fantasy_create_team, {
      teamName: teamName,
    })

    setTeamName(teamName)
    const newFantUser = {
      ...fantasyUser,
      teamName: teamName,
    } as FantasyUser
    if (fantasyUser) {
      setFantasyUser(newFantUser)
    }
    return true
  }

  const setupPicksForWeek = (fanUser: FantasyUser) => {
    if (!fanUser) return
    let picks: CompiledPicksType = getPickedPlayers(fanUser)
    getProjPoints(picks)
    setCompiledPicks(picks)

    let updatedCap = getCapLeft(maxCap, picks)
    setCapLeft(updatedCap)
  }

  const removePick = (position: string, player?: FantasyPlayer) => {
    if (!player || hasGameStarted(player, activeWeek)) return

    let currentPicks: CompiledPicksType = { ...compiledPicks }

    currentPicks[position] = currentPicks[position].filter(
      (pl: FantasyPlayer) =>
        pl.fantasyInfo.fantasyPlayerInfoId !==
        player.fantasyInfo.fantasyPlayerInfoId
    )
    setCompiledPicks(currentPicks)

    //Update cap based on current picks
    let updatedCap = getCapLeft(maxCap, currentPicks)
    setCapLeft(updatedCap)
    getProjPoints(currentPicks)
    setTeamHasChanged(true)
    logEvent(ANALYTICS_TAGS.fantasy_player_drop, {
      player_id: player.officialId,
      player_name: `${player?.firstName} ${player?.lastName}`,
      player_team: player.currentTeam?.fullName,
      player_fantasy_game_id: player.fantasyInfo?.fantasyPlayerInfoId,
      player_position: player.fantasyInfo?.position,
    })
  }

  const addPick = (position: string, player?: FantasyPlayer) => {
    if (!player) return

    let currentPicks: CompiledPicksType = { ...compiledPicks }
    const positionPicks =
      position in currentPicks ? currentPicks[position] : []
    let foundPlayer = positionPicks.find(
      (pl: FantasyPlayer) =>
        pl.fantasyInfo?.fantasyPlayerInfoId ===
        player.fantasyInfo?.fantasyPlayerInfoId
    )
    if (foundPlayer) return //Player already in lineup

    if (checkRules(position, player, compiledPicks, posCount)) {
      currentPicks[position].push(player)

      setCompiledPicks({ ...currentPicks })

      logEvent(ANALYTICS_TAGS.fantasy_player_add, {
        player_name: `${player.firstName} ${player.lastName}`,
        player_id: player.officialId,
        player_fantasy_game_id: player.fantasyInfo?.fantasyPlayerInfoId,
        player_team: player.currentTeam?.fullName,
        player_position: player.fantasyInfo?.position,
      })

      //Update cap based on current picks
      let updatedCap = getCapLeft(maxCap, currentPicks)
      setCapLeft(updatedCap)
      getProjPoints(currentPicks)
      setTeamHasChanged(true)
    } else {
      console.log('Player cannot be added')
    }
  }

  const checkSubmitTeamRules = (
    picks: CompiledPicksType,
    posCount: number
  ) => {
    let countPlayersOnTeam = checkPlayerCount(picks)
    if (countPlayersOnTeam === 0) {
      //No players selected, return
      toggleSnackbar({
        text: 'No players in lineup',
        color: 'red',
      })
      return false
    }

    if (countPlayersOnTeam < posCount) {
      //Does not have all players, return
      toggleSnackbar({
        text: `Need exactly ${posCount} players on team`,
        color: 'red',
      })
      return false
    }
    let underCap = getCapLeft(maxCap, compiledPicks) >= 0
    if (!underCap) {
      //Not under cap, return
      toggleSnackbar({
        text: 'You are over the salary cap.',
        color: 'red',
      })
      console.log('Not under cap')
      return false
    }
    return true
  }

  const checkRules = (
    position: string,
    player: FantasyPlayer,
    compiledPicks: CompiledPicksType,
    posCount: { [key: string]: number } | null
  ) => {
    if (!checkLimits(position, compiledPicks, posCount)) {
      toggleSnackbar({
        text: 'Drop a player before adding another.',
        color: 'red',
      })
      return false
    }

    if (hasGameStarted(player, activeWeek)) {
      toggleSnackbar({
        text: `Player's game has already started.`,
        color: 'red',
      })
      return false
    }

    return true
  }

  const actionButtonClick = (
    type: string,
    position: string,
    player?: FantasyPlayer
  ) => {
    logEvent(ANALYTICS_TAGS.fantasy_action_button_click, {
      player_id: player && player.officialId,
      button_type: type,
      is_logged_in: isLoggedIn,
    })
    if (type === 'arrow') {
      logFirEvent(ANALYTICS_TAGS.fantasy_player_click, {
        week: currentWeek,
        player: player?.officialId,
      })
      return player ? playerCardShow(player.officialId) : null
    }

    switch (type) {
      case 'add':
        return addPick(position, player)
      case 'drop':
        return removePick(position, player)
      case 'swap':
        return handlePlayerSwapClick(position)
      default:
        return handlePlayerSwapClick(position)
    }
  }

  const getSingleFantasyPlayer = async () => {
    try {
      const singlePlayer = await fetchFantasyPlayer(
        playerCardId,
        SEASON,
        currentWeek,
        seasonSelect.seasonType
      )

      if (!singlePlayer) return null

      singlePlayer.allEvents.reverse()
      setPlayerCardPlayer(singlePlayer)
      setPlayerCardLoading(false)
      return
    } catch (err) {
      console.log(err)
      setPlayerCardLoading(false)
      return err
    }
  }

  const submitTeam = async () => {
    let currentPicks: CompiledPicksType = { ...compiledPicks }
    let picks: number[] = []
    if (!fantasyUser) {
      login()
      return Promise.resolve(false)
    }
    if (!teamName) {
      handleShowTeamSubmit()
      return Promise.resolve(false)
    }

    if (
      !checkSubmitTeamRules(
        currentPicks,
        reduceTotalNeededPlayers(posCount)
      )
    ) {
      //Did not meet rules
      console.log('did not meet rules')
      return Promise.resolve(false)
    }

    let picksArray = getPicksArray(currentPicks)

    picksArray.map((pick: FantasyPlayer) => {
      picks.push(pick.fantasyInfo.fantasyPlayerInfoId)
    })

    let success = await submitFantasyTeam(
      fantasyUser.firebaseId,
      currentWeek,
      picks
    )
    if (!success) {
      //Show error snackbar
      toggleSnackbar({
        text: 'There was an error submitting lineup.',
        color: 'red',
      })
      return false
    }
    setTeamHasChanged(false)
    logFirEvent(ANALYTICS_TAGS.fantasy_team_submit, {
      //firebase only
      week: currentWeek,
      players: compiledPicks,
      cap: maxCap - (capLeft ?? 0), //combined value of all players
    })

    toggleSnackbar({
      text: 'Success! Team has been saved.',
      color: 'green',
    })

    return true
  }

  const clearTeam = async () => {
    let currentPicks: CompiledPicksType = { ...compiledPicks }
    logEvent(ANALYTICS_TAGS.fantasy_clear_click, { week: currentWeek })

    if (teamHasPlayerWithGameStarted(currentPicks, activeWeek)) {
      //At least one game has already started
      toggleSnackbar({
        text: "At least one player's game has already started",
        color: 'red',
      })
      console.log('At least one game has already started')
      return Promise.resolve(false)
    }

    if (fantasyUser?.firebaseId) {
      //Only send to backend if user is logged in
      let success = await submitFantasyTeam(
        fantasyUser.firebaseId,
        currentWeek,
        []
      )
      if (!success) {
        //Show error snackbar
        toggleSnackbar({
          text: 'There was an error clearing lineup.',
          color: 'red',
        })
        console.log('Error submitting pick')

        return false
      }
    }

    Object.keys(currentPicks).forEach((pick) => {
      currentPicks[pick].length = 0
    })

    setCapLeft(fantasyConfig?.maxCap || FANTASY_SALARY_CAP)
    setCompiledPicks({ ...currentPicks })
    getProjPoints(currentPicks)
    setTeamHasChanged(false)

    //Successful clear
    logEvent(ANALYTICS_TAGS.fantasy_team_clear, {
      week: currentWeek,
    })
    return true
  }

  const getMaxSalaryCap = () => {
    let week = fantasyConfig?.fantasyWeeks.find(
      (w) => w.week === currentWeek
    )
    setMaxCap(week ? week.maxCap : FANTASY_SALARY_CAP)
  }

  /**
   * Sums up a fantasy user's total week projected points accounting for players that are currently playing
   */
  const getProjPoints = (picks: CompiledPicksType) => {
    let joinedArrays = getPicksArray(picks)
    const sum = joinedArrays.reduce(
      (acc, pick) => acc + selectProjectedPoints(pick, currentWeek),
      0
    )

    return setProjPoints(sum.toFixed(2))
  }

  const checkLimits = (
    position: string,
    compiledPicks: CompiledPicksType,
    posCount: { [key: string]: number } | null
  ) => {
    if (!posCount) return false
    const posGroup = compiledPicks[position]
    const longPosName = getPositionLong(position)
    const foundPosCount = longPosName
      ? posCount[longPosName.toLocaleLowerCase()]
      : null
    return foundPosCount ? posGroup.length < foundPosCount : false
  }

  const handlePlayerSwapClick = (position?: string) => {
    setShowPlayerSwap(!showPlayerSwap)
    position
      ? setPlayerSwapPosition(position)
      : setPlayerSwapPosition(undefined)
  }
  const toggleSnackbar = (message: SnackbarData) => {
    setSnackbarData(message)
    setShowSnackbar(true)
    //show for only 3 seconds
    setTimeout(() => {
      setShowSnackbar(false)
      setSnackbarData(null)
    }, 3000)
  }

  const playerCardShow = (playerId?: string | null) => {
    if (playerId) {
      setPlayerCardId(playerId)
    } else {
      setPlayerCardId(null)
      setPlayerCardPlayer(undefined)
    }

    setPlayerCardLoading(true)
    return setShowPlayerCard(!showPlayerCard)
  }

  const handleTeamOptionsShow = () => {
    setShowTeamOptions(!showTeamOptions)
  }

  return (
    <FantasyContext.Provider
      value={{
        currentWeek,
        fantasyConfig,
        fantasyUser,
        loading,
        isPlayersLoading,
        playerCardLoading,
        capLeft,
        maxCap,
        showModal,
        showPlayerSwap,
        compiledPicks,
        teamHasChanged,
        showPlayerCard,
        playerCardPlayer,
        playerSwapPosition,
        allPlayers,
        projPoints,
        showSnackbar,
        snackbarData,
        showTeamNameSubmit,
        teamName,
        showTeamOptions,
        weekPosTypes,
        handleTeamOptionsShow,
        removePick,
        actionButtonClick,
        addPick,
        getProjPoints,
        submitTeam,
        clearTeam,
        toggleSnackbar,
        submitTeamName,
        handleShowTeamSubmit,
        handlePlayerSwapClick,
        handleWeekChange,
        playerCardShow,
        refreshFantasyUser: getFantasyUser,
        handleShowModal,
      }}
    >
      {children}
      {showSnackbar && snackbarData && (
        <GeneralSnackbar
          text={snackbarData.text}
          color={snackbarData.color}
          show={showSnackbar}
        />
      )}
    </FantasyContext.Provider>
  )
}
