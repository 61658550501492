import React from 'react'
import {
  MORE_MENU,
  PLL_LINKS,
  TEAM_MENU,
} from '../../../Configs/config'
import { SubMenu } from './SubMenu'
import { createShopUTM } from '../../../Utils'

type MenuProps = {
  isMobile: boolean
}

export const NavList: React.FC<MenuProps> = ({ isMobile }) => {
  return (
    <>
      <SubMenu menuText="Teams ▾" options={TEAM_MENU}></SubMenu>
      <li>
        <a href={PLL_LINKS.schedule}>Schedule</a>
      </li>
      <li>
        <a href={PLL_LINKS.standings}>Standings</a>
      </li>
      <li>
        <a href={PLL_LINKS.stats}>Stats</a>
      </li>
      <li>
        <a href={PLL_LINKS.academy}>Academy</a>
      </li>
      <li>
        <a
          href={createShopUTM({
            medium: `F2P_NavBar`,
            campaign: 'shop_nav_item',
          })}
        >
          Shop
        </a>
      </li>
      <li>
        <a href={PLL_LINKS.fantasy}>Fantasy</a>
      </li>
      <li>
        <a href={'/'}>PLL Nation</a>
      </li>
      {isMobile && (
        <SubMenu menuText="More ▾" options={MORE_MENU}></SubMenu>
      )}
    </>
  )
}
