import React, { useEffect } from 'react'
import { Footer } from './footer'
import { useAdContext } from '../../Context'
import { AD_OPTIONS } from '../../Configs/Ads/ads'
import { AdUnit } from '../Ads/AdUnit'

type FooterParams = {
  showPLLNav?: boolean
  showFixedAd: boolean
  zIndex?: number
}

export const FooterComponent: React.FC<FooterParams> = ({
  showPLLNav = true,
  showFixedAd,
  zIndex
}) => {
  const { canShowAds, showRampAdsForPage } = useAdContext()
  useEffect(() => {
    if (!canShowAds || !showFixedAd) return
    showRampAdsForPage([AD_OPTIONS.bannerSecond])
  }, [canShowAds])
  
  return (
    <>
      {showFixedAd && <AdUnit bannerType="bannerSecond" bottomFixed zIndex={zIndex}  />}
      {showPLLNav && <Footer />}
    </>
  )
}
