import { SlideUpContainer } from './styles'

type SlideUpParams = {
  children: React.ReactNode
  visible: boolean
  height: string
  fullWidth?: boolean
  noBackground?: boolean
  showClose?: boolean
  width?: string
  handleClose?: () => void
}

export const SlideUp: React.FC<SlideUpParams> = ({
  children,
  height,
  visible = false,
  noBackground = false,
  showClose = false,
  width = '100%',
  handleClose,
}) => {
  return (
    <SlideUpContainer
      visible={visible}
      height={height}
      width={width}
      noBackground={noBackground}
    >
      {showClose && handleClose && (
        <div className="closeX" onClick={() => handleClose()}>
          X
        </div>
      )}
      {children}
    </SlideUpContainer>
  )
}
