import { styled } from '@mui/system'
import { colors } from '../consts'
type StyleProps = {
  color: 'red' | 'blue' | 'green'
}

export const SnackbarCon = styled('div')<StyleProps>(({ color, theme }) => ({
  display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background:
      color === 'red'
        ? `${colors.red}90`
        : color === 'blue'
        ? `${colors.lightBlue}90`
        : `${colors.green.green}80`,
    width: '50vw',
    margin: theme.spacing(1, 0),
    borderRadius: 10,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      padding: theme.spacing(0.5, 2),
    },
    zIndex: 99999,
    '& p': {
      fontSize: '1rem',
      fontWeight: '700',
      color: colors.white,
    },
}))