import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import { AuthContext } from '../../Api/firebase'
import { submitPick, fetchWeeklyPicks } from '../../Api'
import {
  logEvent,
  checkSinglePickEnabled,
  calcPickemWeekScore,
} from '../../Utils'
import type { Event, Pick, User } from '../../Api'
import { SeasonContext } from '../SeasonContext'
import {
  SnackbarData,
  WeekPickEvent,
} from '../../Api/types/picks.types'
import { PickemSnackbar } from '../../Components/Snackbar/PickemSnackbar'
import {
  filterForWeekEvents,
  TeamFrontData,
  teamsDataArray,
} from '../../Utils'
import { logFirEvent } from '../../Utils/analytics/firAnalytics'

type PickemProviderProps = {
  userId?: string
  children?: React.ReactNode
}
type PickemContextType = {
  user: User | null
  weekList: number[]
  events: Event[]
  teamList: TeamFrontData[]
  pickemSelections: Pick[]
  score: number
  showModal: boolean
  showTutorial: boolean
  showSnackbar: boolean
  handlePick: (pick: Pick) => Promise<boolean>
  handleWeekChange: (week: number) => void
  toggleModal: () => void
  toggleTutorial: () => void
  toggleSnackbar: (message: SnackbarData) => void
  getScore: () => number
  handleSavePick: (pick: Pick) => Promise<boolean>
}

export const PickemContext = createContext<PickemContextType | null>(
  null
)

export const PickemProvider: React.FC<PickemProviderProps> = ({
  children,
}) => {
  const { uid, login, isLoggedIn, isAnonymous } =
    useContext(AuthContext)!
  const {
    activeWeek,
    events: seasonEvents,
    weekList,
    isEventsLoading,
    onWeekDropdownChange,
  } = useContext(SeasonContext)!
  const [user, setUser] = useState<User | null>(null)
  const [events, setEvents] = useState<Event[]>(seasonEvents)
  const [teamList, setTeamList] =
    useState<TeamFrontData[]>(teamsDataArray)
  const [pickemSelections, setPickemSelections] = useState<Pick[]>([])
  const [score, setScore] = useState<number>(0)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showTutorial, setShowTutorial] = useState<boolean>(false)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbarData, setSnackbarData] = useState<SnackbarData | null>(
    null
  )

  useEffect(() => {
    if (!uid || isEventsLoading) {
      //Either not logged in or just logged out
      setPickemSelections([])
      setUser(null)
    }
    if (seasonEvents.length > 1) {
      let wkEvents = filterForWeekEvents(seasonEvents, activeWeek)
      setEvents(wkEvents)
      if (wkEvents.length >= 1) {
        fetchPickemSelections(activeWeek, wkEvents)
      }
    }
  }, [uid])

  useEffect(() => {
    if (!uid || isEventsLoading) {
      //Either not logged in or just logged out
      setPickemSelections([])
      setUser(null)
    }
    if (seasonEvents.length > 1) {
      let wkEvents = filterForWeekEvents(seasonEvents, activeWeek)
      setEvents(wkEvents)
      if (wkEvents.length >= 1) {
        fetchPickemSelections(activeWeek, wkEvents)
      }
    }
  }, [activeWeek, seasonEvents, isEventsLoading])

  const fetchPickemSelections = async (wk: number, evs: Event[]) => {
    if (!uid) return

    const sels = await fetchWeeklyPicks(uid, wk)
    if (!sels) return
    if (sels?.picks) {
      setPickemSelections(sels.picks)
    }
    if (sels.weekEvents && sels.weekEvents.length > 0) {
      updateFanVotes(sels.weekEvents, evs)
    }
  }

  const updateFanVotes = (
    eventsVotes: WeekPickEvent[],
    currentEvents: Event[]
  ) => {
    if (eventsVotes.length > 0) {
      let evsWithVotes = currentEvents.map((ev) => {
        let fanVotes = eventsVotes.find((eVote) => eVote.id === ev.id)
        if (!fanVotes) return ev
        ev.fanResults = fanVotes.fanResults || []
        return ev
      })
      setEvents(evsWithVotes)
      return evsWithVotes
    }
    return []
  }

  const handleWeekChange = (week: number) => {
    onWeekDropdownChange(week)
    logEvent('pickem_week_change_click', {
      week: week,
    })
  }

  const getScore = () => {
    if (pickemSelections.length < 1) {
      return 0
    }
    const score = calcPickemWeekScore(pickemSelections)
    return score
  }

  const handlePick = async (pick: Pick) => {
    const eventPickEnabled = checkSinglePickEnabled(pick.id, events)
    if (!eventPickEnabled) {
      toggleSnackbar({
        text: 'Pick is locked',
        color: 'red',
      })
      return false
    } else {
      if (!uid || isAnonymous || !isLoggedIn) {
        login()
        return false
      }
      let prevSels = [...pickemSelections]
      let userSelections = [...pickemSelections]
      const prevSelection = userSelections.find(
        (sel) => sel.id === pick.id
      )
      if (pickemSelections.length < 1 || !prevSelection) {
        userSelections.push(pick)
      } else {
        const idx = userSelections.findIndex(
          (sel) => sel.id === pick.id
        )
        userSelections[idx].pickedTeam = pick.pickedTeam
      }
      setPickemSelections(userSelections)
      let success = await handleSavePick(pick)
      if (!success) {
        //revert back
        setPickemSelections(prevSels)
      }
      return success
    }
  }

  const handleSavePick = async (pick: Pick) => {
    if (!uid || isAnonymous) {
      toggleSnackbar({
        text: 'Must be logged in to pick.',
        color: 'red',
      })
      Promise.reject()
      return false
    }
    let mutatedPick = {
      userId: uid,
      id: pick.id,
      week: pick.week || activeWeek,
      year: new Date().getFullYear(),
      teamId: pick.pickedTeam,
    }
    try {
      const pickRes = await submitPick(mutatedPick)
      if (!pickRes) {
        //Error, retract added pick
        toggleSnackbar({
          text: 'Something went wrong saving pick, try again.',
          color: 'red',
        })
        return false
      }

      //update newly fetched fan breakdown
      updateFanVotesInState(pickRes)

      // // If the user was referred, add to referrers document
      logFirEvent('pickem_submit', {
        week: activeWeek,
        user_id: uid,
        pickedTeam: pick.pickedTeam,
      })
      return true
    } catch (e) {
      toggleSnackbar({
        text: 'Something went wrong saving pick, try again.',
        color: 'red',
      })
      console.error(e)
      return false
    }
  }

  const updateFanVotesInState = (pick: Pick) => {
    let evList = [...events]
    if (evList.length < 1) return
    let evToUpdate = evList.find((ev) => ev.id === pick.id)
    if (evToUpdate && pick?.fanResults && pick.fanResults.length > 0) {
      evToUpdate.fanResults = pick.fanResults
      let idx = events.findIndex((ev) => ev.id === pick.id)
      if (idx > -1) {
        evList[idx] = evToUpdate
        setEvents(evList)
      }
    }
    return
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const toggleTutorial = () => {
    setShowTutorial(!showTutorial)
  }

  const toggleSnackbar = (message: SnackbarData) => {
    setSnackbarData(message)
    setShowSnackbar(true)
    //show for only 3 seconds
    setTimeout(() => {
      setShowSnackbar(false)
      setSnackbarData(null)
    }, 3000)
  }

  return (
    <PickemContext.Provider
      value={{
        user: user,
        weekList: weekList,
        events: events,
        teamList: teamList,
        pickemSelections: pickemSelections,
        score: score,
        showModal: showModal,
        showTutorial: showTutorial,
        showSnackbar: showSnackbar,
        handlePick: handlePick,
        handleWeekChange: handleWeekChange,
        toggleModal: toggleModal,
        toggleTutorial: toggleTutorial,
        toggleSnackbar: toggleSnackbar,
        getScore: getScore,
        handleSavePick: handleSavePick,
      }}
    >
      {children}
      {showSnackbar && snackbarData && (
        <PickemSnackbar
          text={snackbarData.text}
          color={snackbarData.color}
        />
      )}
    </PickemContext.Provider>
  )
}
