import { callGraphQL } from '../utils/request'
import {
  fetchFantasyPlayerQuery,
  fetchAllPlayers,
} from './player.queries'
import { FantasyPlayer, PlayerEventRes } from '../Fantasy/fantasy.types'

export async function fetchAllFantasyPlayers(
  season: number,
  week: number
): Promise<FantasyPlayer[]> {
  try {
    const playersRes = await callGraphQL<{
      allPlayerFantasy: FantasyPlayer[]
    }>(fetchAllPlayers, {
      season,
      week,
    })
    if (!playersRes?.allPlayerFantasy) return []
    return playersRes.allPlayerFantasy
  } catch (err) {
    console.error('Trouble getting all fantasy players', err)
    return []
  }
}

export async function fetchFantasyPlayer(
  id: string | null,
  year: number,
  week: number | null,
  segment: string
): Promise<FantasyPlayer | null> {
  let seg = segment === 'champseries' ? 'event' : segment
  try {
    const playersRes = await callGraphQL<PlayerEventRes>(
      fetchFantasyPlayerQuery,
      {
        id,
        year,
        week,
        segment: seg,
        includeCS: segment === 'champseries'
      }
    )
    if (!playersRes?.player) return null
    return playersRes.player
  } catch (err) {
    return null
  }
}
