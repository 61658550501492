import React, { useState } from 'react'
import { NavActionCon, NavbarCon } from './styles'
import { SubMenu } from './SubMenu'
import { NavToggle } from '../NavToggle/NavToggle'
import { NavItems } from './NavItems'
import { createShopUTM } from '../../../Utils'
import PLL_MAIN_LOGO from '../../../images/PLL_navbar_logo.png'
import {
  MAIN_PLL_HOME,
  PLL_LINKS,
  MORE_MENU,
} from '../../../Configs/config'
import { PLLN_URL } from '../../../Routing/urls'
import { NavList } from './NavList'
import { AccountCircleOutlined } from '../../LazyComponents/lazyIcons'

export const SiteNavbar: React.FC = () => {
  const [open, setOpen] = useState(false)
  const getMainButton = () => {
    // Change main nav call to action based on day of week
    const day = new Date().getDay()
    if (day === 0 || day > 3) {
      return (
        <NavActionCon href={PLL_LINKS.watchLink}>
          <p>Watch</p>
        </NavActionCon>
      )
    } else {
      return (
        <NavActionCon
          href={createShopUTM({
            slug: '',
            medium: `F2P_NavBar`,
            campaign: 'main_shop_button',
          })}
        >
          <p>Shop</p>
        </NavActionCon>
      )
    }
  }

  return (
    <NavbarCon>
      <div className="brand">
        <a href={MAIN_PLL_HOME}>
          <img src={PLL_MAIN_LOGO} alt="" />
        </a>
      </div>
      <nav className="navMain">
        <div className="navMobile">
          <div className="toggleContainer">
            <NavToggle open={open} setOpen={setOpen} />
          </div>
          <div className="brand">
            <a href={MAIN_PLL_HOME}>
              <img src={PLL_MAIN_LOGO} alt="" />
            </a>
          </div>
          <NavItems open={open} />
        </div>
        <ul className="navList">
          <NavList isMobile={false} />
          <SubMenu menuText="More ▾" options={MORE_MENU}></SubMenu>
        </ul>
      </nav>
      <div className="rightCol">
        {getMainButton()}
        <a className="navProfile" href={PLLN_URL}>
          <AccountCircleOutlined
            color="inherit"
            style={{ color: 'fff', fontSize: 40 }}
          />
        </a>
      </div>
    </NavbarCon>
  )
}
