import { PLAYER_STARS_URL } from "../../Routing/urls"
import { MAIN_PLL_HOME } from "../config"

export const DEFAULT_PLAYER_STARS_COUNT = 7
export const DEFAULT_UNCLAIMED_PLAYER = `${MAIN_PLL_HOME}/wp-content/uploads/2023/09/BackCard.webp`
export const PLAYER_STARS_HEADER_DATA = {
  title: 'PLL Cash App Playoff Stars',
  link: PLAYER_STARS_URL,
  desc: true,
}
export const PLAYER_STAR_ACTIONS = {
    reveal: 'reveal',
    claim: 'claim',
}