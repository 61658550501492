import {
  FantasyConfig,
  FantasyPlayer,
  FantasyPlayerInfo,
} from '../../Api/Fantasy/fantasy.types'
import { colors } from '../../Components/consts'
import { getPositionLong } from './fantasyHelpers'

export type FantasyPlayerSortKeys = keyof FantasyPlayerInfo

/**
Sorts fantasy players by first week points, then projected points
*/
export const sortFantasyPlayers = (
  list: FantasyPlayer[],
  week: number,
  ascDesc: -1 | 1
) => {
  return list.sort((a, b) => {
    let A = 0,
      B = 0,
      A2 = 0,
      B2 = 0
    if (a.fantasyInfo?.seasonWeeklyPts) {
      //Weekly points
      A = getFantasyPointsForWeek(a, week)
      B = getFantasyPointsForWeek(b, week)
    }

    if (a.fantasyInfo) {
      //Projected points
      A2 = a.fantasyInfo?.projectedPoints || 0
      B2 = b.fantasyInfo?.projectedPoints || 0
    }
    if (ascDesc < 0) {
      return A - B || A2 - B2
    } else {
      return B - A || B2 - A2
    }
  })
}

export const getFantasyPointsForWeek = (
  plr: FantasyPlayer,
  week: number
) => {
  if (plr?.fantasyInfo?.seasonWeeklyPts) {
    let weekObj = plr.fantasyInfo?.seasonWeeklyPts?.find(
      (f) => f.week === week
    )
    return weekObj?.totalPts || 0
  }
  return 0
}

/**
 * Used when calculating fantasy user's total. Show projected points or realtime projected points if game is live
 */
export const selectProjectedPoints = (
  player: FantasyPlayer,
  week: number
) => {
  const currentlyPlaying = isGameLive(player, week)
  if (currentlyPlaying) {
    //Live updating as game goes
    return player?.fantasyInfo?.realtimeProjectedPoints || 0.0
  }
  if (player.currentGame?.eventStatus > 1) {
    //Game ended, use final points
    return player.fantasyInfo?.weekPts || 0.0
  }

  //Game hasn't started yet, use projected points
  return player.fantasyInfo?.projectedPoints || 0.0
}

/**
 * Show projected points or realtime projected points if game is live
 */
export const showProjectedPoints = (
  player: FantasyPlayer,
  week: number
) => {
  const currentlyPlaying = isGameLive(player, week)
  if (currentlyPlaying) {
    //Live updating as game goes
    return player?.fantasyInfo?.realtimeProjectedPoints || 0.0
  }
  return player.fantasyInfo?.projectedPoints || '-'
}

export const getFantasyPlayerCount = (
  position: string,
  fantasyConfig: FantasyConfig | null,
  currentWeek: number
) => {
  let week = fantasyConfig?.fantasyWeeks.find(
    (week) => week.week === currentWeek
  )
  let posLong = getPositionLong(position)
  if (posLong) {
    let posCount = week?.playerCount[posLong.toLowerCase()]
    return posCount || 0
  }
  return 0
}

/**
 * Checking if player's game is currently live
 */
export const isGameLive = (player: FantasyPlayer, week: number) => {
  let now = Math.round(Date.now() / 1000)
  const currentGame = player?.currentGame
  if (!currentGame || currentGame.week > week) return false
  if (week === 0) {
    //Champ Series
    const playedGames = player.allEvents.find(
      (ev) =>
        ev.week === week.toString() &&
        (ev.startTime < now || ev.eventStatus > 0)
    )
    return playedGames ? true : false
  }
  if (currentGame.startTime < now && currentGame.eventStatus < 2) {
    return true //Game is live
  }
  return false
}

/**
If there is a minimum projected points, check if the player is over that amount to qualify
*/
export const isOverMinimumProjPts = (
  player: FantasyPlayer,
  minProjectedPts: number
) => {
  const proj = player.fantasyInfo?.projectedPoints || 0
  return proj >= minProjectedPts
}

/**
If there is a minimum projected points, check if the player is over that amount to qualify
*/
export const isOverMinimumSalary = (
  player: FantasyPlayer,
  minSalary: number
) => {
  const sal = player.fantasyInfo?.salary || 0
  return sal >= minSalary
}

/**
Determins what color the fantasy and proj points should be
*/
export const getPointColors = (item: {
  week: number
  player: FantasyPlayer
  useColors: boolean
}) => {
  const hasLiveGame = isGameLive(item.player, item.week)
  let colorObj = {
    pointsColor: hasLiveGame === true ? colors.yellow : colors.white,
    projColor: colors.gray.gray40,
  }
  if (!hasLiveGame || !item.useColors) return colorObj

  const projectedPts = item.player.fantasyInfo?.projectedPoints || 0
  const realtimeProjPts =
    item.player.fantasyInfo?.realtimeProjectedPoints || 0
  if (realtimeProjPts > projectedPts) {
    colorObj.projColor = colors.green?.lightGreen
  }
  if (realtimeProjPts < projectedPts) {
    colorObj.projColor = colors.red
  }

  return colorObj
}
