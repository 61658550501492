import { validateEmail } from '../../Components/Auth/authHelpers'

export const validateProfileEdits = (
  profileData: {
    [key: string]: any
  },
  requireEmail?: boolean
) => {
  let errors: { [key: string]: string } = {}

  //Email
  if (requireEmail && profileData?.alternateEmail) {
    const validated = validateEmail(profileData.alternateEmail)
    validated.errorMsg && (errors.alternateEmail = validated.errorMsg)
  }
  //Zip
  if (profileData?.zip && profileData.zip.length < 5) {
    //Factor in Canadian zip codes
    errors.zip = 'Please enter a valid zip code.'
  }

  return errors
}

export const validateBirthday = (
  year?: string | null,
  month?: string | null,
  day?: string | null
) => {
  //TODO: Clean up this function
  const currentYear = new Date().getFullYear()
  if (!year || !month || !day) {
    return 'Please enter a valid birthday.'
  }
  if (
    year.length !== 4 ||
    month.length < 1 ||
    month.length > 2 ||
    day.length > 2 ||
    day.length < 1
  ) {
    return 'Please enter a valid birthday.'
  }

  //Day/Month Checks
  if (parseInt(month) > 12 || parseInt(month) < 1) {
    return 'Please enter a valid birthday.'
  }

  if (parseInt(day) > 31 || parseInt(day) < 1) {
    return 'Please enter a valid birthday.'
  }

  //Check if birthday is in the future or over 80
  const parsedYear = parseInt(year)
  if (currentYear - parsedYear > 80 || currentYear - parsedYear < 8) {
    return 'Please enter a valid birthday.'
  }

  return ''
}
