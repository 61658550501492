import * as React from 'react'
import { Spinner } from '../Icons'
import { styled } from '@mui/system'
import { colors } from '../consts'
import { Desc } from '../Typography/Text.styles'

export const LoadingRoot = styled('div')(({ theme }) => ({
  '@global': {
    '@keyframes loading_fade_in': {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(1),
  '& > svg': {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
}))

type LoadingParams = {
  width?: number
  color?: string
  textColor?: string
}
export const Loading: React.FC<LoadingParams> = ({
  width = 30,
  color = colors.yellow,
  textColor = colors.text
}) => {
  return (
    <LoadingRoot>
      <Spinner color={color} width={width} />
      <Desc color={textColor}>Loading...</Desc>
    </LoadingRoot>
  )
}
