
import { callGraphQL } from '../utils/request'
import { getAllEvents, eventFanVotesByWeekQuery } from './events.queries'
import type { Event, FetchEvent } from '../index'

/**
 * Fetch user and all user related data
 */
 export async function fetchEventsByYear(
    season: number,
    includeCS: boolean = true
 ): Promise<Event[]> {
   const eventRes = await callGraphQL<FetchEvent>(getAllEvents, {
    season,
    includeCS
   })
   if(!eventRes?.seasonEvents) return []
    return eventRes.seasonEvents
 }


/**
 * Fetch just fan votes for each game in a given week
 */
export async function fetchEventFanVotesByWeek(
   year: number,
   week: number
): Promise<Event[]> {
  const eventRes = await callGraphQL<FetchEvent>(eventFanVotesByWeekQuery, {
   year,
   week: week.toString()
  })
  if(!eventRes?.seasonEvents) return []
   return eventRes.seasonEvents
}