export const isMobile = () => {
  let isMobile = false
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    isMobile = true
  }
  return isMobile
}

export const isEmbeddedWebView = () => {
  if (checkIfEmbededInNativeApp() || checkIfEmbededInPLLApp())
    return true
  return false
}

export const checkIfEmbededInNativeApp = () => {
  if (/WKWebView/.test(navigator.userAgent)) {
    // Running in a WKWebView iOS
    return true
  } else if (/WebView/.test(navigator.userAgent)) {
    // Running in a UIWebView on iOS (older versions) or Android
    return true
  } else if (checkIfEmbededInPLLApp()) {
    // Running in PLL App
    return true
  } else if (window && window?.top !== window) {
    // Last check, if window.top is not equal to window, then we are in an iframe
    return true
  } else {
    // Not in an iframe
    return false
  }
}

/**
 * Use user agent to check if running in PLL App
 */
export const checkIfEmbededInPLLApp = () => {
  if (/pllmobile/.test(navigator.userAgent)) {
    // Running in PLL App
    return true
  }
  if (/pllmobile_android/.test(navigator.userAgent)) {
    // Running in PLL App
    return true
  }
  if (/pllmobile_iOS/.test(navigator.userAgent)) {
    // Running in PLL App
    return true
  }
  return false
}

/**
 * Reset url after removing search param
 */
export const resetWindowLocationWithoutParams = (params: string[]) => {
  const currentUrl = window.location.href
  const updatedUrl = removeSearchParams(currentUrl, params)
  window.history.replaceState(
    { message: 'URL updated' },
    '',
    updatedUrl
  )
}

/**
 * Check permissions and remove unwanted parameters before copy
 */
export const copyLinkFunc = async (
  text: string | null,
  params: string[]
) => {
  if (!text) return false
  let mergedParamsToRm = [...params, 'fbct'] //always remove token
  const updated = removeSearchParams(text, mergedParamsToRm)
  try {
    await navigator.clipboard.writeText(updated)
    return true
  } catch (err) {
    const backupSuccess = fallbackCopyToClipboard(updated)
    return backupSuccess
  }
}

/**
 * Alternative to navigator.clipboard.writeText
 */
export const fallbackCopyToClipboard = (text: string) => {
  const textarea = document.createElement('textarea')
  textarea.value = text
  textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
  document.body.appendChild(textarea)
  textarea.focus()
  textarea.select()
  try {
    document.execCommand('copy')
    document.body.removeChild(textarea)
    return true
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
    document.body.removeChild(textarea)
    return false
  }
}

export const removeSearchParams = (lnk: string, params: string[]) => {
  if (params.length < 1) return lnk
  const url = new URL(lnk)
  params.forEach((param) => {
    url.searchParams.delete(param)
  })
  return url.toString()
}

export const checkStorageOptions = (): {
  indexedDB: boolean
  local: boolean
  session: boolean
  cookies: boolean
} => {
  const indexedDBEnabled = isIndexedDBAvailable()
  const localStorageEnabled = isLocalStorageEnabled()
  const sessionStorageEnabled = isSessionStorageEnabled()
  const cookiesEnabled = isCookiesEnabled()
  return {
    indexedDB: indexedDBEnabled,
    local: localStorageEnabled,
    session: sessionStorageEnabled,
    cookies: cookiesEnabled,
  }
}

export const isIndexedDBAvailable = (): boolean => {
  return typeof window !== 'undefined' && 'indexedDB' in window
}

export const isLocalStorageEnabled = (): boolean => {
  if (typeof window === 'undefined') {
    // Environment does not have window
    return false
  }
  try {
    const testKey = 'test'
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

export const isSessionStorageEnabled = (): boolean => {
  if (typeof window === 'undefined') {
    // Environment does not have window
    return false
  }
  try {
    const testKey = 'test'
    sessionStorage.setItem(testKey, testKey)
    sessionStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Checks if cookies are enabled in the user's browser.
 * @returns {boolean} True if cookies are enabled, false otherwise.
 */
export const isCookiesEnabled = (): boolean => {
  if (
    typeof window === 'undefined' ||
    typeof document === 'undefined'
  ) {
    return false
  }

  try {
    const testCookie = 'pll_test_cookie'
    document.cookie = `${testCookie}=1; SameSite=Strict;`
    const cookies = document.cookie
      .split(';')
      .map((cookie) => cookie.trim())
    const hasTestCookie = cookies.some((cookie) =>
      cookie.startsWith(`${testCookie}=`)
    )

    if (hasTestCookie) {
      // Delete test cookie, set to past
      document.cookie = `${testCookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
