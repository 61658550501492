import React, { useState, useEffect, useRef, memo } from 'react'
import { ImageWrapper } from './styles'
import { isEmbeddedWebView } from '../../Utils'

type ImgParams = {
  src: string
  alt: string
  className?: string
}

export const Img: React.FC<ImgParams> = memo(
  ({ src, alt, className }) => {
    const [loaded, setLoaded] = useState<boolean>(false)
    const imageRef = useRef<HTMLImageElement | null>(null)
    const [altText, setAltText] = useState<string | undefined>('')
    //Set no referrer policy for embedded webviews
    const referrerPolicy = isEmbeddedWebView()
      ? 'no-referrer'
      : 'strict-origin-when-cross-origin'

    useEffect(() => {
      let isMounted = true
      const observer = setupObserver()
      return () => {
        isMounted = false
        observer?.disconnect()
      }
    }, [src])

    const setupObserver = () => {
      const img = imageRef.current
      if (img?.complete) {
        loadImage(src) // If image is already loaded, load it directly
        return
      }
      if (img) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              loadImage(src)
              observer.disconnect()
            }
          },
          { rootMargin: '50px', threshold: 0.1 }
        )
        observer.observe(img)
        return observer
      }
      return
    }

    const loadImage = async (src: string) => {
      try {
        const res = await fetchImage(src)
        if (res) {
          if (imageRef.current) {
            imageRef.current.src = src
            setLoaded(true)
            setAltText(alt)
          }
        }
      } catch (err) {
        if (
          imageRef.current &&
          imageRef.current.getBoundingClientRect().top <
            window.innerHeight
        ) {
          imageRef.current.src = src
          setLoaded(true)
          setAltText(alt)
        }
      }
    }

    const fetchImage = async (
      src: string | null,
      maxRetries = 2,
      timeout = 8000
    ) => {
      if (!src || src.startsWith('data:')) return
      // data URIs are not supported by the Cache API, return early.

      if (typeof window === 'undefined' || !window.caches) {
        console.log(`Failed to load image, no cache available: ${src}`)
        return fetch(src).then((response) =>
          response.ok ? response.blob() : null
        )
      }

      let retries = 0
      const cache = await window.caches.open('image-cache')
      let response = await cache.match(src)

      while (!response && retries < maxRetries) {
        const controller = new AbortController()
        const fetchPromise = fetch(src, {
          signal: controller.signal,
        }).catch(console.log)
        const timeoutId = setTimeout(() => controller.abort(), timeout)

        try {
          const networkResponse = await fetchPromise
          if (networkResponse && networkResponse.ok) {
            await cache.put(src, networkResponse.clone())
            response = await cache.match(src)
          }
        } catch (err) {
          console.log(
            `Failed to load image ${src}, retrying (${
              retries + 1
            }/${maxRetries})`,
            err
          )
        } finally {
          clearTimeout(timeoutId)
        }
        retries++
      }

      if (!response) {
        // Remove from cache if it can't be loaded
        console.warn(
          `Failed to load and cache image after ${maxRetries} attempts: ${src}`
        )
        await cache.delete(src)
      }
      return response ? response.blob() : null
    }

    return (
      <ImageWrapper loaded={loaded}>
        <img
          ref={imageRef}
          src={loaded ? undefined : undefined}
          data-lazy={src}
          alt={loaded ? altText : ''}
          className={`lazy-image ${className} ${
            loaded ? 'loaded' : ''
          }`}
          referrerPolicy={referrerPolicy}
        />
      </ImageWrapper>
    )
  }
)
