import { styled } from '@mui/system'

type MenuProps = {
  open: boolean
}

export const NavToggleStyle = styled('button')<MenuProps>(({ open, theme }) => ({
  position: 'absolute',
  left: 5,
  top: 18,
  width: '1.5rem',
  height: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
  zIndex: 10,
  '&:focus': {
    outline: 'none',
  },
  '& span': {
    borderRadius: 0,
    height: '0.20rem',
    width: '1.48rem',
    background: '#000',
    position: 'relative',
    display: 'block',
    transition: 'all 300ms linear',
    transformOrigin: '1px',
    '& :first-of-type': {
      transform: open ? 'rotate(45deg)' : 'rotate(0)',
    },
    '&:nth-of-type(2)': {
      opacity: open ? '0' : '1',
      transform: open ? 'translateX(20px)' : 'translateX(0)'
    },
    '&:nth-of-type(3)': {
      transform: open ? 'rotate(-45deg)' : 'rotate(0)'
    }
  },
}))