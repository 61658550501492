import { styled } from '@mui/system'

type StyleProps = {
  direction?: 'row' | 'column' | 'row-reverse'
  justify?: string
  align?: string
  noMargin?: boolean
  reverse?: boolean
  noPadding?: boolean
  background?: string
}

export const Con = styled('div')<StyleProps>(
    ({ direction, justify, align, noMargin, noPadding, background, theme }) => ({
      display: 'flex',
      flexDirection: direction || 'row',
      justifyContent: direction === 'row' ? (justify || 'flex-start') : align ? (align || 'flex-start') : 'flex-start',
      alignItems: direction === 'row' ? (align || 'flex-start') : justify ? (justify || 'flex-start') : 'flex-start',
      marginTop: noMargin === true ? 0 : theme.spacing(2),
      padding: noPadding === true ? 0 : theme.spacing(2),
      background: background || 'none',
      [theme.breakpoints.down('md')]: {
        marginTop: noMargin === true ? 0 : theme.spacing(1),
      }
    })
  )

  export const WrappedCon = styled('div')<StyleProps>(
    ({ justify, align, noMargin, reverse, background, direction, theme }) => ({
      display: 'flex',
      flexDirection: direction || 'row',
      flexWrap: reverse ? 'wrap-reverse' : 'wrap',
      justifyContent: justify ? justify : 'flex-start',
      alignItems: align ? align : 'flex-start',
      marginTop: noMargin === true ? 0 : theme.spacing(2),
      background: background || 'none',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginTop: noMargin === true ? 0 : theme.spacing(1),
      },
    })
  )